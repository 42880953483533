// MODULE
import { useRecoilState } from 'recoil';
// RECOIL STATE
import { urlCopyInfoState } from '../../state/userState';
// COMPONENT
import Kakao from './Kakao/Kakao';
import Facebook from './Facebook/Facebook';

const ShareBox = ({ shareState, close, description, image }) => {
  const [urlInfo, setUrlInfo] = useRecoilState(urlCopyInfoState);
  const handleCopyClipBoard = async () => {
    setUrlInfo({ description: description, image: image });
    try {
      await navigator.clipboard.writeText(window.location.href);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다.');
    }
  };
  console.log(urlInfo);

  return (
    <>
      <div className={`share_box_modal fixed ${shareState ? '' : 'hide'}`}>
        <div className="modal_header flex flex_jc_sb">
          <div className="title">공유하기</div>
          <div className="close_btn relative " onClick={() => close()}>
            <div></div>
            <div></div>
          </div>
        </div>
        <ul className="flex flex_jc_s">
          <li>
            <button onClick={handleCopyClipBoard} title="URL 클립보드에 복사하기">
              <img src={`${process.env.PUBLIC_URL}/assets/img/icon/copyurl.png`} alt="URL 복사하기" width={50} />
            </button>
          </li>
          <li>
            <Kakao />
          </li>
          <li>
            <Facebook />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ShareBox;
