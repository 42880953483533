import axios from '../utils/instance';

const uri = '/buy-requests';
// POST BUY REQUEST
export const buyRequestPOST = (articleId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}`, {
        headers: { 'Content-Type': 'application/json' },
        articleId: articleId,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//
export const buyRequestUserGET = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${id}`, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// GET MY BUY REQUEST LIST
export const buyRequestMyListGET = (lastMessageTime) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}`, {
        headers: { 'Content-Type': 'application/json' },
        params: {
          lastMessageTime: lastMessageTime,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// PATCH BOYREQUEST STATE
export const buyRequestStatePATCH = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// LEAVE MESSAGE PATCH
export const leaveMessagePATCH = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
