import axios from '../utils/instance';

const uri = '/articles';
const like = '/articles/like';

// ARTICLE DETAIL GET
// 게시글 삭제
export const detailGET = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${id}`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// WRITE ARTICLE POST
// 게시글 등록
export const writePOST = (formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// MY ARTICLE DELETE
export const articleDELETE = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${uri}/${id}`, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// MY ARTICLE PATCH
export const articlePATCH = (id, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ARTICLE LIKE POST
export const articleLikePOST = (roadShowId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${like}/${roadShowId}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        resolve(response);
        console.log(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
};
// ARTICLE LIKE CANCEL POST
export const articleLikeDELETE = (roadShowId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${like}/${roadShowId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
        console.log(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
};
// ARTICLE SEARCH
export const articleSearchGET = (regionCode, keyword, page, placeName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}`, {
        headers: { 'Content-Type': 'application/json' },
        params: { placeName: placeName, regionCode: regionCode, keyword: keyword, page: page },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
