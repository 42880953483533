// MODULE
import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
// RECOIL STATE
import writeState from '../../state/writeState';
import { myLocationState, userGeolocationState } from '../../state/userState';
// IMAGE
import ModalFrame from '../Modal/ModalFrame';

const PlaceTextPopup = ({ getCenter, close, popupClose }) => {
  const [text, setText] = useState('');
  const [getPosition, seGetPosition] = useRecoilState(writeState.writeLocationInfoState);
  // RESULT ADDR
  const [selectInfo, setSelectInfo] = useState(null);
  const handleOnChangePlace = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  useEffect(() => {
    // 선택한 장소의 행정동,법정동 정보 가져오기
    const geocoder = new window.kakao.maps.services.Geocoder();
    const callback = function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        if (result[0].region_4depth_name !== '') {
          setSelectInfo(result[1]);
        } else {
          setSelectInfo(result[0]);
        }
      }
    };
    geocoder.coord2RegionCode(getCenter.La, getCenter.Ma, callback);
  }, []);
  const handleLocationState = () => {
    const geocoder = new window.kakao.maps.services.Geocoder();
    const callback = function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        if (result[0].region_4depth_name !== '') {
          setSelectInfo(result[1]);
        } else {
          setSelectInfo(result[0]);
        }
      }
    };
    geocoder.coord2RegionCode(getCenter.La, getCenter.Ma, callback);
    if (text <= 0) {
      alert('장소명을 입력해주세요.');
      return null;
    } else {
      seGetPosition(() => ({
        lat: getCenter.Ma,
        lon: getCenter.La,
        place_name: text,
        addr_depth_3: selectInfo?.region_3depth_name,
        code: selectInfo.code,
      }));
      close();
    }
  };

  return (
    <div className="toast_popup absolute">
      <div className="toast_popup_header flex">
        <div className="close_btn relative" onClick={popupClose}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div>
        <label htmlFor="custom_place" placeholder="">
          상세장소를 입력해주세요.
        </label>
        <input type="text" id="custom_place" onChange={handleOnChangePlace} value={text} />
      </div>
      <div className="btn_box">
        <div className="button regist_place" onClick={() => handleLocationState()}>
          장소 등록
        </div>
      </div>
    </div>
  );
};

const Map = ({ close, type, order }) => {
  const container = useRef(null);
  const [, setMap] = useState(null);
  const [userLat, setUserLat] = useState(Number);
  const [userLng, setUserLng] = useState(Number);
  const [getCenter, setGetCenter] = useState('');
  const [getLa, setGetLa] = useState(0);
  const [getMa, setGetMa] = useState(0);
  const [typing, setTyping] = useState(false);
  const [, setAddress] = useState('');

  const [samePlace, setSamePlace] = useState(false);

  const [myLocation, setMyLocation] = useRecoilState(myLocationState);
  // GEOLOCATION
  const [accessGeolocation, setAccessGeolocation] = useRecoilState(userGeolocationState);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setAccessGeolocation(true);
        setUserLat(position.coords.latitude);
        setUserLng(position.coords.longitude);
      });
    } else {
      console.log('내 위치를 사용할 수 없어요.');
    }

    const geocoder = new window.kakao.maps.services.Geocoder();

    // Perform reverse geocoding
    geocoder.coord2Address(userLng, userLat, (result, status) => {
      if (status === window.kakao.maps.services.Status.OK) {
        setAddress(result[0].address_name);
      }
    });
    const mapContainer = document.getElementById('map'),
      mapOption = {
        center: new window.kakao.maps.LatLng(userLat, userLng),
        level: 3,
      };

    const map = new window.kakao.maps.Map(mapContainer, mapOption);
    let latlng = map.getCenter();
    setGetCenter(latlng);
    setGetLa(latlng.La);
    setGetMa(latlng.Ma);
    window.kakao.maps.event.addListener(map, 'dragend', function () {
      let latlng = map.getCenter();
      setGetCenter(latlng);
      setGetLa(latlng.La);
      setGetMa(latlng.Ma);
    });
    setMap(map);
    map.setMinLevel(1);
    map.setMaxLevel(7);
  }, [container, userLat, userLng, accessGeolocation]);

  // GET LOCATION INFO
  useEffect(() => {
    const geocoder = new window.kakao.maps.services.Geocoder();
    const callback = function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        // setSelectInfo(result[0]);
        if (result[0].region_4depth_name !== '') {
          setSelectInfo(result[1]);
        } else {
          setSelectInfo(result[0]);
        }
      }
    };
    geocoder.coord2RegionCode(getLa, getMa, callback);
  }, [getLa, getMa]);

  const handlePlaceInputOpen = () => {
    setTyping(true);
  };
  const handlePlaceInputClose = () => {
    setTyping(false);
  };
  const [selectInfo, setSelectInfo] = useState();
  // console.log('Map.jsx', selectInfo);
  // REGISTER LOCATION INFO
  const handleSelectMyLocation = () => {
    const sameCheckPlace =
      myLocation[0]?.regionName === selectInfo?.region_3depth_name ||
      myLocation[1]?.regionName === selectInfo?.region_3depth_name;
    if (sameCheckPlace) {
      setSamePlace(true);
    } else {
      setMyLocation((prevMyLocation) => {
        if (order === 0) {
          return [
            {
              ...prevMyLocation[0],
              regionName: selectInfo?.region_3depth_name,
              code: selectInfo?.code,
            },
            { ...prevMyLocation[1] },
          ];
        } else {
          return [
            { ...prevMyLocation[0] },
            {
              ...prevMyLocation[1],
              regionName: selectInfo?.region_3depth_name,
              code: selectInfo?.code,
            },
          ];
        }
      });

      close();
    }
  };
  return (
    <>
      {samePlace ? (
        <ModalFrame
          confirm={() => setSamePlace(false)}
          custom_txt={'동일한 지역을 등록할 수 없습니다.'}
          height={'25%'}
        />
      ) : (
        ''
      )}
      <div
        className={`map_select_modal fixed ${typing ? 'active' : ''}`}
        style={{ width: '100%', height: '100%', top: 0, left: 0 }}
      >
        <div className="marker absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Marker.svg`} alt="" />
        </div>
        {/* <Marker className="marker absolute" /> */}
        {typing ? <PlaceTextPopup getCenter={getCenter} close={close} popupClose={handlePlaceInputClose} /> : ''}
        <div className="map_select_header flex flex_jc_sb flex_ai_c">
          <div className="close_btn relative" onClick={close}>
            <div></div>
            <div></div>
          </div>
          <div>{type !== undefined ? '내지역 설정' : '장소 선택하기'}</div>
        </div>
        <div
          className="select_btn fixed flex flex_jc_c flex_ai_c"
          onClick={type !== undefined ? () => handleSelectMyLocation() : () => handlePlaceInputOpen()}
        >
          {type !== undefined ? '선택하기' : '장소 입력하기'}
        </div>
        <div id="map" ref={container} style={{ width: '100%', height: 'calc(100% - 4rem)' }}></div>
      </div>
    </>
  );
};

export default Map;
