// MODULE
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useInView } from 'react-intersection-observer';
// RECOIL STATE
import { accessTokenCodeState } from '../../state/userState';
// HOOKS
import { buyRequestMyListGET, buyRequestUserGET } from '../../api/buyRequest';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import Skeleton from '../Skeleton';
import ProfileImage from '../ProfileImage/ProfileImage';
// IMAGE
import Default_Img from '../../assets/img/default_profile.png';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const MessageList = () => {
  const navigate = useNavigate();

  const [messageList, setMessageList] = useState(undefined);
  const [errCode, setErrCode] = useRecoilState(accessTokenCodeState);
  const [ref, inView] = useInView();

  const lastMessageSendTime =
    messageList && messageList !== undefined ? messageList[messageList?.length - 1].lastMessageTime : null;

  const getRequestList = async () => {
    try {
      const result = await buyRequestMyListGET();
      setMessageList(result.data);
    } catch (error) {
      if (error.response.status === 401) {
        setErrCode(error.response.status);
        setTimeout(() => {
          setErrCode(undefined);
        }, 0);
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    getRequestList();
  }, [errCode]);

  useEffect(() => {
    const lastMessageSendTime =
      messageList && messageList !== undefined ? messageList[messageList?.length - 1].lastMessageTime : null;

    const getRequestList = async () => {
      try {
        const result = await buyRequestMyListGET(lastMessageSendTime);
        setMessageList([...messageList, ...result.data]);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          setErrCode(error.response.status);
        } else {
          console.error(error);
        }
      }
    };
    if (inView) {
      getRequestList();
    }
  }, []);
  const handleMoveMessageDetail = (id) => {
    refreshTokenGET()
      .then((response) => {
        console.log(response);
        MovePage();
      })
      .catch((error) => {
        console.log(error);
      });
    const MovePage = async () => {
      try {
        const result = await buyRequestUserGET(id);
        navigate(`/message/detail`, { state: { id: id, articleId: result.data.articleId } });
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
        }
      }
    };
  };
  return (
    <div className="message_list_frame">
      <ul>
        {messageList === undefined ? (
          <Skeleton type={'chat_list'} loop={11} />
        ) : messageList.length > 0 ? (
          messageList.map((item, index) => {
            const id = item.id;
            const alarm = item.hasNotification;
            return (
              <li key={index} className="flex width_100p">
                <button
                  onClick={() => handleMoveMessageDetail(id)}
                  className={`relative flex flex_jc_sb flex_ai_c width_100p ${alarm}`}
                >
                  <div className="profile_img">
                    <ProfileImage
                      size={44}
                      src={
                        item.storedPartnerProfileImagePath === null
                          ? Default_Img
                          : `${process.env.REACT_APP_IMAGE_URL}${item.storedPartnerProfileImagePath}`
                      }
                    />
                  </div>
                  <div className="message_body flex flex_wrap_wrap">
                    <div className="user_info flex flex_ai_c flex_basis_100">
                      <div className="nickname">{item.partnerNickname}</div>
                      <div className="item_location">{item.regionName3depth}</div>
                    </div>
                    <div className="message_data flex flex_jc_sb flex_ai_c flex_basis_100">
                      <div className="content">{item.lastMessage}</div>
                      <div className="last_send_time">{dayjs(item.lastMessageTime).format('a hh:mm')}</div>
                    </div>
                  </div>
                  <div className="product_img">
                    <div className="img_box  relative flex_as_c">
                      {item.storedProductImagePath === null ? (
                        <img src={process.env.PUBLIC_URL + '/assets/img/logo_c.png'} alt="" />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${item.storedProductImagePath}`}
                          alt=""
                          loading="lazy"
                          height={'auto'}
                        />
                      )}
                    </div>
                  </div>
                </button>
              </li>
            );
          })
        ) : (
          <li className="empty_list flex flex_jc_c">진행중인 구매대행이 없습니다.</li>
        )}
        {messageList !== undefined ? messageList.length > 19 ? <li ref={ref}></li> : '' : ''}
      </ul>
    </div>
  );
};

export default MessageList;
