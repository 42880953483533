// MODULE
import { createBrowserRouter } from 'react-router-dom';
// COMPONENT
//// LAYOUT
import App from '../App';
import UserRouter from './UserRouter';
//// SPLASH
import Splash from '../components/Splash';
import Main from '../pages/Main';
//// LOGIN
import Login from '../pages/Login';
import KakaoOauth from '../components/KakaoLogin/KakaoLoginOauth';
import KakaoCallback from '../pages/Callback';
//// REGISTER
import PhoneCertification from '../pages/PhoneCertification';
import RegisterTerms from '../pages/RegisterTerms';
import RegistNickname from '../pages/Register/Nickname';
import ThumbNail from '../pages/Register/ThumbNail';

import DreegoRegist from '../pages/DreegoRegister';
//// MESSAGE
import Message from '../pages/Message';
import MessageList from '../components/MessageList';
import MessageDetail from '../pages/MessageDetail';
import TradeReview from '../pages/TradeReview';
import CurrentUserData from '../pages/CurrentUserData';
//// ARTICLE
import ArticleDetail from '../pages/ArticleDetail';
import ArticleList from '../pages/ArticleList';
import ArticleWrite from '../pages/ArticleWrite';
import ArticleModify from '../pages/ArticleModify';
import ClusterList from '../pages/ClustererList';
import MyLocation from '../pages/MyLocation';
//// MYPAGE
import MyPage from '../pages/MyPage';
import MenuList from '../components/Mypage/Menu';
import MyLocationSetting from '../components/Mypage/MyLocationSetting';
import LikeList from '../components/Mypage/LikeList';
import ProfileSetting from '../components/Mypage/ProfileSetting';
import MyNicknameSetting from '../pages/NicknameSetting';
import Point from '../components/Point';
// import PointDetail from '../components/PointLogSearch';
import PointConversion from '../components/PointConversion';
import PointLogSearch from '../components/PointLogSearch';
import ShareList from '../components/Mypage/ShareList';
////// ORDER
// import All from '../components/Mypage/All';
// import OnSale from '../components/Mypage/OnSale';
// import Expired from '../components/Mypage/Expired/Expired';
//// USER
import UserInfo from '../pages/UserInfo';
import UserInfoTab from '../pages/WriterItemsTab';
import UserReport from '../pages/UserReport';
//// NOTICE
import NoticeList from '../pages/NoticeList';
import NoticeDetail from '../pages/NoticeDetail';
//// FAQ
import FAQMain from '../pages/FAQ';
//// APPSETTING
import AppSetting from '../pages/AppSetting';
import AccountCancellation from '../pages/AccountCancel/AccountCancellation';
import AccountCancelFinish from '../pages/AccountCancel/AccountCancelFinish';
import AccountCancelEnd from '../pages/AccountCancel/AccountCancelEnd';
import BlockList from '../pages/BlockList';
//// ERROR
import Error from '../pages/Error';
//// TEST
import Test from '../pages/Test';
import NormalRegister from '../pages/Register/NormalForm';
import FindUserInfo from '../pages/FindUserInfo/FindUserInfo';
import ChangePassword from '../components/Mypage/ChangePassword';

const Datarouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Splash />,
      },
      {
        path: 'auth/kakao/callback',
        element: <KakaoOauth />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register-form',
        element: <NormalRegister />,
      },
      {
        path: 'find-info',
        element: <FindUserInfo />,
      },
      {
        path: 'phone-certification',
        element: <PhoneCertification />,
      },
      {
        path: 'register-terms',
        element: <RegisterTerms />,
      },
      {
        path: 'regist-nickname',
        element: <RegistNickname />,
      },
      {
        path: 'regist-thumbnail',
        element: <ThumbNail />,
      },
      {
        path: 'regist-location',
        element: <MyLocationSetting />,
      },
      {
        path: 'home',
        element: <Main />,
      },
      {
        path: 'detail',
        element: <ArticleDetail />,
      },
      {
        path: 'cluster-list',
        element: <ClusterList />,
      },
      {
        path: 'list',
        element: <ArticleList />,
      },
      {
        path: 'write',
        element: (
          <UserRouter>
            <ArticleWrite />
          </UserRouter>
        ),
      },
      {
        path: 'modify',
        element: (
          <UserRouter>
            <ArticleModify />
          </UserRouter>
        ),
      },
      // MESSAGE
      {
        path: 'message/',
        element: (
          <UserRouter>
            <Message />
          </UserRouter>
        ),
        children: [
          {
            path: 'list',
            element: <MessageList />,
          },
          {
            path: 'detail',
            element: <MessageDetail />,
          },
          {
            path: 'review',
            element: <TradeReview />,
          },
        ],
      },

      // MYPAGE
      {
        path: 'mypage/',
        element: <MyPage />,
        children: [
          {
            path: 'main',
            element: <MenuList />,
          },
          {
            path: 'location-setting',
            element: <MyLocationSetting />,
          },
          {
            path: 'like-list',
            element: <LikeList />,
          },
          {
            path: 'share-list',
            element: <ShareList />,
          },
          // {
          //   path: 'all',
          //   element: <All />,
          // },
          // {
          //   path: 'on-sale',
          //   element: <OnSale />,
          // },
          // {
          //   path: 'expired',
          //   element: <Expired />,
          // },
          {
            path: 'profile-setting',
            element: <ProfileSetting />,
          },
          {
            path: 'nickname-setting',
            element: <MyNicknameSetting />,
          },
          {
            path: 'point',
            element: <Point />,
          },
          {
            path: 'point-conversion',
            element: <PointConversion />,
          },
          {
            path: 'point-log',
            element: <PointLogSearch />,
          },
          {
            path: 'change-password',
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: 'mylocation',
        element: <MyLocation />,
      },
      // APPSETTING
      {
        path: 'setting',
        element: <AppSetting />,
      },
      {
        path: 'block-list',
        element: (
          <UserRouter>
            <BlockList />
          </UserRouter>
        ),
      },
      {
        path: 'account-cancellation',
        element: (
          <UserRouter>
            <AccountCancellation />
          </UserRouter>
        ),
      },
      {
        path: 'account-cancel-finish',
        element: (
          <UserRouter>
            <AccountCancelFinish />
          </UserRouter>
        ),
      },
      {
        path: 'account-cancel-end',
        element: (
          <UserRouter>
            <AccountCancelEnd />
          </UserRouter>
        ),
      },
      // USERINFO
      {
        path: 'userinfo',
        element: (
          <UserRouter>
            <UserInfo />
          </UserRouter>
        ),
      },
      {
        path: 'userinfo-detail',
        element: <UserInfoTab />,
      },
      {
        path: 'user-report',
        element: (
          <UserRouter>
            <UserReport />
          </UserRouter>
        ),
      },
      {
        path: 'notice',
        element: <NoticeList />,
      },
      {
        path: 'notice-detail',
        element: <NoticeDetail />,
      },
      {
        path: 'faq',
        element: <FAQMain />,
      },
      {
        path: 'dreego-regist',
        element: (
          <UserRouter>
            <DreegoRegist />
          </UserRouter>
        ),
      },
      // KAKAO CALLBACK
      {
        path: 'callback/auth-callback',
        element: (
          <UserRouter>
            <KakaoCallback />
          </UserRouter>
        ),
      },
      // CURRENT USER
      {
        path: 'current-user',
        element: <CurrentUserData />,
      },
      {
        path: 'custom_test_page',
        element: <Test />,
      },
    ],
    errorElement: <Error />,
  },
]);

export default Datarouter;
