// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack/HistoryBack';
import FAQList from '../../components/FAQList/FAQList';

const FaqMain = () => {
  return (
    <div className="faq_section">
      <div className="faq_header flex flex_jc_sb flex_ai_c">
        <HistoryBack />
        <div className="page_title flex flex_jc_c">자주 묻는 질문</div>
      </div>
      <FAQList />
    </div>
  );
};

export default FaqMain;
