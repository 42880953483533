// MODULE
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// HOOKS
import { memberLogoutGET } from '../../api/auth';
import { deleteStorage } from '../../utils/common';
import { getCookie, setCookie, removeCookie } from '../../utils/cookie';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import ModalFrame from '../../components/Modal';
// JSON
import MenuText from '../../json/menuText.json';

const AppSetting = () => {
  const navigate = useNavigate();
  const [logoutCheck, setLogoutCheck] = useState(false);
  const [isByapps, setIsByapps] = useState(false);
  const handleLogoutCheck = () => {
    setLogoutCheck(true);
  };

  const handleLogout = async () => {
    try {
      const response = await memberLogoutGET();
      if (response.status === 200) {
        deleteStorage();
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Add the byapps functions
  const byapps_is_api_enable = function () {
    return !!navigator.userAgent.match('Byapps');
  };

  const byapps_api_call = function (a, s) {
    if (byapps_is_api_enable()) {
      if (window.byappsJSI && window.byappsJSI.callAPI) {
        window.byappsJSI.callAPI(a, s);
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.byappsJSI) {
        let e = a;
        s && (e += '?' + s);
        window.webkit.messageHandlers.byappsJSI.postMessage(e);
      }
    }
  };

  const handleMoveByappsSetting = () => {
    byapps_api_call('showSettings');
  };

  const getRefreshTokenCheck = async () => {
    try {
      const response = await refreshTokenGET();
      if (response.status === 200) {
        // const time = 3600;
        // const expiration = new Date(Date.now() + time * 720000);
        // setCookie('isLogin', true, { expires: expiration });
      }
    } catch (error) {
      deleteStorage();
      if (error.response.status === 401) {
        deleteStorage();
      } else {
        deleteStorage();
      }
    }
  };

  useEffect(() => {
    if (byapps_is_api_enable()) {
    }
    if (navigator.userAgent.match('Byapps')) {
      setIsByapps(true);
    }

    if (getCookie('isLogin') !== undefined) {
      getRefreshTokenCheck();
    } else {
      deleteStorage();
    }
  }, []);
  return (
    <>
      {logoutCheck && (
        <ModalFrame
          // confirm={() => handleLogout()}
          confirm={handleLogout}
          custom_txt={'로그아웃 하시겠습니까?'}
          buttons={() => setLogoutCheck(false)}
          type={'type_3'}
        />
      )}
      <div className="app_setting_section">
        <div className="app_setting_header flex flex_jc_sb">
          <HistoryBack />
          <div className="menu_title center">앱 설정</div>
        </div>
        <div className="menu_list">
          <ul>
            {MenuText.appSettingList.slice(2).map((item, index) => (
              <li key={index}>
                <Link to={item.url} className="flex flex_jc_sb flex_ai_c">
                  <div className="txt">{item.name}</div>
                  <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                </Link>
              </li>
            ))}
            <li onClick={handleLogoutCheck} className="flex flex_jc_sb flex_ai_c">
              <div className="txt">로그아웃</div>
              <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
            </li>
            {isByapps ? (
              <li onClick={() => handleMoveByappsSetting()} className="flex flex_jc_sb flex_ai_c">
                <div className="txt">설정</div>
                <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
              </li>
            ) : (
              ''
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppSetting;
