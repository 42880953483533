// MODULE
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
// RECOIL STATE
import { clusterPlaceState, clusterPlaceIndexState } from '../../state/userState';
// IMAGE

const ClusterSelect = ({ placeSelect, listItem, close }) => {
  const isArrObject = listItem !== undefined ? listItem?.map((item) => item.placeName) : null;
  const navigate = useNavigate();
  const changePlaceName = useSetRecoilState(clusterPlaceState);
  const [, setPlaceName] = useRecoilState(clusterPlaceState);
  const [, setPlaceIndex] = useRecoilState(clusterPlaceIndexState);
  // SET DATA
  const PlaceNameSet =
    listItem !== undefined
      ? isArrObject[0] !== undefined
        ? [...new Set(listItem?.map((item) => item.placeName))]
        : listItem
      : null;
  // PAGE PATHNAME CHECK
  const checkPage = window.location.pathname.includes('cluster-list');
  // CLUSTER MAP ONCLICK EVENT
  const handleMovePage = (name, index) => {
    changePlaceName(name);
    setPlaceIndex(index);
    navigate('/cluster-list', {
      state: { listItem: listItem, selectItem: name, placeName: PlaceNameSet },
    });
  };
  // LIST ONCLICK EVENT
  const handleChangePlaceName = (name, index) => {
    changePlaceName(name);
    setPlaceName(name);
    setPlaceIndex(index);
    close();
  };
  return (
    <div className={`toast_menu fixed ${placeSelect ? '' : 'hide'}`}>
      <div className="modal_header flex flex_jc_sb">
        <div className="title">장소 목록</div>
        <div onClick={close} className="relative close_btn">
          <div></div>
          <div></div>
        </div>
      </div>
      <ul>
        {listItem !== undefined
          ? PlaceNameSet.map((name, index) => (
              <li
                key={index}
                onClick={() => (checkPage ? handleChangePlaceName(name, index) : handleMovePage(name, index))}
              >
                {name}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default ClusterSelect;
