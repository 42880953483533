// MODULE
import { useNavigate } from 'react-router-dom';
// HOOKS
import { setPrice } from '../../utils/textEffect';
import { getElapsedTime, getRemainingPeriod } from '../../utils/timeEffect';
// IMAGE
import { ReactComponent as MessageCounter } from '../../assets/img/icon/Message_icon.svg';
import { ReactComponent as LikeCounter } from '../../assets/img/icon/Like_icon.svg';

const PostTimeAgo = ({ createdAt }) => {
  const timeAgo = getElapsedTime(createdAt);
  return <>{timeAgo}</>;
};
const ClusterItem = ({ info }) => {
  const navigate = useNavigate();
  return (
    <li className="list_item">
      <div className="flex flex_jc_sb" onClick={() => navigate(`/detail?info=${info.id}`)}>
        <div className="img_box relative flex_as_c">
          <img
            src={info.thumb === 'default' ? `${process.env.PUBLIC_URL}/assets/img/logo_c.png` : info.thumb}
            alt=""
            loading="lazy"
          />
          <div className="counter_item absolute flex">
            {info.request === 0 ? (
              ''
            ) : (
              <div className="purchase_request flex">
                <MessageCounter />
                <span>{info.request}</span>
              </div>
            )}
            {info.like === 0 ? (
              ''
            ) : (
              <div className="like flex">
                <LikeCounter />
                <span>{info.like}</span>
              </div>
            )}
          </div>
        </div>
        <div className="item_info relative">
          <div className="header_info flex flex_jc_s flex_ai_c">
            <div className="reg_dt">
              <PostTimeAgo createdAt={info.createdAt} />
              &nbsp;·&nbsp;
            </div>
            <div className="location flex">
              <div className="area">{info.placeName}</div>
            </div>
          </div>
          <div className="body_info">
            <div className="title">{info.title}</div>
            <div className="price">{info.price === null ? '가격정보 없음' : setPrice(info.price) + '원'}</div>
          </div>
          <div className="end_dt absolute">{getRemainingPeriod(info.end)}</div>
        </div>
      </div>
    </li>
  );
};

export default ClusterItem;
