export function getLocation() {
  if (
    window.location.pathname.includes('login') ||
    window.location.pathname.includes('register') ||
    window.location.pathname.includes('detail') ||
    window.location.pathname.includes('write') ||
    window.location.pathname.includes('phone-certification') ||
    window.location.pathname.includes('userinfo') ||
    window.location.pathname.includes('notice') ||
    window.location.pathname.includes('review') ||
    window.location.pathname.includes('faq') === true
  ) {
    return 0;
  } else if (
    window.location.pathname.includes('home') ||
    window.location.pathname.includes('mylocation') ||
    window.location.pathname.includes('message') ||
    window.location.pathname.includes('mypage/main') ||
    window.location.pathname.includes('cluster-list') === true
  ) {
    return 1;
  } else if (window.location.pathname.includes('write') || window.location.pathname.includes('detail')) {
    return 2;
  } else {
    return -1;
  }
}

export function getNavEffect(name) {
  if (name === '/home') {
    return 0;
  } else if (name === '/write') {
    return 1;
  } else if (name === '/mylocation' || name === '/cluster-list') {
    return 2;
  } else if (name === '/message/list') {
    return 3;
  } else if (name === '/mypage/main') {
    return 4;
  }
}

export function getMyPageName(name) {
  if (name === '/mypage/main') {
    return 0;
  } else if (
    name === '/mypage/share-list' ||
    name === '/mypage/all' ||
    name === '/mypage/on-sale' ||
    name === '/mypage/expired'
  ) {
    return 1;
  } else if (name === '/mypage/location-setting') {
    return 2;
  } else if (name === '/mypage/profile-setting') {
    return 3;
  } else if (name === '/mypage/nickname-setting') {
    return 4;
  } else if (name === '/mypage/point') {
    return 5;
  } else if (name === '/mypage/point-conversion') {
    return 6;
  } else if (name === '/mypage/like-list') {
    return 7;
  } else if (name === '/mypage/point-log') {
    return 8;
  } else if (name === '/mypage/change-password') {
    return 9;
  } else {
    return -1;
  }
}

export function byappsMenuSet(url) {
  // if (
  // window.location.pathname.includes('home') ||
  // window.location.pathname.includes('mylocation') ||
  // window.location.pathname.includes('message/list') ||
  // window.location.pathname.includes('mypage/main') === true
  // )
  if (url === '/home' || url === '/mylocation' || url === '/message/list' || url === '/mypage/main') {
    return 99;
  } else {
    return 0;
  }
}
