export function setPrice(price) {
  if (price == null) {
    return '';
  }
  const priceString = typeof price === 'string' ? price : price.toString();
  const indexToInsertComma = priceString.length % 3;
  let formattedPrice = '';
  for (let i = 0; i < priceString.length; i++) {
    if (i > 0 && (i - indexToInsertComma) % 3 === 0) {
      formattedPrice += ',';
    }
    formattedPrice += priceString[i];
  }
  return formattedPrice;
}

export function handleOnInput(el, maxlength) {
  if (el.value.length > maxlength) {
    el.value = el.value.substr(0, maxlength);
  }
}

export function convertHTMLCode(htmlContent) {
  const modifyImgSrc = (htmlString) => {
    const baseUrl = 'https://showup.co.kr/images/notice/';
    const modifiedHtml = htmlString.replace(/src="notice\//g, `src="${baseUrl}`);
    return modifiedHtml;
  };

  const renderHtml = (htmlString) => {
    const modifiedHtml = modifyImgSrc(htmlString);
    return { __html: modifiedHtml };
  };

  return <div dangerouslySetInnerHTML={renderHtml(htmlContent)} />;
}
export function CheckPasswordText(password) {
  const check = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,16}$/;
  return check.test(password);
}
