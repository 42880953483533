// MODULE
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
// RECOIL STATE
import { setCertificationState } from '../state/userState';

const Timer = ({ time, timeReset }) => {
  const [timer, setTimer] = useState(time);
  const [, setIsEnd] = useRecoilState(setCertificationState);
  useEffect(() => {
    setTimer(time);
    setIsEnd(false);
  }, [timeReset]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        setIsEnd(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  const minutes = Math.floor(timer / 60);
  const remainingSeconds = timer % 60;
  return (
    <div className="timer absolute flex flex_ai_c">
      {timer !== 0 ? (
        <>
          {minutes}분 {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}초
        </>
      ) : (
        '인증시간이 만료되었습니다.'
      )}
    </div>
  );
};

export default Timer;
