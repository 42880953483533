import axios from 'axios';

const uri = 'https://shop-api.e-ncp.com';
const redirectUrl = 'https://showup.co.kr/callback/auth-callback';
// const redirectUrl = 'https://localhost:3000/callback/auth-callback';
const syncType = 'ncp_kakao-sync';
// DREEGO LOGIN
export const isDreegoUserCheckPOST = (memberId, password) => {
  return new Promise((resolve, reject) => {
    const data = {
      memberId: memberId,
      password: password,
      keepLogin: true,
      captcha: null,
      provider: null,
    };
    axios
      .post(`${uri}/oauth/token`, data, {
        headers: {
          accept: 'application/json',
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// GET KAKAKO SYNC LOGIN
export const dreegoKakaoSyncGET = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/oauth/login-url`, {
        async: true,
        crossDomain: true,
        headers: {
          accept: 'application/json',
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
        },
        params: {
          redirectUri: redirectUrl,
          provider: syncType,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// GET OAUTH TOKEN
export const dreegoKakaoSyncTokenGET = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/oauth/openid`, {
        async: true,
        crossDomain: true,
        // url: `https://shop-api.e-ncp.com/oauth/openid?platformType=PC&redirectUri=${redirectUrl}&code=${code}&provider=${syncType}`,
        headers: {
          accept: 'application/json',
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
        },
        params: {
          redirectUri: redirectUrl,
          code: code,
          provider: syncType,
          keepLogin: true,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// GET DREEGO USER INFO
export const dreegoUserInfoGET = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/profile`, {
        headers: {
          Version: '1.0',
          platform: 'PC',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          accessToken: token,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET MALL PI
export const dreegoTermsUseGET = (type) => {
  // USE : 이용약관
  // PI_COLLECTION_AND_USE_REQUIRED : 개인정보 수집/이용(필수)
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/terms`, {
        headers: {
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
        },
        params: {
          termsTypes: type,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// CHECK DREEGO CI CHECK
export const dreegoMemberCiGET = (ci, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/profile/ci/exists`, {
        headers: {
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
          ci: ci,
          accessToken: token,
        },
      })
      .then((response) => {
        resolve(response); // response.status = 'ACTIVE' 일 경우 사용중인 아이디임
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// CHECK DREEGO ID CHECK
export const dreegoMemberIdGET = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/profile/id/exist`, {
        headers: {
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
        },
        params: { memberId: id },
      })
      .then((response) => {
        resolve(response);
        // response.status = 'ACTIVE' 일 경우 사용중인 아이디임
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// CHECK DREEGO EMAIL CHECK
export const dreegoMemberEmailGET = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/profile/email/exist`, {
        headers: {
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
          email: email,
        },
      })
      .then((response) => {
        resolve(response); // response.status = 'ACTIVE' 일 경우 사용중인 아이디임
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// JOIN DREEGO MEMBER
export const dreegoMemberJoinPOST = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}/profile`, data, {
        headers: {
          async: true,
          crossDomain: true,
          Version: '1.0',
          clientId: process.env.REACT_APP_DREEGO_CLINT_ID,
          platform: 'PC',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
