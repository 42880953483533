import { atom } from 'recoil';

// WRITE STAY STATE
const writeStayState = atom({
  key: 'writeStayStatus',
  default: {
    title: '',
    lat: Number,
    lon: Number,
    contents: '',
    price: Number,
    img: [],
  },
});
// WRITE LOCATION INFO
const writeLocationInfoState = atom({
  key: 'writeLocationInfoStatus',
  default: [
    {
      lat: null,
      lon: null,
      place_name: '',
      addr_depth_1: '',
      addr_depth_2: '',
      addr_depth_3: '',
      code: '',
    },
  ],
});
// POSITION SELECT TYPE
const locationSelectTypeState = atom({
  key: 'type',
  default: 0,
});
// DATEPICKER
const datePickerState = atom({
  key: 'date',
  default: { startDate: '', endDate: '' },
});
const writeState = {
  writeStayState,
  writeLocationInfoState,
  locationSelectTypeState,
  datePickerState,
};

export default writeState;
