import { Privacy } from './Data';

const PrivacyPolicy = ({ close }) => {
  return (
    <div className="privacy_policy_section cover_section fixed">
      <div className="modal_header flex flex_jc_sb flex_ai_c">
        <div className="modal_title">개인정보처리방침</div>
        <div className="close_btn relative" onClick={() => close()}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="content">{Privacy[0].Privacy20231027}</div>
    </div>
  );
};

export default PrivacyPolicy;
