// MODULE
import { useEffect, useState } from 'react';
// HOOK
import { myProfileInfoGET } from '../../api/member';
import HistoryBack from '../../components/Common/HistoryBack';
// COMPONENT
import Skeleton from '../../components/Skeleton';
import BlockedListItem from '../../components/BlockedListItem';
const BlockList = () => {
  const [blockedList, setBlockedList] = useState(null);
  const [eventLength, setEventLength] = useState(0);
  const getBlockedList = async () => {
    const needValue = '18';
    const id = localStorage.getItem('memberId');
    try {
      const response = await myProfileInfoGET(id, needValue);
      if (response.status === 200) {
        setBlockedList(response.data.blockedMemberIds);
        setEventLength(response.data.blockedMemberIds.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChildEvent = (length) => {
    console.log('이벤트 발생:', length);
    setEventLength(length);
  };
  useEffect(() => {
    getBlockedList();
  }, [blockedList?.length, eventLength]);
  return (
    <div className="blocked_section">
      <div className="blocked_header flex flex_jc_sb">
        <HistoryBack />
        <div>차단 목록</div>
        <div></div>
      </div>
      <div className="blocked_user_list">
        <ul>
          {blockedList !== null ? (
            blockedList.length === 0 ? (
              <li className="empty_list">차단한 사용자가 없습니다.</li>
            ) : (
              <BlockedListItem data={blockedList} length={blockedList.length} onChildEvent={handleChildEvent} />
            )
          ) : (
            <Skeleton />
          )}
        </ul>
      </div>
    </div>
  );
};

export default BlockList;
