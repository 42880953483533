// MODULE
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// HOOKS
import { accountDELETE } from '../../../api/member';
// COMPONENT
import HistoryBack from '../../../components/Common/HistoryBack';
import ModalFrame from '../../../components/Modal';

const CancelReasonList = [
  {
    id: 1,
    text: '이용빈도가 너무 적어요.',
  },
  {
    id: 2,
    text: '알림이 너무 많이 와요.',
  },
  {
    id: 3,
    text: '서비스 이용이 어려워요.',
  },
  {
    id: 4,
    text: '새 계정을 만들고 싶어요',
  },
  {
    id: 5,
    text: '기타',
  },
];
const AccountCancelFinish = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [userTyping, setUserTyping] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const accountCancelCheck = () => {
    setConfirmModal(true);
  };
  const handleAccountCancellation = async () => {
    try {
      const response = await accountDELETE(cancelReason);
      console.log(response);
      if (response.status === 204) {
        navigate('/account-cancel-end');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onChangeTypingReason = (e) => {
    e.preventDefault();
    setUserTyping(e.target.value);
  };
  const handleReasonType = (e) => {
    console.log(e);
    if (e === undefined) {
      setIsTyping(true);
      setCancelReason(userTyping);
    } else {
      setIsTyping(false);
      setCancelReason(e);
    }
  };
  const checkReason = document.querySelector('input[type="radio"]:checked') === null;

  useEffect(() => {}, [cancelReason]);
  return (
    <>
      {confirmModal === true ? (
        <ModalFrame
          custom_txt={'account_cancel'}
          type={'account_cancel'}
          buttons={() => handleAccountCancellation()}
          buttons_txt={'탈퇴하기'}
          confirm={() => setConfirmModal(false)}
          confirm_txt={'더 이용하기'}
        />
      ) : (
        ''
      )}
      <div className="account_cancel_section finish_section">
        <div className="account_cancel_header flex flex_jc_sb">
          <div className="menu_title center">회원탈퇴</div>
        </div>
        <div className="account_cancel_body">
          <div className="sub_title">탈퇴하는 이유를 알려주세요.</div>
          <div className="reason_list">
            <ul>
              {CancelReasonList.map((item, index) => (
                <li key={index} className="flex flex_ai_c">
                  <input
                    type="radio"
                    id={`reason_type_${index}`}
                    name={`reason`}
                    onChange={() => handleReasonType(item.text)}
                  />
                  <label htmlFor={`reason_type_${index}`} className="relative">
                    {item.text}
                  </label>
                </li>
              ))}
              <li className="flex flex_ai_c flex_wrap_wrap self_reason">
                <input
                  type="radio"
                  id={`reason_type_${CancelReasonList.length + 1}`}
                  name={`reason`}
                  onChange={() => handleReasonType()}
                />
                <label htmlFor={`reason_type_${CancelReasonList.length + 1}`} className="relative">
                  직접입력
                </label>
                <textarea
                  className={`${isTyping}`}
                  name=""
                  id=""
                  cols="30"
                  rows="6"
                  maxLength={200}
                  onChange={onChangeTypingReason}
                  value={userTyping}
                  readOnly={!isTyping}
                ></textarea>
              </li>
            </ul>
            <div className="extinction_point flex flex_ai_c">
              <p>
                소멸 포인트&ensp;&ensp;<span>{state.point}포인트</span>
              </p>
            </div>
          </div>
          <div className="btn_box absolute flex flex_jc_sb">
            <div className="button" onClick={() => navigate(-2)}>
              더 이용하기
            </div>
            <div
              className={`button ${!checkReason ? '' : 'disable'}`}
              onClick={() => (!checkReason ? accountCancelCheck() : null)}
            >
              탈퇴하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountCancelFinish;
