// MODULE
import { FacebookShareButton, FacebookIcon } from 'react-share';
const Facebook = () => {
  const shareUrl = window.location.href;
  return (
    <FacebookShareButton url={shareUrl} title="FaceBook에 공유하기">
      <FacebookIcon size={50} round />
    </FacebookShareButton>
  );
};

export default Facebook;
