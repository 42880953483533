// axiosConfig.js

import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://dev-api.showup.co.kr',
  // baseURL: 'http://localhost:8080',
  //baseURL: '',
  withCredentials: true,
  timeout: 10000,
});

export default instance;
