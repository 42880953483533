// MODULES
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// RECOIL STATE
import { isLoginState } from '../../state/commonState';
// HOOKS
import { getCookie, setCookie } from '../../utils/cookie';
import { refreshTokenGET, loginIdPOST } from '../../api/auth';
import { myProfileInfoGET } from '../../api/member';
import { saveLoginData } from '../../utils/saveData';
// COMPONENT
import KakaoLogin from '../../components/KakaoLogin/KakaoLogin';
import Input from '../../components/Common/Input';

// IMAGES

const Login = () => {
  useEffect(() => {
    document.addEventListener('scroll', function () {});
  }, []);
  const navigate = useNavigate();
  const [, setIsLogin] = useRecoilState(isLoginState);
  const [loginId, setLoginId] = useState('');
  const [loginPw, setLoginPw] = useState('');
  const [loginError, setLoginError] = useState(0);

  const onChangeLoginId = (e) => {
    e.preventDefault();
    setLoginId(e.target.value);
  };
  const onChangeLoginPw = (e) => {
    e.preventDefault();
    setLoginPw(e.target.value);
  };

  const getProfile = async () => {
    if (getCookie('isLogin') !== undefined) {
      try {
        const response = await refreshTokenGET();
        alert(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleLoginId = async () => {
    if (loginId.length === 0) {
      setLoginError(3);
    } else {
      try {
        const response = await loginIdPOST(loginId, loginPw);
        if (response.status === 200) {
          const result = response.data;
          const needValue = '2,3,4,5,6,7,8,11,16';
          const loginResult = await myProfileInfoGET(result.id, needValue);
          const resultData = loginResult.data;
          setCookie('firstRegionOfResidence', resultData.firstRegionOfResidence, { path: '/' });
          setCookie('secondRegionOfResidence', resultData.secondRegionOfResidence, { path: '/' });
          const time = 3600;
          const expiration = new Date(Date.now() + time * 720000);
          setCookie('isLogin', expiration, { expires: expiration });
          saveLoginData(result.id, resultData);
          setIsLogin(true);
          navigate(localStorage.getItem('prevUrl') !== undefined ? localStorage.getItem('prevUrl') : '/home');
        }
      } catch (error) {
        if (error.response.status === 401) {
          setLoginError(1);
        } else if (error.response.status === 404) {
          setLoginError(2);
        }
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <div className="login_section frame flex flex_flow_cw flex_jc_sb flex_ai_c">
      <div className="logo_section">
        <div className="img_box">
          <img src={`${process.env.PUBLIC_URL}/assets/img/logo_w.png`} alt="" />
        </div>
        <div className="login_title flex flex_jc_c">
          <span>쇼</span>핑스킬 레벨<span>업!</span>
        </div>
      </div>
      <div className="login_form">
        <Input
          value={loginId}
          id={'loginId'}
          name={'loginId'}
          type={'text'}
          placeholder={'아이디'}
          onChange={onChangeLoginId}
        />
        <Input
          value={loginPw}
          id={'loginPw'}
          name={'loginPw'}
          type={'password'}
          placeholder={'비밀번호'}
          onChange={onChangeLoginPw}
          maxLength={18}
        />
        <div className="btn_box  flex flex_dir_c flex_jc_c" style={{ position: 'relative', width: '100%' }}>
          {loginError !== 0 && (
            <div className="event_txt absolute">
              {loginError === 1
                ? '아이디 또는 비빌번호를 확인해주세요.'
                : loginError === 2
                ? '존재하지 않는 회원입니다.'
                : '아이디를 입력해주세요.'}
            </div>
          )}
          <div className="button" onClick={() => handleLoginId()}>
            로그인
          </div>
          <Link className="sign_up button" to="/register-form">
            회원가입
          </Link>
          <Link to="/find-info" className="small_btn flex flex_jc_c flex_ai_c">
            아이디/비밀번호 찾기
          </Link>
        </div>
      </div>
      <div className="btn_box flex flex_dir_c flex_jc_c">
        <div style={{ display: 'none' }}>
          <KakaoLogin />
        </div>
        {/* <Link to="/phone-certification" className="sign_up button">
          휴대폰 번호로 시작
        </Link> */}
        {/* <Link className="sign_up button">쇼업 시작하기</Link>
        <Link className="sign_up button" to="/register-form">
          회원가입
        </Link> */}
      </div>
      {/* <div className="login_footer">
        <Footer />
      </div> */}
    </div>
  );
};

export default Login;
