// MODULE
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
// HOOKS
import { pointInfoGET } from '../../api/member';
import { isDreegoUserCheckPOST, dreegoKakaoSyncGET } from '../../api/externalAPI';
import { setPrice } from '../../utils/textEffect';
// COMPONENT
import Skeleton from '../../components/Skeleton';
// RECOIL STATE
// IMAGE
import PointDefaultLog from '../PointDefaultLog';

const DreegoLogin = ({ loginModal, close, totalPoint }) => {
  const navigate = useNavigate({ replace: true });
  const { state } = useLocation();
  const [loginId, setLoginId] = useState('');
  const [loginPw, setLoginPw] = useState('');
  const [errMsg, setErrMsg] = useState(null);

  const onChangeLoginId = (e) => {
    e.preventDefault();
    setLoginId(e.target.value);
  };
  const onChangeLoginPw = (e) => {
    e.preventDefault();
    setLoginPw(e.target.value);
  };

  // DERRGO LOGIN API
  const handleDreegoLogin = async () => {
    if (loginId.length === 0) {
      setErrMsg('※ 아이디를 입력해주세요.');
      document.querySelector('#dreego_id').focus();
    } else if (loginPw.length === 0) {
      setErrMsg('※ 비밀번호를 입력해주세요.');
      document.querySelector('#dreego_pw').focus();
    } else {
      try {
        const response = await isDreegoUserCheckPOST(loginId, loginPw);

        if (response.status === 200) {
          console.log('response', response);
          navigate('/mypage/point-conversion', {
            state: { userInfo: response.data, totalPoint: totalPoint },
          });
        }
      } catch (error) {
        if (error.response.data.code === 'M0019') {
          setErrMsg('※ ' + error.response.data.message);
        }
        console.log(error);
      }
    }
  };
  // DREEGO KAKAO SYNC
  const handleKakaoSync = async () => {
    try {
      const result = await dreegoKakaoSyncGET();
      // navigate(result.data.loginUrl);
      window.location.href = result.data.loginUrl;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`toast_menu fixed ${loginModal ? '' : 'hide'}`}>
      <div className="modal_header flex flex_jc_sb flex_ai_c">
        <div className="img_box">
          <img src={`${process.env.PUBLIC_URL}/assets/img/dreego_Logo.png`} alt="Dreego Logo" width={75} />
        </div>
        <div onClick={() => close()} className="relative close_btn">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="modal_content relative">
        <div className="id_box input_box">
          <label htmlFor="dreego_id"></label>
          <input id="dreego_id" type="text" value={loginId} onChange={onChangeLoginId} placeholder="아이디" />
        </div>
        <div className="pw_box input_box">
          <label htmlFor="dreego_pw"></label>
          <input type="password" id="dreego_pw" value={loginPw} onChange={onChangeLoginPw} placeholder="비밀번호" />
        </div>
        <div className="err_msg absolute">{errMsg}</div>
        <div className="btn_box">
          <div className="button" onClick={() => handleDreegoLogin()}>
            로그인
          </div>
          <div className="kakao_sync button" onClick={() => handleKakaoSync()}>
            카카오 아이디로 로그인
          </div>
        </div>
        <div className="custom_regist" onClick={() => navigate('/dreego-regist')}>
          회원가입
        </div>
      </div>
    </div>
  );
};

const Point = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loginModal, setLoginModal] = useState(false);
  const [pointInfo, setPointInfo] = useState(null);
  // GET POINT
  const today = new Date();
  const postStartDay = today.toISOString().slice(0, 10);

  const oneMonthDay = new Date(today);
  oneMonthDay.setDate(today.getDate() - 30);

  const [endDt, setEndDt] = useState(oneMonthDay);
  const postEndDay = endDt.toISOString().slice(0, 10);

  const getPointInfo = async () => {
    const id = localStorage.getItem('memberId');
    try {
      const result = await pointInfoGET(id, postEndDay, postStartDay);
      setPointInfo(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPointInfo();
  }, []);
  return (
    <div className="point_section">
      <div className="showup_point_info flex flex_dir_c flex_jc_c flex_ai_c">
        <div className="my_nickname">{JSON.parse(localStorage.getItem('userProfiles')).nickname}님 포인트</div>
        <div className="total_point">
          {pointInfo !== null ? setPrice(pointInfo.totalPoint) + ' 포인트' : <Skeleton itemWid={135} itemHei={40} />}{' '}
        </div>
        <div className="btn_box">
          <div onClick={() => setLoginModal(true)} className="button">
            포인트 전환하기
          </div>
        </div>
      </div>
      <div className={`shadow_bg fixed ${loginModal}`} onClick={() => setLoginModal(false)}></div>
      <DreegoLogin
        loginModal={loginModal}
        close={() => setLoginModal(false)}
        totalPoint={pointInfo !== null ? pointInfo.totalPoint : ''}
      />
      <div className="one_month_list ">
        <div className="list_header relative flex flex_jc_sb flex_ai_c">
          <div className="title">최근 한 달 포인트 내역</div>
          {/* <div className="more_btn" onClick={() => navigate('/mypage/point-log')}>
            상세조회
          </div> */}
        </div>
        <ul className="point_log_list">
          {pointInfo !== null ? (
            pointInfo.logResponses?.length > 0 ? (
              <>
                <PointDefaultLog data={pointInfo.logResponses} />
                {/* <Skeleton type={'point_log'} /> */}
              </>
            ) : (
              <li className="empty_list">최근 적립 된 포인트가 없습니다.</li>
            )
          ) : (
            <Skeleton type={'point_log'} loop={6} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default Point;
