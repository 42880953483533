export const Privacy = [
  {
    Privacy20231027:
      `메이저월드(주)(이하”회사”)은(는) 회사가 운영하는 인터넷사이트(https://showup.co.kr/, 이하 “쇼업”) 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의 서비스를\n` +
      `이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다. 이에 회사는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에\n` +
      `관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다. 회사는 개인정보 처리방침을 통하여\n` +
      `이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다\n` +
      `회사는 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.\n` +
      `\n` +
      `회사의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 처리방침의 지속적인 개선을 위하여 필요한 절차를\n` +
      `정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우나 개인정보 처리방침 변경될 경우 쇼핑몰의 첫페이지의 개인정보처리방침을 통해 고지하고 있습니다. 이용자들께서는 사이트 방문 시\n` +
      `수시로 확인하시기 바랍니다.\n` +
      `\n` +
      `회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.\n` +
      `\n` +
      `1. 개인정보 수집에 대한 동의\n` +
      `2. 개인정보의 수집목적 및 이용목적\n` +
      `3. 수집하는 개인정보 항목 및 수집방법\n` +
      `4. 수집하는 개인정보의 보유 및 이용기간\n` +
      `5. 수집한 개인정보의 공유 및 제공\n` +
      `6. 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항\n` +
      `7. 쿠키(Cookie)의 운용 및 거부\n` +
      `8. 비회원 고객의 개인정보 관리\n` +
      `9. 개인정보의 위탁처리\n` +
      `10. 개인정보관련 의견수렴 및 불만처리에 관한 사항\n` +
      `11. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처\n` +
      `12. 아동의 개인정보보호\n` +
      `13. 고지의 의무\n` +
      `\n` +
      `제1조 개인정보 수집에 대한 동의\n` +
      `\n` +
      `회사는 이용자들이 회사의 개인정보 처리방침 또는 이용약관의 내용에 대하여 「동의」버튼 또는「취소」버튼을 클릭할 수 있는 절차를 마련하여, 「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.\n` +
      `\n` +
      `제2조 개인정보의 수집목적 및 이용목적\n` +
      `\n` +
      `1) 회원으로부터 수집\n` +
      `\n` +
      `- 회원제 서비스 본인 확인 절차\n` +
      `\n` +
      `수집항목 : 이름, 아이디, 비밀번호, 성별, 생년월일, 연령정보, CI/DI값, 내/외국인정보, 본인확인결과 (회원탈퇴 시 또는 법정의무 보유기간)\n` +
      `\n` +
      `- 본인 확인 절차\n` +
      `\n` +
      `수집항목 : 이름, 휴대폰번호, 성별, 생년월일, CI/DI값, 내/외국인정보, 본인확인결과 (법정의무 보유기간)\n` +
      `\n` +
      `- 고객문의, 이벤트관리\n` +
      `\n` +
      `수집항목 : 이메일주소, 전화번호 (법정의무 보유기간)\n` +
      `\n` +
      `- 요금결재 및 정산\n` +
      `\n` +
      `수집항목 : 계좌번호, 신용카드정보 (법정의무 보유기간)\n` +
      `\n` +
      `- 물품배송\n` +
      `\n` +
      `수집항목 : 주소, 전화번호 (법정의무 보유기간)\n` +
      `\n` +
      `- 불량회원 부정 이용 방지 및 비인가 사용 방지\n` +
      `\n` +
      `수집항목 : IP Address (법정의무 보유기간)\n` +
      `\n` +
      `- 14세 미만 가입자의 법정대리인 정보 수집\n` +
      `\n` +
      `수집항목 : 법정 대리인 동의 여부 확인 (법정의무 보유기간)\n` +
      `\n` +
      `\n` +
      `2) SNS 가입(카카오)\n` +
      `\n` +
      `\n` +
      `- 이용자 식별, 회원관리 및 서비스 제공, 구매물품 배송 및 주문확인\n` +
      `\n` +
      `필수항목 : 카카오계정(이메일), 카카오계정(전화번호), 프로필 정보(닉네임/프로필사진)\n` +
      `\n` +
      `선택항목 : 연령대, 생년월일, 성별, 배송지정보(수령인명, 배송지주소, 전화번호)\n` +
      `\n` +
      `※ 보유기간 : 회원탈퇴 시 지체없이 파기\n` +
      `\n` +
      `- 회원비교식별\n` +
      `\n` +
      `CI(연계정보)\n` +
      `\n` +
      `※ 보유기간 : 제공목적 달성 후 지체없이 파기\n` +
      `\n` +
      `3) SNS 가입(네이버)\n` +
      `\n` +
      `- 이용자 식별, 회원관리 및 서비스 제공, 구매물품 배송 및 주문확인\n` +
      `\n` +
      `필수항목 : 네이버계정(ID), 프로필 정보(닉네임/프로필사진)\n` +
      `선택항목 : 생년월일, 성별, 배송지정보(수령인명, 배송지주소, 전화번호)\n` +
      `\n` +
      `※ 보유기간 : 회원탈퇴 시 지체없이 파기\n` +
      `\n` +
      `- 회원비교식별\n` +
      `\n` +
      `CI(연계정보)\n` +
      `\n` +
      `※ 보유기간 : 제공목적 달성 후 지체없이 파기\n` +
      `\n` +
      `4) 스마트앱을 통한 수집\n` +
      `\n` +
      `- 앱 설치 시 자동 수집\n` +
      `\n` +
      `고유기기 식별번호, 단말기 기기명, OS버전(회원탈퇴 시 또는 법정의무 보유기간)\n` +
      `\n` +
      `제3조 수집하는 개인정보 항목 및 수집방법\n` +
      `\n` +
      `회사는 이용자들이 회원서비스를 이용하기 위해 회원으로 가입하실 때 서비스 제공을 위한 필수적인 정보들을 온라인상에서 입력 받고 있습니다. 회원 가입 시에 받는 필수적인 정보는 이름, 이메일 주소\n` +
      `등입니다. 또한 양질의 서비스 제공을 위하여 이용자들이 선택적으로 입력할 수 있는 사항으로서 전화번호 등을 입력 받고 있습니다.\n` +
      `또한 쇼핑몰 내에서의 설문조사나 이벤트 행사 시 통계분석이나 경품제공 등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다. 그러나, 이용자의 기본적 인권 침해의 우려가 있는 민감한\n` +
      `개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시 구할 것입니다.\n` +
      `그리고, 어떤 경우에라도 입력하신 정보를 이용자들에게 사전에 밝힌 목적 이외에 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.\n` +
      `\n` +
      `제4조 수집하는 개인정보의 보유 및 이용기간\n` +
      `\n` +
      `회사는 이용자가 쇼핑몰 회원으로서 회사에 제공하는 서비스를 이용하는 동안 이용자들의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다. 다만, 아래의 \"6. 이용자 자신의 개인정보\n` +
      `관리(열람,정정,삭제 등)에 관한 사항\" 에서 설명한 절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에 의하여 디스크에서\n` +
      `완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로 처리됩니다.\n` +
      `\n` +
      `그리고 \"3. 수집하는 개인정보 항목 및 수집방법\" 에서와 같이 일시적인 목적 (설문조사, 이벤트, 본인확인 등)으로 입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이 불가능한 상태로 처리됩니다.\n` +
      `\n` +
      `\n` +
      `귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을 원칙으로 합니다. 그리고 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여\n` +
      `보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.\n` +
      `\n` +
      `- 계약 또는 청약철회 등에 관한 기록 : 5년\n` +
      `- 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n` +
      `- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n` +
      `\n` +
      `회사는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.\n` +
      `\n` +
      `- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.\n` +
      `- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.\n` +
      `\n` +
      `제5조 수집한 개인정보의 공유 및 제공\n` +
      `\n` +
      `회사는 이용자들의 개인정보를 \"2. 개인정보의 수집목적 및 이용목적\"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의\n` +
      `개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.\n` +
      `\n` +
      `- 이용자들이 사전에 공개에 동의한 경우\n` +
      `- 서비스 제공에 따른 요금정산을 위하여 필요한 경우\n` +
      `- 홈페이지에 게시한 서비스 이용 약관 및 기타 회원 서비스 등의 이용약관 또는 운영원칙을 위반한 경우\n` +
      `- 자사 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우\n` +
      `- 기타 법에 의해 요구된다고 선의로 판단되는 경우 (ex. 관련법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)\n` +
      `- 통계작성, 학술연구나 시장조사를 위하여 특정개인을 식별할 수 없는 형태로 광고주, 협력업체나 연구단체 등에 제공하는 경우\n` +
      `\n` +
      `제6조 개인정보의 제 3자 제공\n` +
      `\n` +
      `1. 회사는 회원의 동의가 있거나 관련 법령의 규정에 의한 경우를 제회하고는 본 개인정보 처리방침에서 고지한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 제공하지 않습니다.\n` +
      `2. 회사는 회원에게 높은 품질의 서비스 제공 등을 위하여 아래와 같이 회원 개인정보를 제3자에게 제공하고 있습니다.\n` +
      `\n` +
      `※개인정보 제3자 제공 (배송, 상담 등)\n` +
      `\n` +
      `고객님의 개인정보를 가입신청서, 서비스이용약관, 「개인정보처리방침」의「개인정보의 수집 및 이용목적」에서 알린 범위 내에서 사용하며, 이 범위를 초과하여 이용하거나 타인 또는 다른 기업 • 기관에 제공하지 않습니다. 단, 고객의 동의가 있거나 법령에 의하여 정해진 절차에 따라 정보를 요청받은 경우는 예외로 하며, 이 경우 주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.\n` +
      `\n` +
      `[쇼핑몰]\n` +
      `\n` +
      `- \n이용목적 : 쇼핑몰 구매 상품 배송 및 상담\n` +
      `\n` +
      `- 제공 항목 : 이름(한글,영문), 연락처,주소,집전화(선택), 이메일주소\n` +
      `\n` +
      `＊구매자와 수취인이 다를 경우에는 수취인의 정보가 제공될 수 있습니다.\n` +
      `\n` +
      `- 제공받는 자\n` +
      `\n` +
      `3HC컴퍼니,골프앤드(예스스트리트),골프필드,군자실업,그룹스,모빈네트웍스 글로벌,까스텔바작,넥스트플레이어,더진엠씨,도원,디비씽크 ,디앤와이인터내셔널,디엔케이네트웍스,뜨레,럭스네트웍스,로볼코리아,리앤쿡코리아, 매트로,베니펫,비비드글라스,사라토가 골프,사사키,사우인터내셔날,삼정네트웍스,선덜랜드코리아,세이션,솔티드 주식회사,스페셜그라운드,스페셜노블레스그룹,아이비케어,에스비스포츠,에이쇼핑,엑스넬스코리아,예가스포츠,유디피플,이레씨엔에프,인커머스,인프럭스코리아,정글히어로,제이투엠 네트웍스,조아골프,주식회사 렉시,주식회사 미카도골프,주연홈쇼핑,지브이투어,지비컴퍼니(골프붐),지온컴퍼니,에스제이라이언,케이에스스포츠,코비스스포츠,코웰패션(씨에프),파르마인터내셔널,파인캐디,페르시안캣(디자인씨),제이디스포츠,플레이버,피지에이패션 조아패션,한국다이와주식회사 (낚시),한성에프아이,한신프로젝트,홍인규TV(착한형님),B&E컴퍼니,이룸디엔피,박사장몰(BSJMALL)_포시즌,주식회사 구스(GOOSE),해피키친,주식회사투힘,보이스캐디,주식회사 에스엔케이글로벌,리드통상,케이앤코리아,(주) 신한코리아\n` +
      `\n` +
      `- 보유 및 이용기간 : 개인정보 이용목적 달성시까지 (단, 관계 법령의 규정에 의해 보존의 필요가 있는 경우 및 사전동의를 득한 경우는 해당 기간까지)\n` +
      `\n` +
      `\n` +
      `※개인정보 제3자 제공(마케팅)\n` +
      `\n` +
      `메이저월드(주)는 마케팅을 위해 아래와 같이 개인정보를 제3자에게 제공하고 있으며, 제공받은 업체가 관련 법령을 준수하도록 관리/감독하고 있습니다.\n` +
      `\n` +
      `[이벤트 응모 및 당첨]\n` +
      `\n` +
      `- 이용목적 : 회원가입 및 이벤트 당첨자선정/경품전달\n` +
      `- 제공 항목 : 이름(한글,영문),연락처,주소,집전화(선택)\n` +
      `\n` +
      `- 제공받는 자\n` +
      `\n` +
      `3HC컴퍼니,골프앤드(예스스트리트),골프필드,군자실업,그룹스,모빈네트웍스 글로벌,까스텔바작,넥스트플레이어,더진엠씨,도원,디비씽크 ,디앤와이인터내셔널,디엔케이네트웍스,뜨레,럭스네트웍스,로볼코리아,리앤쿡코리아, 매트로,베니펫,비비드글라스,사라토가 골프,사사키,사우인터내셔날,삼정네트웍스,선덜랜드코리아,세이션,솔티드 주식회사,스페셜그라운드,스페셜노블레스그룹,아이비케어,에스비스포츠,에이쇼핑,엑스넬스코리아,예가스포츠,유디피플,이레씨엔에프,인커머스,인프럭스코리아,정글히어로,제이투엠 네트웍스,조아골프,주식회사 렉시,주식회사 미카도골프,주연홈쇼핑,지브이투어,지비컴퍼니(골프붐),지온컴퍼니,에스제이라이언,케이에스스포츠,코비스스포츠,코웰패션(씨에프),파르마인터내셔널,파인캐디,페르시안캣(디자인씨),제이디스포츠,플레이버,피지에이패션 조아패션,한국다이와주식회사 (낚시),한성에프아이,한신프로젝트,홍인규TV(착한형님),B&E컴퍼니,이룸디엔피,박사장몰(BSJMALL)_포시즌,주식회사 구스(GOOSE),해피키친,주식회사투힘,보이스캐디,주식회사 에스엔케이글로벌,리드통상,케이앤코리아,(주) 신한코리아\n` +
      `\n` +
      `- 보유 및 이용기간 : 개인정보 이용목적 달성시까지 (단, 관계 법령의 규정에 의해 보존의 필요가 있는 경우 및 사전동의를 득한 경우는 해당 기간까지)\n` +
      `\n` +
      `[예약 및 커뮤니티 서비스]\n` +
      `\n` +
      `- 이용목적 : 이벤트 및 프로모션 홍보 가입권유 및 관련 민원처리 신규 제휴 서비스 안내 제휴 마케팅 활용\n` +
      `- 제공 항목 : 이름, ID, 생년월일, 성별, 휴대폰번호, 주소, 회원등급, 가입일, 이메일 주소, 약관동의일\n` +
      `- 제공받는 자\n` +
      `\n` +
      `3HC컴퍼니,골프앤드(예스스트리트),골프필드,군자실업,그룹스,모빈네트웍스 글로벌,까스텔바작,넥스트플레이어,더진엠씨,도원,디비씽크 ,디앤와이인터내셔널,디엔케이네트웍스,뜨레,럭스네트웍스,로볼코리아,리앤쿡코리아, 매트로,베니펫,비비드글라스,사라토가 골프,사사키,사우인터내셔날,삼정네트웍스,선덜랜드코리아,세이션,솔티드 주식회사,스페셜그라운드,스페셜노블레스그룹,아이비케어,에스비스포츠,에이쇼핑,엑스넬스코리아,예가스포츠,유디피플,이레씨엔에프,인커머스,인프럭스코리아,정글히어로,제이투엠 네트웍스,조아골프,주식회사 렉시,주식회사 미카도골프,주연홈쇼핑,지브이투어,지비컴퍼니(골프붐),지온컴퍼니,에스제이라이언,케이에스스포츠,코비스스포츠,코웰패션(씨에프),파르마인터내셔널,파인캐디,페르시안캣(디자인씨),제이디스포츠,플레이버,피지에이패션 조아패션,한국다이와주식회사 (낚시),한성에프아이,한신프로젝트,홍인규TV(착한형님),B&E컴퍼니,이룸디엔피,박사장몰(BSJMALL)_포시즌,주식회사 구스(GOOSE),해피키친,주식회사투힘,보이스캐디,주식회사 에스엔케이글로벌,리드통상,케이앤코리아,(주) 신한코리아\n` +
      `\n` +
      `- 보유 및 이용기간 : 개인정보 이용목적 달성시까지 (단, 관계 법령의 규정에 의해 보존의 필요가 있는 경우 및 사전동의를 득한 경우는 해당 기간까지)\n` +
      `\n` +
      `마케팅 동의는 회원가입 선택약관으로 회원께서 동의하신 경우에만 해당업체에 정보가 제공되며,본 선택항목에 대해 동의하지 않아도 회원가입, 상품구매 등 기본서비스는 정상적으로 이용이 가능합니다\n` +
      `\n` +
      `제7조 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항\n` +
      `\n` +
      `회원님이 원하실 경우 언제라도 당사에서 개인정보를 열람하실 수 있으며 보관된 필수 정보를 수정하실 수 있습니다. 또한 회원 가입 시 요구된 필수 정보 외의 추가 정보는 언제나 열람,\n` +
      `수정, 삭제할 수 있습니다. 회원님의 개인정보 변경 및 삭제와 회원탈퇴는 당사의 고객센터에서 로그인(Login) 후 이용하실 수 있습니다.\n` +
      `\n` +
      `제8조 쿠키(Cookie)의 운용 및 거부\n` +
      `\n` +
      `가. 쿠키의 사용 목적\n` +
      `① 회사는 개인 맞춤 서비스를 제공하기 위해서 이용자에 대한 정보를 저장하고 수시로 불러오는 \'쿠키(cookie)\'를 사용합니다. 쿠키는 웹사이트 서버가 이용자의 브라우저에게 전송하는 소량의 정보로서 이용자 컴퓨터의 하드디스크에 저장됩니다.\n` +
      `② 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다.\n` +
      `③ 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.\n` +
      `\n` +
      `나. 쿠키의 설치/운용 및 거부\n` +
      `① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.\n` +
      `- 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.\n` +
      `\n` +
      `[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.\n` +
      `[개인정보 탭]을 클릭합니다.\n` +
      `[개인정보처리 수준]을 설정하시면 됩니다.\n` +
      `② 쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스는 이용이 어려울 수 있습니다.\n` +
      `\n` +
      `제9조 개인정보의 위탁처리\n` +
      `\n` +
      `회사은(는) 서비스 향상을 위해서 귀하의 개인정보를 필요한 경우 동의 등 법률상의 요건을 구비하여 외부에 수집 • 처리 • 관리 등을 위탁하여 처리할 있으며, 제 3자에게 제공할 수 있습니다.\n` +
      `- 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 또한 공유하는 정보는 당해 목적을 달성하기 위하여 필요한 최소한의 정보에 국한됩니다.\n` +
      `수탁자 - 한진택배, CJ대한통운(주), (주)KG로지스,로젠택배,(주)드림플러스 외 기타 택배사\n` +
      `위탁 업무 내용 - 상품배송업무\n` +
      `수탁자 - NHNKCP,KG이니시스,토스페이먼츠,올더게이트,KG모빌리언스,나이스페이,이지페이,네이버페이,카카오페이\n` +
      `\n` +
      `위탁 업무 내용 - 결제 처리\n` +
      `\n` +
      `수탁자 - 비즈엠, 드림문자, KP모바일, TasOn,㈜ 카카오㈜, 바이앱스\n` +
      `\n` +
      `위탁 업무 내용 - 문자, 메일, 카카오알림톡, 앱푸쉬 발송\n` +
      `\n` +
      `수탁자 - 메이저월드㈜, 엔에이치엔고도(주)\n` +
      `\n` +
      `위탁 업무 내용 - 개인정보 위탁처리(서비스의 개발 및 운영, 시스템 운영, 서비스 이용, 분석 등)\n` +
      `\n` +
      `수탁자 - 엠더블유홀딩스㈜, 메이저월드㈜\n` +
      `\n` +
      `위탁 업무 내용 - 고객센터(회원 불만건 처리, 사이트 이용의 편의성 제공)\n` +
      `\n` +
      `수탁자 - 업체리스트 [3HC컴퍼니,골프앤드(예스스트리트),골프필드,군자실업,그룹스,모빈네트웍스 글로벌,까스텔바작,넥스트플레이어,더진엠씨,도원,디비씽크 ,디앤와이인터내셔널,디엔케이네트웍스,뜨레,럭스네트웍스,로볼코리아,리앤쿡코리아,매트로,베니펫,비비드글라스,사라토가 골프,사사키,사우인터내셔날,삼정네트웍스,선덜랜드코리아,세이션,솔티드 주식회사,스페셜그라운드,스페셜노블레스그룹,아이비케어,에스비스포츠,에이쇼핑,엑스넬스코리아,예가스포츠,유디피플,이레씨엔에프,인커머스,인프럭스코리아,정글히어로,제이투엠 네트웍스,조아골프,주식회사 렉시,주식회사 미카도골프,주연홈쇼핑,지브이투어,지비컴퍼니(골프붐),지온컴퍼니,에스제이라이언,케이에스스포츠,코비스스포츠,코웰패션(씨에프),파르마인터내셔널,파인캐디,페르시안캣(디자인씨),제이디스포츠,플레이버,피지에이패션 조아패션,한국다이와주식회사 (낚시),한성에프아이,한신프로젝트,홍인규TV(착한형님),B&E컴퍼니,이룸디엔피,박사장몰(BSJMALL)_포시즌,주식회사 구스(GOOSE),해피키친,주식회사투힘,보이스캐디,주식회사 에스엔케이글로벌,리드통상,케이앤코리아,(주) 신한코리아]\n` +
      `\n` +
      `위탁 업무 내용 - 거래이행,배송,고객상담,AS,불만처리 등\n` +
      `\n` +
      `수탁자 - 니블스카이㈜\n` +
      `\n` +
      `위탁 업무 내용 - 웹로그 및 CRM 분석, 온사이트 마케팅,개인화 추천,웹/앱 push\n` +
      `\n` +
      `- 다만, 아래의 경우에는 예외로 합니다.\n` +
      `\n` +
      `= 이용자들이 사전에 동의한 경우\n` +
      `\n` +
      `= 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우\n` +
      `\n` +
      `- 개인정보의 처리를 위탁하거나 제공하는 경우에는 수탁자, 수탁범위, 공유 정보의 범위 등에 관한 사항을 서면, 전자우편, 전화 또는 홈페이지를 통해 미리 귀하에게 고지합니다.\n` +
      `\n` +
      `제10조 비회원고객의 개인정보관리\n` +
      `\n` +
      `- 회사는 비회원 고객 또한 물품 및 서비스 상품의 구매를 하실 수 있습니다. 당사는 비회원 주문의 경우 배송 및 대금 결제, 상품 배송에 반드시 필요한 개인정보만을 고객님께 요청하고 있습니다.\n` +
      `- 회사에서 비회원으로 구입을 하신 경우 비회원 고객께서 입력하신 지불인 정보 및 수령인 정보는 대금 결제 및 상품 배송에 관련한 용도 외에는 다른 어떠한 용도로도 사용되지 않습니다.\n` +
      `\n` +
      `제11조 개인정보관련 의견수렴 및 불만처리에 관한 사항\n` +
      `\n` +
      `회사는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 이용자들은 하단에 명시한 \"11. 개인정보\n` +
      `관리책임자 및 담당자의 소속-성명 및 연락처\"항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 회사는 이용자들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.\n` +
      `\n` +
      `제12조 개인정보 보호책임자 및 담당자의 소속-성명 및 연락처\n` +
      `\n` +
      `회사는 귀하가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에 반하는 사고가 발생할 경우 개인정보보호책임자가 책임을 집니다.\n` +
      `이용자 개인정보와 관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게 있습니다. 회사는 비밀번호에 대해 어떠한 방법으로도 이용자에게 직접적으로 질문하는 경우는\n` +
      `없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다. 특히 공공장소에서 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다.\n` +
      `회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 보호책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다.\n` +
      `\n` +
      `이　　　 름 : 김태진\n` +
      `\n` +
      `소속 / 직위　: 부사장\n` +
      `\n` +
      `E-M A I L 　 : help@mwd.kr\n` +
      `전 화 번 호　: 1544-9332\n` +
      `\n` +
      `기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.\n` +
      `\n` +
      `1. 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)\n` +
      `2. 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)\n` +
      `3. 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)\n` +
      `\n` +
      `제13조 아동의 개인정보보호\n` +
      `\n` +
      `회사는 온라인 환경에서 만 14세 미만 어린이의 개인정보를 보호하는 것 역시 중요한 일이라고 생각하고 있습니다.\n` +
      `회사는 만 14세 미만의 어린이들은 법정대리인의 동의가 없는 한 회원으로 가입할 수 없게 하고 있습니다. 즉, 만 14세 미만의 어린이들은 법정대리인의 동의가 있을 때만 회사에서 회원 자격의 서비스를 받을 수 있습니다.\n` +
      `\n` +
      `\n` +
      `제14조 고지의 의무\n` +
      `\n` +
      `현 개인정보처리방침의 내용은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 홈페이지의 \'공지사항\'을 통해 고지할 것입니다.\n`,
  },
];
