// MODULE
import { useEffect } from 'react';
import { Outlet, useLocation, ScrollRestoration } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
// RECOIL STATE
import { messageUserState } from '../../state/userState';
import { messageMenuState } from '../../state/messageState';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';

const Message = () => {
  const [pageTitle, setPageTitle] = useRecoilState(messageUserState);
  const [messageMenu, setMessageMenu] = useRecoilState(messageMenuState);

  const location = useLocation();
  const isDetail = location.pathname.includes('/detail');
  const isReview = location.pathname.includes('/review');

  const resetTitle = useResetRecoilState(messageUserState);
  useEffect(() => {
    resetTitle();
  }, [isDetail]);
  const handleOpenMenu = () => {
    setMessageMenu(true);
  };
  const pageType = window.location.pathname.includes('review') || window.location.pathname.includes('list');

  return (
    <div className="message_section">
      <div className={`shadow_bg fixed ${messageMenu}`} onClick={() => setMessageMenu(false)}></div>
      <div className="page_title flex flex_jc_sb flex_ai_c">
        {isDetail || isReview ? <HistoryBack /> : <div></div>}
        <div className="txt">{isReview ? '후기 남기기' : pageTitle === '' ? '채팅' : pageTitle}</div>
        {pageType ? (
          <div className="dotted_btn flex flex_dir_c flex_jc_sb flex_ai_c"></div>
        ) : (
          <button className="dotted_btn flex flex_dir_c flex_jc_sb flex_ai_c" onClick={() => handleOpenMenu()}>
            <div></div>
            <div></div>
            <div></div>
          </button>
        )}
      </div>
      <div className="message_body">
        <ScrollRestoration />
        <Outlet />
      </div>
    </div>
  );
};

export default Message;
