import axios from '../utils/instance';

const uri = '/notices';

// GET LIST
export const noticeListGET = (page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}`, { headers: { 'Content-Type': 'application/json' }, params: { page: page } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET DETAIL
export const noticeDetailGET = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${id}`, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
