const Contents = ({ content, change, error }) => {
  return (
    <div className="contents_box text_box relative flex flex_wrap_wrap">
      <label htmlFor="">내용</label>
      <textarea
        name=""
        id=""
        cols=""
        rows={10}
        onChange={change}
        value={content}
        maxLength={500}
        placeholder="내용을 10자 이상 입력해 주세요."
      ></textarea>
      <div className={`length_count absolute ${content.length === 0 ? 'zero' : ''}`}>{content.length}/500</div>
      <span className="event_txt absolute">{error === 4 ? '*내용을 10자 이상 입력해주세요.' : ''}</span>
    </div>
  );
};

export default Contents;
