// MODULES
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams, useOutletContext } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet-async';
// HOOKS
import { getElapsedTime, getRemainingPeriod } from '../../utils/timeEffect';
import { detailGET, articleLikePOST, articleLikeDELETE } from '../../api/article';
import { refreshTokenGET } from '../../api/auth';
import { setPrice } from '../../utils/textEffect';
// RECOIL STATE
import { urlCopyInfoState } from '../../state/userState';
// COMPONENT
import WriterItems from '../../components/WriterItems';
import DetailHeader from '../../components/Detail/DetailHeader/DetailHeader';
import WriterInfo from '../../components/Detail/WrtierInfo';
import ThumbSlide from '../../components/ThumbSlide/ThumbSlide';
import DetailNavigation from '../../components/Detail/DetailNavigation/DetailNavigation';
import KakaoMapImage from '../../components/KakaoMap/KakaoMapImage';
import ShareBox from '../../components/ShareBox/ShareBox';
import ModalFrame from '../../components/Modal';
import Skeleton from '../../components/Skeleton';
import ToastBottomMenu from '../../components/ToastBottomMenu/ToastBottomMenu';
// IMAGE

const ArticleDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginCheck } = useOutletContext();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const pageNo = Number(searchParams.get('info'));

  const [urlInfo, setUrlInfo] = useRecoilState(urlCopyInfoState);
  const [result, setResult] = useState('');
  const [menuState, setMenuState] = useState(false);
  const [kakaoMapApiLoaded, setKakaoMapApiLoaded] = useState(false);

  const [shareModal, setShareModal] = useState(false);
  const [likeState, setLikeState] = useState(null);
  const [alertModal, setAlertModal] = useState(0);

  const getData = async () => {
    try {
      const result = await detailGET(pageNo);
      setResult(result.data);
      setKakaoMapApiLoaded(true);
      setLikeState(result.data.isLiked);
      setUrlInfo({
        title: '쇼업(Showup)',
        description: result.data.productName,
        image: process.env.REACT_APP_IMAGE_URL + result.data.memberStoredProfileImagePath,
      });
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        try {
          const result = await refreshTokenGET();
        } catch (error) {
          setAlertModal(1);
        }
      } else if (error.response.status === 404 && error.response.data.message === '게시글이 존재하지 않습니다.') {
        setAlertModal(2);
      } else {
      }
    }
  };
  useEffect(() => {
    getData();
  }, [location]);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const header = document.querySelector('.detail_header');
        if (header !== null) {
          if (entry.isIntersecting) {
            header.classList.add('active');
          } else if (header.classList.contains('active')) {
            header.classList.remove('active');
          }
        }
      });
    }, options);
    const boxList = document.querySelectorAll('.img_slide');
    boxList.forEach((el) => observer.observe(el));
  }, []);

  // ARTICLE LIKE FUNCTION
  const handleLikeArticle = async () => {
    try {
      const response = await articleLikePOST(result.id);
      getData();
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnLikeArticle = async () => {
    try {
      const response = await articleLikeDELETE(result.id);
      getData();
    } catch (error) {
      console.log(error);
    }
  };
  // SHARE BOX
  const handleShareBoxOpen = () => {
    setShareModal(true);
  };
  const handleShareBoxClose = () => {
    setShareModal(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, location]);
  // OPEN MENU
  const handleMenuOpen = () => {
    setMenuState(true);
  };
  const handleMenuClose = () => {
    setMenuState(false);
  };
  const isEndArticle = getRemainingPeriod(result.endDay) === result.endDay + '에 종료됨';
  const fromWrite = state?.isWrite;
  const notImages = result?.storedProductImagePaths?.length === 0;
  return (
    <>
      {alertModal === 1 ? (
        <ModalFrame
          custom_txt={'쇼업 회원만 볼 수 있어요.\n 회원가입 페이지로 이동하시겠어요?'}
          type={'type_3'}
          confirm={() => (window.location.href = '/phone-certification')}
          buttons={() => (window.location.href = '/')}
        />
      ) : alertModal === 2 ? (
        <ModalFrame
          custom_txt={'삭제되거나 기간이 만료된 아이템입니다.'}
          type={'type_4'}
          confirm={() => navigate(-1)}
        />
      ) : (
        ''
      )}
      <div className={`detail_section ${notImages ? 'type_normal' : ''}`}>
        <div
          className={`shadow_bg fixed ${menuState || shareModal}`}
          onClick={() => (setShareModal(false), setMenuState(false))}
        ></div>
        <DetailHeader
          notImages={notImages}
          fromWrite={fromWrite}
          openMenu={handleMenuOpen}
          isMyArticle={result.isMyArticle}
          homeBtn={true}
        />
        {result.storedProductImagePaths === undefined ? (
          <Skeleton itemWid={'100%'} itemHei={360} type={'normal'} />
        ) : notImages ? (
          ''
        ) : (
          <ThumbSlide imgs={result?.storedProductImagePaths} isDayOver={isEndArticle} />
        )}
        <WriterInfo
          nickname={result.writerNickname}
          profile={result?.writerStoredProfileImagePath === null ? null : result?.writerStoredProfileImagePath}
          like={result.likeCount}
          message={result.buyRequestCount}
          userId={result.writerId}
          myArticle={result.isMyArticle}
          data={result}
          loginCheck={loginCheck}
        />
        <div className="detail_body">
          <div className="title">
            {result.productName === undefined ? <Skeleton itemWid={'45%'} itemHei={19} /> : result.productName}
          </div>
          <div className="location_info flex">
            <div className="market">
              {result.regionName3depth === undefined ? <Skeleton itemWid={50} itemHei={13} /> : result.regionName3depth}
            </div>
            &nbsp;·&nbsp;
            <div className="area">
              {result.placeName === undefined ? <Skeleton itemWid={50} itemHei={13} /> : result.placeName}
            </div>
            &nbsp;·&nbsp;
            {result.createdAt === undefined ? (
              <Skeleton itemWid={25} itemHei={13} />
            ) : (
              <div className="reg_dt">{getElapsedTime(result.createdAt)}</div>
            )}
            &nbsp;·&nbsp;
            {result.hitCount === undefined ? (
              <Skeleton itemWid={30} itemHei={13} />
            ) : (
              <div className="reg_dt">조회 {result.hitCount}</div>
            )}
          </div>
          <div className="price">
            {result.price === undefined ? (
              <Skeleton itemWid={'25%'} itemHei={19} />
            ) : (
              <>
                {setPrice(result.price)}
                <span>원</span>
              </>
            )}
          </div>
          <div className="contents">
            {result.content === undefined ? (
              <Skeleton itemWid={'80%'} itemHei={20} marTop={4} loop={3} />
            ) : (
              result.content
            )}
          </div>
          <div className="map">
            <div className="sub_title">상품 판매 장소</div>
            {kakaoMapApiLoaded ? (
              <KakaoMapImage
                key={`${result.latitude}-${result.longitude}-${result.placeName}`}
                getLat={result.latitude}
                getLon={result.longitude}
                placeName={result.placeName}
              />
            ) : (
              <Skeleton itemWid={'100%'} itemHei={175} />
            )}
          </div>
          {isEndArticle ? (
            ''
          ) : (
            <WriterItems
              items={result.writerArticleInfo}
              nickname={result.writerNickname}
              totalCount={result.writerArticleCount}
              isMyArticle={result.isMyArticle}
              loginCheck={loginCheck}
            />
          )}
        </div>
        <DetailNavigation
          isPurchasable={result.isPurchasable}
          articleId={result.id}
          isMyArticle={result.isMyArticle}
          isLiked={likeState}
          like={handleLikeArticle}
          unLike={handleUnLikeArticle}
          shareOpen={handleShareBoxOpen}
          loginCheck={loginCheck}
        />
        <ToastBottomMenu
          close={handleMenuClose}
          menuState={menuState}
          currentMenu={result.isMyArticle}
          articleId={result.id}
          data={result}
          isDayOver={isEndArticle}
          pages={'detail'}
          memberId={result.writerId}
          memberNickname={result.writerNickname}
          loginCheck={loginCheck}
        />
        {shareModal ? (
          <ShareBox
            shareState={shareModal}
            close={handleShareBoxClose}
            description={result.productName}
            image={result.storedProductImagePaths[0]}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ArticleDetail;
