import { atom } from 'recoil';

// LOCATION UPDATE
export const setFunctionOrderState = atom({
  key: 'functionOrder',
  default: false,
});

// SEARCH RESULT PAGE
export const searchResultPageState = atom({
  key: 'pageState',
  default: 1,
});

// BYAPPS LOCATION CHECK
export const setByAppsBottomNavigationState = atom({
  key: 'bottomState',
  default: 0,
});
// IS LOGIN
export const isLoginState = atom({
  key: 'isLogin',
  default: false,
});
