const LocationItem = ({ openMap, data, deleteItem }) => {
  return (
    <>
      {data?.map((region, index) => {
        return (
          <li key={index} className={region.regionName === '' ? 'add' : ''}>
            {region.regionName === '' ? (
              <button onClick={() => openMap(index)}>+</button>
            ) : (
              <button onClick={() => deleteItem(index)}>
                <span>{region.regionName}</span>
                <span>x</span>
              </button>
            )}
          </li>
        );
      })}
    </>
  );
};

export default LocationItem;
