// MODULE
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// RECOIL STATE
import { messageAlarmState } from '../../state/userState';
// UTIL
import { rememberPrevPage } from '../../utils/common';
// HOOKS
import { getNavEffect } from '../../utils/locationCheck';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import ModalFrame from '../Modal/ModalFrame';
// IMAGE
import { ReactComponent as HomeIcon } from '../../assets/img/icon/Home.svg';
import { ReactComponent as WriteIcon } from '../../assets/img/icon/Write.svg';
import { ReactComponent as LocationIcon } from '../../assets/img/icon/Location.svg';
import { ReactComponent as MessageIcon } from '../../assets/img/icon/Message.svg';
import { ReactComponent as MyPageIcon } from '../../assets/img/icon/MyPage.svg';

const Navigation = ({ loginCheck }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAlarm = useRecoilValue(messageAlarmState);

  const [alertModal, setAlertModal] = useState(0);
  const Type = getNavEffect(location.pathname);

  // USER LOCATION OPTION
  const regionOption = JSON.parse(localStorage.getItem('region'));
  const unsetLocation = regionOption?.filter((option) => option.code === '');

  // CLOSE MODAL
  const handleCloseAlert = () => {
    setAlertModal(0);
  };
  // HANDLE MOVE PAGE
  const handleMoveWritePage = () => {
    refreshTokenGET()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    unsetLocation?.length === 2 ? setAlertModal(1) : !loginCheck ? setAlertModal(2) : navigate('/write');
  };
  const handleMoveChartList = () => {
    refreshTokenGET()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    !loginCheck ? setAlertModal(2) : navigate('/message/list');
  };
  const handleMoveMyLocationSettingPage = () => {
    navigate('/mypage/location-setting');
  };
  return (
    <>
      {alertModal === 1 ? (
        <ModalFrame
          confirm={handleMoveMyLocationSettingPage}
          custom_txt={'내 지역을 등록해야만 글을 작성할 수 있어요. \n내지역을 등록하러 가시겠어요?'}
          buttons={handleCloseAlert}
          type={'type_3'}
        />
      ) : alertModal === 2 ? (
        <ModalFrame
          confirm={() => (rememberPrevPage(window.location.href), navigate('/login'))}
          custom_txt={'로그인을 해야 글을 작성할 수 있어요. \n로그인 하러 가시겠어요?'}
          buttons={handleCloseAlert}
          type={'type_3'}
        />
      ) : (
        ''
      )}
      <div className="bottom_nav fixed">
        <ul className="flex flex_jc_sb flex_ai_c">
          <li className={`flex flex_jc_sb flex_ai_c ${Type === 0 ? 'active' : ''}`}>
            <Link to="/home" className="flex flex_jc_sb flex_ai_c">
              <HomeIcon />
              <div className="txt">홈</div>
            </Link>
          </li>
          <li className={`flex flex_jc_sb flex_ai_c ${Type === 1 ? 'active' : ''}`}>
            <div onClick={() => handleMoveWritePage()} className="flex flex_jc_sb flex_ai_c cursor_p">
              <WriteIcon />
              <div className="txt">등록</div>
            </div>
          </li>
          <li className={`flex flex_jc_sb flex_ai_c ${Type === 2 ? 'active' : ''}`}>
            <Link to="/mylocation" className="flex flex_jc_sb flex_ai_c">
              <LocationIcon />
              <div className="txt">내 근처</div>
            </Link>
          </li>
          <li className={`message relative flex flex_jc_sb flex_ai_c ${Type === 3 ? 'active' : ''}`}>
            {isAlarm ? <div className="alarm absolute"></div> : ''}
            <div onClick={() => handleMoveChartList()} className="flex flex_jc_sb flex_ai_c">
              <MessageIcon />
              <div className="txt">채팅</div>
            </div>
          </li>
          <li className={`flex flex_jc_sb flex_ai_c ${Type === 4 ? 'active' : ''}`}>
            <Link to="/mypage/main" className="flex flex_jc_sb flex_ai_c">
              <MyPageIcon />
              <div className="txt">MY</div>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navigation;
