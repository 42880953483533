// MODULE
import { useEffect, useRef } from 'react';
// HOOKS
import { messageTimeStamp } from '../../utils/timeEffect';
import { normalDay } from '../../utils/common';
// COMPONENT
import Skeleton from '../Skeleton';

const MessageView = ({ message }) => {
  const myId = Number(localStorage.getItem(`memberId`));
  const lastListItemRef = useRef(null);

  useEffect(() => {
    if (lastListItemRef.current) {
      lastListItemRef.current.focus();
    }
  }, [message]);
  return (
    <ul>
      {message === null ? (
        <div className="flex flex_jc_c">
          <Skeleton itemWid={99} itemHei={30} marTop={30} />
        </div>
      ) : (
        message.map((chat, index, array) => {
          const prevMessage = array[index - 1];
          const isDifferentDay =
            !prevMessage || new Date(prevMessage.createdAt).toDateString() !== new Date(chat.createdAt).toDateString();

          return (
            <li
              ref={index === array.length - 1 ? lastListItemRef : null}
              key={index}
              className={`${myId === chat.memberId ? 'my flex_jc_e' : 'user flex_jc_s'} ${
                isDifferentDay ? 'time_set' : ''
              } flex`}
              tabIndex={-1}
            >
              {isDifferentDay && (
                <div className="chat_start_day flex flex_jc_c flex_ai_c">
                  <div>{normalDay(chat.createdAt)}</div>
                </div>
              )}
              {myId === chat.memberId ? (
                <>
                  <div className="create_dt flex_as_e">{messageTimeStamp(chat.createdAt)}</div>
                  <div className="text flex_as_c">{chat.content}</div>
                </>
              ) : (
                <>
                  <div className="text flex_as_c">{chat.content}</div>
                  <div className="create_dt flex_as_e">{messageTimeStamp(chat.createdAt)}</div>
                </>
              )}
            </li>
          );
        })
      )}
    </ul>
  );
};

export default MessageView;
