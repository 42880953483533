// MODULE
import { Suspense, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Outlet } from 'react-router-dom';
// RECOIL STATE
import { setByAppsBottomNavigationState } from '../state/commonState';
// HOOKS
import { refreshTokenGET } from '../api/auth';
import { byappsMenuSet } from '../utils/locationCheck';
// UTIL
import { deleteStorage } from '../utils/common';
import { getCookie } from '../utils/cookie';
// COMPONENT

const UserRouter = ({ children }) => {
  const [, setByAppsNav] = useRecoilState(setByAppsBottomNavigationState);
  const getRefreshTokenCheck = async () => {
    try {
      const response = await refreshTokenGET();
      if (response.status === 200) {
        // const time = 3600;
        // const expiration = new Date(Date.now() + time * 720000);
        // setCookie('isLogin', true, { expires: expiration });
      }
    } catch (error) {
      deleteStorage();
      if (error.response.status === 401) {
        alert('미로그인 사용자의 요청입니다. 로그인 하시기 바랍니다.');
        deleteStorage();
        window.location.href = '/';
      } else {
        alert('잘못된 접근입니다.');
        deleteStorage();
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    const refreshCheck = new Date(getCookie('isLogin')).getTime() - new Date().getTime();
    setByAppsNav(byappsMenuSet());
    if (refreshCheck > 0 || refreshCheck !== typeof Number) {
      getRefreshTokenCheck();
    } else {
      alert('미로그인 사용자의 요청입니다. 로그인 하시기 바랍니다.');
      deleteStorage();
      window.location.href = '/';
    }
  }, [window.location.pathname]);

  if (children) {
    return children;
  }

  return (
    <Suspense fallback={<></>}>
      <Outlet />
    </Suspense>
  );
};

export default UserRouter;
