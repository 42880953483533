// MODULES
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, ScrollRestoration } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
// HOOKS
import { clusterListGET } from '../../api/cluster';
// RECOIL STATE
import { clusterPlaceState, clusterPlaceIndexState, accessTokenCodeState } from '../../state/userState';
// COMPONENT
import ClusterSelect from '../../components/ClusterSelect/ClusterSelect';
import Header from '../../components/Header';
import ClusterItem from '../../components/ClusterItem/ClusterItem';

const ClustererList = () => {
  const { state } = useLocation();
  const [ref, inView] = useInView();
  const [listData, setListData] = useState([]);
  const [placeIndex] = useRecoilState(clusterPlaceIndexState);
  const [stateData, setStateData] = useState([]);
  const [, setEmptyMsg] = useState('');
  const [placeSelect, setPlaceSelect] = useState(false);
  const [oneData, setOneData] = useState('');
  const [page, setPage] = useState(1);
  const [placeName, setPlaceName] = useRecoilState(clusterPlaceState);
  const [, setErrCode] = useRecoilState(accessTokenCodeState);

  const PlaceNameSet = Array.isArray(stateData)
    ? [...new Set(stateData?.map((item) => item.placeName))]
    : stateData?.placeName;
  const getList = async () => {
    try {
      const result = await clusterListGET(placeName, page);
      setListData(result.data);
    } catch (error) {
      if (error.response.status === 401) {
        setErrCode(error.response.status);
      } else {
        console.error(error);
        setEmptyMsg(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    const handleScrollPageView = async () => {
      try {
        const nextPage = page + 1;
        setPage(nextPage);
        const result = await clusterListGET(placeName, nextPage);
        setListData((prevData) => [...prevData, ...result.data.responses]);
      } catch (err) {
        console.error(err);
      }
    };
    if (inView) {
      handleScrollPageView();
    }
  }, [inView, state?.listItem, placeName]);
  useEffect(() => {
    if (Array.isArray(PlaceNameSet) && PlaceNameSet.length > 0) {
      setPlaceName(PlaceNameSet[placeIndex]);
    } else {
      setPlaceName(Array.isArray(state?.placeName) === true ? state?.placeName[placeIndex] : state?.placeName);
    }
  }, []);
  useEffect(() => {
    setStateData(state?.listItem);
    if (Array.isArray(PlaceNameSet) && PlaceNameSet.length > 0) {
      setPlaceName(PlaceNameSet[placeIndex]);
    }
  }, [state?.listItem]);

  useEffect(() => {
    getList();
  }, [state?.listItem, placeName]);

  const filterResult = Array.isArray(stateData)
    ? listData.filter((listItem) => {
        return stateData.some((apiItem) => apiItem.placeName === listItem.placeName);
      })
    : listData.filter((items) => items.placeName === stateData.placeName);

  useEffect(() => {
    if (!filterResult) {
      setOneData({
        id: filterResult.id,
        info: filterResult,
        title: filterResult.productName,
        price: filterResult.price,
        start: filterResult.startDay,
        createdAt: filterResult.createdAt,
        end: filterResult.endDay,
        like: filterResult.likeCount,
        request: filterResult.buyRequestCount,
        location: filterResult.areaName3depth,
        placeName: filterResult.placeName,
        writer: filterResult.memberNickname,
        thumb:
          filterResult.storedProductImagePaths?.length === 0
            ? 'default'
            : `${process.env.REACT_APP_IMAGE_URL}${filterResult.storedProductImagePaths[0]}`,
      });
    }
  }, []);
  // PLACE NAME CHECK
  const placeNameArr = filterResult.map((name) => name.placeName);
  const checkArr = [];
  placeNameArr.forEach((el) => {
    if (!checkArr.includes(el)) {
      checkArr.push(el);
    }
  });
  const objectArr = Array.isArray(PlaceNameSet);
  const areAllValuesSame = (arr) => arr.every((val) => val === arr[0]);
  const handleOpenSelect = () => {
    if (objectArr && PlaceNameSet.length > 0 && areAllValuesSame(PlaceNameSet) === false) {
      setPlaceSelect(true);
    } else {
      setPlaceSelect(false);
    }
  };
  const handleCloseSelect = () => {
    setPlaceSelect(false);
  };

  return (
    <div className="cluster_list">
      <ScrollRestoration />
      <div className={`shadow_bg fixed ${placeSelect}`} onClick={() => setPlaceSelect(false)}></div>
      <Header placeName={placeName} placeNames={PlaceNameSet} open={handleOpenSelect} />
      {objectArr && PlaceNameSet.length > 0 && areAllValuesSame(PlaceNameSet) === false ? (
        <ClusterSelect placeSelect={placeSelect} listItem={PlaceNameSet} close={handleCloseSelect} />
      ) : null}
      <div className="list_frame">
        <ul>
          {Array.isArray(filterResult) ? (
            filterResult?.map((item, index) => {
              const Info = {
                id: item.id,
                info: item,
                title: item.productName,
                price: item.price,
                start: item.startDay,
                createdAt: item.createdAt,
                end: item.endDay,
                like: item.likeCount,
                request: item.buyRequestCount,
                location: item.areaName3depth,
                placeName: item.placeName,
                writer: item.memberNickname,
                thumb:
                  item.storedProductImagePaths.length === 0
                    ? 'default'
                    : `${process.env.REACT_APP_IMAGE_URL}${item.storedProductImagePaths[0]}`,
              };
              return (
                <>
                  <ClusterItem key={index} info={Info} />
                  {filterResult.length > 19 ? <li ref={ref}></li> : ''}
                </>
              );
            })
          ) : (
            <ClusterItem info={oneData} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default ClustererList;
