// MODULE
import { useEffect, useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
// HOOK
import { getRemainingPeriod } from '../../utils/timeEffect';
// COMPONENT
import ArticleListItem from '../../components/ArticleList/ArticleListItem';
import Skeleton from '../../components/Skeleton';

const ArticleList = ({ data, setPage, page, value, maxPage, type, empty_txt, endPage, currentLocation }) => {
  const [ref, inView] = useInView();
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    if (maxPage && type !== undefined) {
      const ShareListCount = maxPage.filter((types) => types.type === type)[0]?.count;
      const getMaxPage = Math.ceil(ShareListCount / 20);
      setLastPage(getMaxPage);
    }
  }, [type]);
  useEffect(() => {
    if (inView) {
      if (!endPage) {
        setPage(page + 1);
      } else {
        return false;
      }
    }
  }, [inView, currentLocation]);
  return (
    <div className="list_frame">
      {value && (
        <div style={{ display: 'flex', justifyContent: 'center', fontSize: 12, color: '#666666', marginTop: 50 }}>
          찜한 상품은 종료일로부터 90일까지만 확인할 수 있어요!
        </div>
      )}
      <ScrollRestoration />
      <ul>
        {data === null ? (
          <Skeleton type={'one_type'} loop={5} />
        ) : (
          data?.map((item, index) => {
            const Info = {
              id: item.id,
              info: item,
              title: item.productName,
              price: item.price,
              start: item.startDay,
              create: item.createdAt,
              end: getRemainingPeriod(item.endDay),
              like: item.likeCount,
              request: item.buyRequestCount,
              location: item.regionName3depth,
              placeName: item.placeName,
              writer: item.memberNickname,
              thumb:
                item.storedProductImagePaths.length === 0
                  ? 'default'
                  : `${process.env.REACT_APP_IMAGE_URL}${item.storedProductImagePaths[0]}`,
            };
            return (
              <li key={index + 'id'}>
                <ArticleListItem info={Info} index={index} />
              </li>
            );
          })
        )}
        {data?.length > 19 && !endPage && <li ref={ref}></li>}
      </ul>
    </div>
  );
};

export default ArticleList;
