// MODULE
import { useState } from 'react';
// COMPONENT
import WriterItems from '../../components/WriterItems';
import HistoryBack from '../../components/Common/HistoryBack';

const TabMenu = [
  {
    id: 1,
    name: '전체',
  },
  {
    id: 2,
    name: '게시중',
  },
  {
    id: 3,
    name: '기간만료',
  },
];

const UserInfo = () => {
  const [tabs, setTabs] = useState(1);
  const handleOnChangeTab = (index) => {
    setTabs(index);
  };
  return (
    <div className="user_info_section">
      <div className="page_header flex">
        <HistoryBack />
        <ul className="flex flex_jc_sb">
          {TabMenu.map((item, index) => {
            return (
              <li
                key={item.name}
                onClick={(index) => handleOnChangeTab(item.id)}
                className={`${tabs === item.id ? 'active' : ''} relative flex flex_jc_c flex_ai_c`}
              >
                <span>{item.name}(0)</span>
              </li>
            );
          })}
        </ul>
      </div>
      <WriterItems />
    </div>
  );
};

export default UserInfo;
