// MODULES
import React, { useState, useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
// HOOKS
import { writePOST } from '../../api/article';
// RECOIL STATUE
import writeState from '../../state/writeState';
import { accessTokenCodeState } from '../../state/userState';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import Location from '../../components/LocationSelect';
import Map from '../../components/MapSelect';
import ModalFrame from '../../components/Modal/ModalFrame';
import ProductName from '../../components/ArticleWrite/ProductName/ProductName';
import Price from '../../components/ArticleWrite/Price/Price';
import Category from '../../components/ArticleWrite/Category/Category';
import Calendar from '../../components/ArticleWrite/Calendar';
import Contents from '../../components/ArticleWrite/Contents/Contents';
import ArticleCheckBox from '../../components/ArticleWrite/ArticleCheckBox/ArticleCheckBox';
// IMPORT
import 'react-datepicker/dist/react-datepicker.css';
// JSON
import CategoryData from '../../json/kakaomapCategory.json';
// IMAGE

const ArticleWrite = () => {
  const navigate = useNavigate();
  const [coord] = useRecoilState(writeState.writeLocationInfoState);
  const [modalType, setModalType] = useRecoilState(writeState.locationSelectTypeState);
  const [uploadImage, setUploadImage] = useState([]);
  const [resizeImg, setResizeImg] = useState([]);
  const [inputErr, setInputErr] = useState('');
  const [, setErrCode] = useRecoilState(accessTokenCodeState);

  const imgRef = useRef();
  const postBtn = useRef();

  const getDate = useRecoilValue(writeState.datePickerState);

  const cleanLocationInfo = useResetRecoilState(writeState.writeLocationInfoState);

  useEffect(() => {
    cleanLocationInfo();
  }, []);
  // IMAGE RESIZE
  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log('file', file);
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const handleAddImages = async (event) => {
    console.log('event', event.target.files);
    const imageLists = event.target.files;
    const resizedImages = [];
    const imageUrlLists = [...resizeImg];

    for (let i = 0; i < imageLists.length; i++) {
      const file = imageLists[i];
      const image = await resizeFile(file);

      resizedImages.push(image);
    }

    let newImageList = [...uploadImage, ...resizedImages.slice(0, 5 - uploadImage.length)];
    if (resizedImages.length > 5) {
      alert('이미지는 최대 5장 까지만 등록이 가능합니다.');
      setResizeImg(newImageList.slice(0, 5));
      setUploadImage(newImageList);
    } else {
      setResizeImg(newImageList);
      setUploadImage(newImageList);
    }
  };

  // DELETE IMAGE
  const handleDeleteImage = (id) => {
    setUploadImage(uploadImage.filter((_, index) => index !== id));
    setResizeImg(resizeImg.filter((_, index) => index !== id));
  };
  // SUCCESS

  // LOCATION SELECT POPUP
  const [locationSelect, setLocationSelect] = useState(false);
  const handleOpenLocationSelectBox = () => {
    setLocationSelect(true);
  };
  const handleCloseLocationSelectBox = () => {
    setLocationSelect(false);
  };
  // SELECT MAP CLOSE
  const handleSelectMapPosition = () => {
    setModalType(false);
  };
  // TITLE VALUE
  const [title, setTitle] = useState('');
  const onChangeTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };
  // CATEGORY
  const [category, setCategory] = useState('');
  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };

  // PRICE
  const [price, setPrice] = useState('');
  const onChangePrice = (e) => {
    e.preventDefault();
    setPrice(e.target.value.replace(/[^0-9]/g, ''));
  };
  // START DAY
  const [startDt, setStartDt] = useState(new Date());
  const postStartDay = startDt.toISOString().slice(0, 10);
  // END DAY
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const defaultEndDate = addDays(new Date(), 7);
  const [endDt, setEndDt] = useState(defaultEndDate);
  const postEndDay = endDt.toISOString().slice(0, 10);
  // ISNATIONWIDE
  const [nationWide, setNationWide] = useState(false);
  const onChangeNationWide = () => {
    setNationWide((nationWide) => !nationWide);
  };

  // ISPURCHASABLE
  const [purchasable, setPurchasable] = useState(false);
  const onChangePurchasable = () => {
    setPurchasable((purchasable) => !purchasable);
  };
  // CONTENT
  const [content, setContent] = useState('');
  const onChangeContent = (e) => {
    e.preventDefault();
    // setContent(e.target.value.replace(/<br\s*\/?>/gm, '\n'));
    const sanitizedValue = e.target.value.replace(/<br\s*\/?>/gm, '\n');
    setContent(sanitizedValue);
  };
  const isNullCheck =
    title.length === 0 ||
    price.length === 0 ||
    content.length < 10 ||
    coord.place_name === null ||
    coord.place_name === undefined ||
    category === '';
  const handleArticlePost = () => {
    refreshTokenGET()
      .then((response) => {
        poseArticle();
      })
      .catch((error) => {
        console.log(error);
      });
    const poseArticle = async () => {
      if (title.length === 0) {
        document.getElementById('title').focus();
        setInputErr(1);
      } else if (price.length === 0 || price < 1) {
        document.getElementById('price').focus();
        setInputErr(2);
      } else if (coord.place_name === null || coord.place_name === undefined) {
        setInputErr(3);
      } else if (content.length < 10) {
        setInputErr(4);
      } else if (category === '') {
        setInputErr(5);
      } else {
        const formData = new FormData();
        for (let i = 0; i < resizeImg.length; i++) {
          formData.append('productImages', resizeImg[i]);
        }

        const json = JSON.stringify({
          productName: title,
          startDay: getDate.startDate,
          endDay: getDate.endDate,
          price: price,
          content: content,
          isNationwide: nationWide,
          isPurchasable: purchasable,
          category: category,
          placeName: coord.place_name,
          latitude: coord.lat,
          longitude: coord.lon,
          regionName3depth: coord.addr_depth_3,
          regionCode: coord.code,
        });
        const blob = new Blob([json], { type: 'application/json' });
        const blobMember = new Blob(['10000001'], { type: 'application/json' });
        formData.append('postData', blob);
        formData.append('memberId', blobMember);

        try {
          const result = await writePOST(formData);
          postBtn.current.disabled = true;
          navigate(`/detail?info=${result.data.id}`, { state: { isWrite: true } });
        } catch (error) {
          if (error.response.status === 401) {
            setErrCode(error.response.status);
          }
          console.error('writePOST error:', error);
          postBtn.current.disabled = true;
        }
      }
    };
  };
  // CHECK USER LOCATION SETTING

  const [alertModal, setAlertModal] = useState(false);
  const regionOption = JSON.parse(localStorage.getItem('region'));
  const regionOptionCheck = regionOption[0]?.code === '' && regionOption[1]?.code === '';

  useEffect(() => {
    regionOptionCheck && setAlertModal(regionOptionCheck);
  }, [postBtn.current]);

  return (
    <>
      {alertModal ? (
        <ModalFrame
          type={'type_1'}
          confirm={() => navigate(-1)}
          custom_txt={'내 지역을 설정하셔야 글을 작성할 수 있어요.'}
        />
      ) : (
        ''
      )}
      <div className="write_section">
        {locationSelect ? <div className="modal_bg"></div> : null}
        <div className="write_header flex">
          <div className="history_back relative" onClick={() => navigate('/home')}>
            <div></div>
            <div></div>
          </div>
          <div className="page_title">아이템 등록</div>
        </div>
        <div className="form_box" encType="multipart/form-data">
          <div className="picture_section flex flex_ai_fe relative">
            <span className="event_txt absolute_top">{inputErr === 4 ? '*상품사진을 추가해주세요.' : ''}</span>

            <div className="picture_box input_box relative">
              <label htmlFor="image_file" className="absolute flex flex_jc_c flex_ai_c">
                +<span className="img_counter absolute">{uploadImage.length}/5</span>
              </label>
              <input
                type="file"
                accept="image/*"
                ref={imgRef}
                onChange={handleAddImages}
                // onChange={onChange}
                id="image_file"
                disabled={uploadImage.length === 5 ? true : false}
                multiple="multiple"
              />
            </div>

            <div className="thumb_img flex flex_ai_fe">
              {uploadImage.map((image, id) => {
                const Src = image instanceof File ? URL.createObjectURL(image) : image;
                return (
                  <span key={id} className="relative">
                    <div className="img_box relative flex flex_ai_c">
                      <img src={Src} alt={`${image}-${id}`} width={70} />
                    </div>
                    <div className="del_btn flex flex_jc_c flex_ai_c" onClick={() => handleDeleteImage(id)}>
                      +
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
          <ProductName title={title} change={onChangeTitle} error={inputErr} />

          <Category category={category} change={onChangeCategory} error={inputErr} data={CategoryData.CATEGORY_LIST} />

          <Price price={price} change={onChangePrice} error={inputErr} />

          <Calendar startDt={startDt} endDt={endDt} />

          <ArticleCheckBox
            purchasable={purchasable}
            nationWide={nationWide}
            changePurchasable={onChangePurchasable}
            changeNationWide={onChangeNationWide}
          />

          <Contents content={content} change={onChangeContent} error={inputErr} />

          <div className="location_select_btn relative">
            <div className="section_title">장소 선택하기</div>
            <div onClick={handleOpenLocationSelectBox} className="custom_input">
              <div>
                {coord.place_name === null || coord.place_name === undefined ? (
                  <span>위치 추가</span>
                ) : (
                  coord.place_name
                )}
              </div>
              <span className="event_txt absolute">{inputErr === 3 ? '*장소를 선택해주세요.' : ''}</span>
            </div>
            {locationSelect ? <Location close={handleCloseLocationSelectBox} /> : null}
            {modalType ? <Map close={handleSelectMapPosition} /> : null}
          </div>
          <div className="btn_box flex flex_jc_c">
            <button
              className={`button ${isNullCheck ? 'disable' : ''}`}
              onClick={() => handleArticlePost()}
              ref={postBtn}
            >
              작성완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleWrite;
