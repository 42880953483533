// MODULE
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// HOOK
import { dreegoKakaoSyncTokenGET } from '../../api/externalAPI';

const KakaoCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const dreegoLogin = async () => {
    try {
      const result = await dreegoKakaoSyncTokenGET(code);
      console.log(result);
      if (result.status === 200) {
        navigate('/mypage/point-conversion', {
          state: { userInfo: result.data, pathName: location.pathname },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCode(location.search.replace('?code=', ''));
    dreegoLogin();
  }, [code]);

  return (
    <>
      <div>로그인중</div>
    </>
  );
};

export default KakaoCallback;
