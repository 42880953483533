// MODULE
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// HOOK
import { tradeReviewPOST } from '../../api/review';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import ModalFrame from '../../components/Modal';

const TradeReview = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [score, setScore] = useState(null);
  const [errModal, setErrModal] = useState(0);
  const [reviewComplete, setReviewComplete] = useState(false);

  const onChangeScore = (e) => {
    setScore(e.target.value);
  };

  const handleReviewPost = () => {
    refreshTokenGET()
      .then((response) => {
        tradeReview();
      })
      .catch((error) => {
        console.log(error);
      });
    const tradeReview = async () => {
      if (score === null) {
        setErrModal(1);
      } else {
        try {
          const result = await tradeReviewPOST(Number(score), state.requestId);
          if (result.status === 201) {
            setReviewComplete(true);
          }
        } catch (error) {
          if (error.data.message === '리뷰를 남긴 이력이 존재합니다.') {
            setErrModal(2);
          } else {
            console.log(error);
          }
        }
      }
    };
  };
  const handleReviewEndPoint = () => {
    navigate(-1);
    setReviewComplete(false);
  };
  return (
    <>
      {reviewComplete && (
        <ModalFrame custom_txt={'후기 남기기 성공'} confirm={() => handleReviewEndPoint()} type={'type_4'} />
      )}
      {errModal === 1 ? (
        <ModalFrame
          custom_txt={`${state.partnerNickname} 님과의 거래 후기 점수를 남겨주세요!`}
          confirm={() => setErrModal(0)}
          type={'type_4'}
        />
      ) : (
        errModal === 2 && (
          <ModalFrame
            custom_txt={`이미 해당 상품에 대한 후기를 남기셨어요.`}
            confirm={() => setErrModal(0)}
            type={'type_4'}
          />
        )
      )}
      <div className="review_partner_nickname">
        <span>{state.partnerNickname}</span>님과의 거래는 어떠셨나요?
        <br />
        후기를 남겨보세요.
      </div>
      <div className="review_product_name">
        <span>{state.productName}</span> 상품을 거래했어요.
      </div>
      <div className="review_form_box">
        <div className="score_box flex flex_jc_sb flex_ai_c">
          <div className="flex flex_dir_c flex_jc_c flex_ai_c">
            <input type="radio" name="review_score" value={-1} id="minus" onChange={onChangeScore} />
            <label htmlFor="minus" className="relative">
              별로에요
            </label>
          </div>
          <div className="flex flex_dir_c flex_jc_c flex_ai_c">
            <input type="radio" name="review_score" value={0} id="zero" onChange={onChangeScore} />
            <label htmlFor="zero" className="relative">
              보통이에요
            </label>
          </div>
          <div className="flex flex_dir_c flex_jc_c flex_ai_c">
            <input type="radio" name="review_score" value={1} id="plus" onChange={onChangeScore} />
            <label htmlFor="plus" className="relative">
              최고에요
            </label>
          </div>
        </div>
      </div>
      <div className="btn_box">
        <div className="button" onClick={() => handleReviewPost()}>
          후기 남기기
        </div>
      </div>
    </>
  );
};

export default TradeReview;
