const OriginalImage = ({ imgSrc, close }) => {
  return (
    <div className="original_image_modal fixed">
      <div onClick={() => close()} className="close_btn fixed">
        <div></div>
        <div></div>
      </div>
      <div className="img_box">
        <img src={`${process.env.REACT_APP_IMAGE_URL}${imgSrc}`} alt="" />
      </div>
    </div>
  );
};

export default OriginalImage;
