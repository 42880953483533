// MODULE
import { useEffect, useState } from 'react';
// SVG
import { ReactComponent as SeedIcon } from '../../assets/img/icon/Seed.svg';
import { ReactComponent as Level1Icon } from '../../assets/img/icon/Level_1.svg';
import { ReactComponent as Level2Icon } from '../../assets/img/icon/Level_2.svg';
import { ReactComponent as Level3Icon } from '../../assets/img/icon/Level_3.svg';
import { ReactComponent as Level4Icon } from '../../assets/img/icon/Level_4.svg';

const MannerScore = ({ propsScore }) => {
  const [userScore, setUserScore] = useState(0);
  const [getScore, setGetScore] = useState(0);
  const maxScore = 500;
  const standard = 20;

  useEffect(() => {
    setGetScore(propsScore);
  }, []);
  useEffect(() => {
    if (getScore !== userScore) {
      setTimeout(() => {
        setUserScore(userScore + 1);
      }, 10);
    }
  }, [getScore, userScore]);
  return (
    <>
      <div className="manner_status">
        <div className="gauge_bar">
          <div className="gauge_charge" style={{ width: `calc(${String(userScore)}%)` }}></div>
        </div>
      </div>
      <ul className="manner_icon flex flex_jc_sb flex_ai_c">
        <li className={`${userScore >= standard ? '' : 'disable'} flex flex_jc_c`}>
          <SeedIcon />
        </li>
        <li className={`${userScore >= standard * 2 ? '' : 'disable'} flex flex_jc_c`}>
          <Level1Icon color={userScore >= standard * 2 ? '#2AAE40' : '#222222'} />
        </li>
        <li className={`${userScore >= standard * 3 ? '' : 'disable'} flex flex_jc_c`}>
          <Level2Icon color={userScore >= standard * 3 ? '#2AAE40' : '#222222'} />
        </li>
        <li className={`${userScore >= standard * 4 ? '' : 'disable'} flex flex_jc_c`}>
          <Level3Icon color={userScore >= standard * 4 ? '#2AAE40' : '#222222'} />
        </li>
        <li className={`${userScore >= standard * 5 ? '' : 'disable'} flex flex_jc_c`}>
          <Level4Icon color={userScore >= standard * 5 ? '#2AAE40' : '#222222'} />
        </li>
      </ul>
    </>
  );
};

export default MannerScore;
