// MODULE
import { useNavigate } from 'react-router-dom';
// HOOK

const NoticeListItem = ({ data }) => {
  const navigate = useNavigate();

  const handleToggleFaqItem = (id) => {
    navigate('/notice-detail', { state: { id: id } });
    // setIsOpen(index === isOpen ? -1 : index);
    // try {
    //   const result = await noticeDetailGET(id);
    //   console.log(result);
    //   setDetailData(result.data.content);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div className="question">
      <ul>
        {data !== null ? (
          data.length === 0 ? (
            <li className="empty_list">등록된 공지사항이 없습니다.</li>
          ) : (
            data.map((data, index) => (
              <li key={index} onClick={() => handleToggleFaqItem(data.id)} className="flex flex_dir_c">
                <div className="title">{data.title}</div>
                <div className="create_dt">{data.createdAt}</div>
              </li>
            ))
          )
        ) : (
          ''
        )}
      </ul>
    </div>
  );
};

export default NoticeListItem;
