// MODULE
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useInView } from 'react-intersection-observer';
// RECOIL STATE
import writeState from '../../state/writeState';
import { userGeolocationState } from '../../state/userState';
// LOCATION CATEGORY CODE
const Category = [
  { code: 'MT1', text: '대형마트' },
  { code: 'CS2', text: '편의점' },
  { code: 'PS3', text: '어린이집, 유치원' },
  { code: 'SC4', text: '학교' },
  { code: 'AC5', text: '학원' },
  { code: 'PK6', text: '주차장' },
  { code: 'OL7', text: '주유소,충전소' },
  { code: 'SW8', text: '지하철역' },
  { code: 'BK9', text: '은행' },
  { code: 'CT1', text: '문화시설' },
  { code: 'AG2', text: '중개업소' },
  { code: 'PO3', text: '공공기관' },
  { code: 'AT4', text: '관광명소' },
  { code: 'AD5', text: '숙박' },
  { code: 'FD6', text: '음식점' },
  { code: 'CE7', text: '카페' },
  { code: 'HP8', text: '병원' },
  { code: 'PM9', text: '약국' },
];
const Location = ({ close }) => {
  const [modalType, setModalType] = useRecoilState(writeState.locationSelectTypeState);
  // WRITE STATE
  const [getPosition, seGetPosition] = useRecoilState(writeState.writeLocationInfoState);
  // LATITUDE
  const [lat, setLat] = useState(null);
  // LONGITUDE
  const [lon, setLon] = useState(null);
  // CATEGORY
  const [cate, setCate] = useState('');
  // RESULT
  const [place, setPlace] = useState([]);
  // RESULT ADDR
  const [selectInfo, setSelectInfo] = useState([]);
  // PAGINATION
  const [pageNum, setPageNum] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [ref, inView] = useInView();
  // GEOLOCATION
  const [accessGeolocation, setAccessGeolocation] = useRecoilState(userGeolocationState);
  // SELECT OPTION
  const handleSelect = (e) => {
    setCate(e.target.value);
  };
  useEffect(() => {
    if (inView) {
      if (inView && pageNum < pagination.last) {
        setPageNum(pageNum + 1);
      }
    }
  }, [inView]);
  useEffect(() => {
    if (cate) {
      setPageNum(1);
      setPlace([]);
    }
  }, [cate]);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setAccessGeolocation(true);
        setLat(position.coords.latitude); // LATITUDE
        setLon(position.coords.longitude); // LONGITUDE
      });
    } else {
      let message = 'geolocation을 사용할수 없어요.';
      console.log(message);
    }
    if (accessGeolocation === true && cate !== '') {
      const places = new window.kakao.maps.services.Places();
      const callback = function (result, status, pagination) {
        if (status === window.kakao.maps.services.Status.OK) {
          setPagination(pagination);
          setPlace((prevPlace) => [...prevPlace, ...result]);
        } else if (status === window.kakao.maps.services.Status.ZERO_RESULT) {
          setPlace(result);
        }
      };

      places.categorySearch(cate, callback, {
        location: new window.kakao.maps.LatLng(lat, lon),
        radius: 2500,
        size: 15,
        page: pageNum,
      });
    }
  }, [cate, pageNum, accessGeolocation]);

  const handleLocationState = (index) => {
    if (place && place.length > 0) {
      const { x, y, place_name } = place[index];
      const geocoder = new window.kakao.maps.services.Geocoder();
      const callback = function (result, status) {
        if (status === window.kakao.maps.services.Status.OK) {
          console.log(result[0]);
          setSelectInfo(result[0]);

          seGetPosition({
            lat: y,
            lon: x,
            place_name: place_name,
            addr_depth_3: result[0].region_3depth_name,
            code: result[0].code,
          });
          close();
        }
      };
      geocoder.coord2RegionCode(x, y, callback);
    }
  };

  const handleSelectMapPosition = () => {
    close();
    setModalType(true);
  };
  return (
    <>
      <div className="location_select fixed">
        <div className="select_box flex flex_jc_sb">
          <select name="" id="" onChange={handleSelect} value={cate}>
            <option value="default">카테고리를 선택해주세요.</option>
            {Category.map((item, index) => (
              <option value={item.code} key={item.code}>
                {item.text}
              </option>
            ))}
          </select>
          <div className="close_btn relative" onClick={close}>
            <div></div>
            <div></div>
          </div>
        </div>

        <ul>
          {place !== null ? (
            place.length !== 0 ? (
              place.map((item, index) => (
                <li key={index} ref={index === place.length - 1 ? ref : null}>
                  <div>{item.place_name}</div>
                  <div className="select_btn" onClick={() => handleLocationState(index)}>
                    선택
                  </div>
                </li>
              ))
            ) : (
              <li className="empty_txt flex flex_dir_c">
                <div>해당 카테고리에 대한 장소가 없어요.</div>
                <div onClick={handleSelectMapPosition} className="btn cursor_p">
                  지도에서 위치 지정하기
                </div>
              </li>
            )
          ) : (
            <li className="default_txt flex flex_dir_c">카테고리를 선택해주세요.</li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Location;
