// MODULE
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
// IMAGE
import Default_Img from '../../assets/img/default_profile.png';

const ProfileImage = ({ src, alt, size }) => {
  const imgRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  useLayoutEffect(() => {
    const handleImageLoad = () => {
      const img = imgRef?.current;
      const width = img?.naturalWidth;
      const height = img?.naturalHeight;

      setImageWidth(width);
      setImageHeight(height);

      const ratio = width / height;

      if (ratio > 1) {
        const newWidth = size * ratio;
        const newHeight = size;
        img.style.width = `${newWidth}px`;
        img.style.height = `${newHeight}px`;
      } else {
        const newWidth = size;
        const newHeight = size / ratio;
        img.style.width = `${newWidth}px`;
        img.style.height = `${newHeight}px`;
      }
    };

    imgRef.current.addEventListener('load', handleImageLoad);
  }, [src, size]);

  return (
    <div className="flex flex_jc_c">
      <img
        ref={imgRef}
        src={src === 'default' ? Default_Img : src}
        alt={alt}
        loading="lazy"
        width={size !== null ? size : ''}
      />
    </div>
  );
};
export default ProfileImage;
