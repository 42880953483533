// MODULE
import { useNavigate } from 'react-router-dom';
// COMPONENT
import HistoryBack from '../Common/HistoryBack';

const CertComplete = () => {
  const navigate = useNavigate();
  const comp_txt = '본인인증이 완료되었어요!\n프로필을 설정하러 가볼까요?';
  return (
    <div className="comp_splash">
      <HistoryBack />
      <div className="logo img_box">
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`} alt="" />
      </div>
      <div className="sub_txt flex flex_jc_c">{comp_txt}</div>
      <div className="btn_box">
        <div className="button" onClick={() => navigate('/regist-nickname')}>
          프로필 설정하기
        </div>
      </div>
    </div>
  );
};

export default CertComplete;
