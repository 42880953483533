import axios from '../utils/instance';

const uri = '/members';
// REGISTRATION POST
export const memberPOST = (formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};
// MY PROFILE INFO GET
export const myProfileInfoGET = async (id, fieldValue) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${id}`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        params: {
          fieldNumbers: fieldValue,
        },
        withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// MY PROFILE PATCH
export const myProfilePATCH = (id, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// USER LOCATION SELECT
export const locationSelectPATCH = (id, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// NICKNAME CHECK GET
export const nicknameCheckGET = (nickname) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/verify-nickname`, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { nickname: nickname },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ACCOUNT CANCELLATION
export const accountDELETE = (withdrawalReason) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${uri}`, {
        headers: { 'Content-Type': 'application/json' },
        params: { withdrawalReason: withdrawalReason },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// POINT GET
export const pointInfoGET = (id, startDate, endDate, lastCreatedPointDate) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${id}/point-log`, {
        headers: { 'Content-Type': 'application/json' },
        params: { startDate: startDate, endDate: endDate, lastCreatedPointDate: lastCreatedPointDate },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// POINT CONVERSION
export const pointConversionPATCH = (memberNo, memberId, accumulationAmt) => {
  return new Promise((resolve, reject) => {
    const body = {
      memberNo: memberNo,
      memberId: memberId,
      accumulationAmt: accumulationAmt,
    };
    axios
      .patch(`${uri}/my-point/link`, body, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ARTICLE LIST GET
export const listGET = (regionCode, page, all) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/region/articles`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        params: {
          regionCode: regionCode,
          page: page,
          isViewingAllArticle: all,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// MY ARTICLE GET
export const myArticleGET = (id, order, page) => {
  return new Promise((resolve, reject) => {
    const orderType = order.toLowerCase();
    axios
      .get(`${uri}/${id}/write/articles`, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { order: orderType, page: page },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// LIKE ARTICLE GET
export const likeArticleGET = (page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/likes/articles`, { headers: { 'Content-Type': 'application/json' }, params: { page: page } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// BLOCKED USER
export const userBlockedPATCH = (id, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// BLOCKED USER INFO
export const blockedUserInfoGET = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/blocked-members`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// CHANGE LOGIN FORM
//// REGISTER
export const registerMemberPOST = (formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//// CHECK DUPLICATION
export const userDuplicationPOST = (value) => {
  const values = {
    loginId: value.id,
    nickname: value.nickname,
    email: value.email,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}/duplication-check`, values, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//// FIND LOGIN ID
export const findLoginIdPOST = (data) => {
  const email = {
    email: data,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}/login-id`, email, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//// FIND LOGIN PW
export const findLoginPWResetPOST = (id, email) => {
  const userData = {
    loginId: id,
    email: email,
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/password/reset`, userData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//// CHANGE PASSWORD
export const changeUserPassword = (data) => {
  const password = {
    password: data.password,
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(`${uri}/member`, password, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
