const Input = ({
  id,
  value,
  type,
  placeholder,
  name,
  onChange,
  onBlur,
  maxLength,
  label,
  style,
  event_type,
  event_msg,
}) => {
  return (
    <div className={`input_box ${style} ${event_msg !== '' && 'relative'}`}>
      {label !== undefined ? <label htmlFor={id}>{label}</label> : ''}
      <input
        type={type === undefined ? 'text' : type}
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={`input_normal`}
        maxLength={maxLength}
      />
      {event_msg !== '' && <div className={`event_txt ${event_type}`}>{event_msg}</div>}
    </div>
  );
};

export default Input;
