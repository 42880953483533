export function getElapsedTime(createdAt) {
  const createDate = new Date(createdAt);
  const currentDate = new Date();
  const timeDifference = Math.floor((currentDate - createDate) / (60 * 60 * 1000));

  if (timeDifference < 1) {
    const minutesDifference = Math.floor((currentDate - createDate) / (60 * 1000));
    if (minutesDifference < 1) {
      return '방금 전';
    } else {
      return `${minutesDifference}분 전`;
    }
  } else if (timeDifference < 24) {
    return `${timeDifference}시간 전`;
  } else {
    const daysDifference = Math.floor(timeDifference / 24);
    return `${daysDifference}일 전`;
  }
}

export function getRemainingPeriod(endDay) {
  const endDate = new Date(endDay);
  const currentDate = new Date();
  const timeDifference = endDate - currentDate;
  const endTxt = '오늘 마감';
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  if (daysRemaining === 1 || daysRemaining === 0) {
    return endTxt;
  } else if (daysRemaining < 0) {
    return endDay + '에 종료됨';
    // return 300;
  } else {
    return daysRemaining + '일 남음';
  }
}

export function formatTimeToAmPm(timeString) {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? '오후' : '오전';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${ampm} ${formattedHours}:${formattedMinutes}`;
}

export function getTimeStamp(timeString) {
  const createDate = new Date(timeString);
  const currentDate = new Date();
  const timeDifference = Math.floor((currentDate - createDate) / (60 * 60 * 1000));

  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? '오후' : '오전';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  if (timeDifference < 1) {
    const minutesDifference = Math.floor((currentDate - createDate) / (60 * 1000));
    if (minutesDifference > 24) {
      const daysDifference = Math.floor(timeDifference / 24);
      if (daysDifference === 0) {
        return `${ampm} ${formattedHours}:${formattedMinutes}`;
      } else {
        return `${daysDifference}일 전`;
      }
    }
  } else {
    return `${ampm} ${formattedHours}:${formattedMinutes}`;
  }
}

export function messageTimeStamp(timeString) {
  // const createDate = new Date(timeString);
  // const currentDate = new Date();
  // const timeDifference = Math.floor((currentDate - createDate) / (60 * 60 * 1000));

  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? '오후' : '오전';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${ampm} ${formattedHours}:${formattedMinutes}`;
}
