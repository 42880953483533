// MODULE
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// HOOK
import { findLoginPWResetPOST } from '../../api/member';
// COMPONENT
import Input from '../Common/Input';
import ModalFrame from '../Modal';

const FindPw = ({ Frame }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [result, setResult] = useState(null);

  const emailLengthCheck = email.length > 6 ? true : false;
  const idLengthCheck = id.length > 4 ? true : false;
  const onChangeId = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      const response = await findLoginPWResetPOST(id, email);
      console.log(response);
      setResult(response);
    } catch (error) {
      if (error.response.data.message === '회원이 존재하지 않습니다.') {
        alert(error.response.data.message);
      } else {
        console.log('error\n', error);
      }
    }
  };
  return (
    <>
      {result?.status === 200 ? (
        <ModalFrame
          custom_txt={`${email} 메일로 임시비밀번호가 발급되었어요.`}
          type={'type_3'}
          confirm={() => navigate(-1)}
        />
      ) : (
        ''
      )}
      <Frame>
        <Input
          value={id}
          id={'id'}
          name={'id'}
          placeholder={'아이디'}
          label={'아이디'}
          onChange={(e) => onChangeId(e)}
        />
        <Input
          value={email}
          id={'email'}
          name={'email'}
          placeholder={'이메일'}
          label={'이메일'}
          onChange={(e) => onChangeEmail(e)}
        />
        <div className="btn_box">
          <div
            className={`button ${(!emailLengthCheck && 'disable') || (!idLengthCheck && 'disable')}`}
            onClick={() => emailLengthCheck && idLengthCheck && handleResetPassword()}
          >
            비밀번호 재발급
          </div>
        </div>
      </Frame>
    </>
  );
};

export default FindPw;
