// MODULE
import { useState, useEffect } from 'react';
import { ScrollRestoration, useOutletContext } from 'react-router-dom';
// HOOK
import { listGET } from '../../api/member';
// COMPONENT
import ArticleList from '../ArticleList/ArticleList';
import MainHeader from '../../components/Header/MainHeader';
import Skeleton from '../../components/Skeleton';

const Main = () => {
  const { loginCheck } = useOutletContext();
  const code = localStorage.getItem('userAreaCode');
  const regionOption = JSON.parse(localStorage.getItem('region'));
  const regionOptionCheck = loginCheck
    ? regionOption === undefined
      ? locationData()
      : regionOption.filter((option) => option.code !== '')
    : 'all';
  const [articleList, setArticleList] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedRegion, setSelectedRegion] = useState(regionOptionCheck?.length > 0 ? regionOptionCheck[0] : '');
  const [lastPage, setLastPage] = useState(false);
  const [all, setAll] = useState(undefined);

  const locationData = () => {
    const region = [
      {
        regionName: '',
        code: '',
      },
      {
        regionName: '',
        code: '',
      },
    ];
    localStorage.setItem('region', JSON.stringify(region));
  };
  const currentLocation = document.querySelector('.current_location > span')?.innerHTML;

  const getList = async (data) => {
    const response = await listGET(data?.code, page, all /* loginCheck ? all : true */);
    const result = response.data.responses;
    if (response.data.responses.length !== 0) {
      if (page > 1) {
        setArticleList([...articleList, ...result]);
      } else {
        setArticleList(result);
      }
    } else if (response.data.responses.length === 0) {
      setArticleList([]);
    } else {
      setLastPage(true);
      return true;
    }
  };
  useEffect(() => {
    setLastPage(false);
  }, [currentLocation]);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const geocoder = new window.kakao.maps.services.Geocoder();
        const coord = new window.kakao.maps.LatLng(position.coords.latitude, position.coords.longitude);
        const callback = function (result, status) {
          if (status === window.kakao.maps.services.Status.OK) {
            const data = { regionName: result[0].region_3depth_name, code: result[0].code };
            if (regionOptionCheck?.length === 0 || !loginCheck) {
              setSelectedRegion(data);
            }
            localStorage.setItem('userAreaCode', result[0].code);
          }
        };
        geocoder.coord2RegionCode(coord.getLng(), coord.getLat(), callback);
      });
    } else {
      console.log('내 위치를 사용할 수 없어요.');
    }
  }, []);

  useEffect(() => {
    selectedRegion && getList(selectedRegion, all);
  }, [page, selectedRegion, all]);
  return (
    <>
      <ScrollRestoration />
      <MainHeader
        regionOption={regionOptionCheck.length > 0 ? regionOptionCheck : code}
        regionOptionCheck={regionOptionCheck}
        setData={setSelectedRegion}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        all={all}
        allLocation={setAll}
        setPage={setPage}
        loginCheck={loginCheck}
      />
      {articleList !== null ? (
        articleList && articleList?.length > 0 ? (
          <ArticleList
            data={articleList}
            setPage={setPage}
            page={page}
            endPage={lastPage}
            currentLocation={currentLocation}
          />
        ) : (
          <div className="main_empty_list">등록된 글이 없습니다.</div>
        )
      ) : (
        <Skeleton type={'one_type'} loop={6} />
      )}
    </>
  );
};

export default Main;
