// MODULE
import { useState } from 'react';
import { useNavigate, useLocation, Outlet, useOutletContext } from 'react-router-dom';
// RECOIL STATE
// UTIL
import { rememberPrevPage } from '../../utils/common';
// HOOKS
import { getMyPageName } from '../../utils/locationCheck';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack/HistoryBack';
import ModalFrame from '../../components/Modal/ModalFrame';
// IMAGE
import { ReactComponent as SettingIcon } from '../../assets/img/icon/Setting_icon.svg';
import UserRouter from '../../router/UserRouter';

const MyPage = () => {
  const [modal, setModal] = useState(false);
  // Add the byapps functions
  const byapps_is_api_enable = function () {
    return !!navigator.userAgent.match('Byapps');
  };

  const byapps_api_call = function (a, s) {
    if (byapps_is_api_enable()) {
      if (window.byappsJSI && window.byappsJSI.callAPI) {
        window.byappsJSI.callAPI(a, s);
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.byappsJSI) {
        let e = a;
        s && (e += '?' + s);
        window.webkit.messageHandlers.byappsJSI.postMessage(e);
      }
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { loginCheck } = useOutletContext();
  const Type = getMyPageName(location.pathname);
  const menuTitle =
    Type === 0
      ? '마이페이지'
      : Type === 1
      ? '공유 내역'
      : Type === 2
      ? '내 지역 설정'
      : Type === 3
      ? '프로필 설정'
      : Type === 4
      ? '닉네임 변경'
      : Type === 5
      ? '내 포인트'
      : Type === 6
      ? '포인트 전환하기'
      : Type === 7
      ? '내가 찜한 상품들'
      : Type === 8
      ? '포인트 상세조회'
      : Type === 9
      ? '비밀번호 변경'
      : null;
  const handleSettingMenu = () => {
    if (!loginCheck && byapps_is_api_enable() === true) {
      navigate(`${byapps_api_call('showSettings')}`);
    } else if (loginCheck) {
      navigate('/setting', { state: { loginCheck: loginCheck } });
    } else {
      setModal(true);
    }
  };
  return (
    <>
      {modal && (
        <ModalFrame
          confirm={() => (rememberPrevPage(window.location.href), navigate('/login'))}
          custom_txt={'로그인이 필요한 서비스 입니다. \n로그인 하러 가시겠어요?'}
          buttons={() => setModal(false)}
          type={'type_3'}
        />
      )}
      <div className="my_page ">
        <div className={`mypage_header flex flex_jc_sb ${Type === 1 || Type === 2 || Type === 5 ? 'border' : ''}`}>
          {Type === 0 ? (
            <div></div>
          ) : (
            <HistoryBack url={Type === 5 || Type === 1 ? '/mypage/main' : Type === 6 ? '/mypage/point' : undefined} />
          )}
          <div className={`menu_title ${Type === 0 ? 'center' : ''}`}>{menuTitle}</div>
          {Type === 0 ? (
            <button className="app_setting" onClick={() => handleSettingMenu()}>
              <SettingIcon />
            </button>
          ) : (
            <div></div>
          )}
        </div>
        {loginCheck ? (
          <UserRouter>
            <Outlet context={{ loginCheck }} />
          </UserRouter>
        ) : (
          <Outlet />
        )}
      </div>
    </>
  );
};

export default MyPage;
