// MODULE
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// HOOK
import { myProfilePATCH, userDuplicationPOST } from '../../api/member';
// COMPONENT
import ModalFrame from '../../components/Modal/ModalFrame';
// JSON
import responseCheck from '../../json/responseText.json';

const NicknameSetting = () => {
  const navigate = useNavigate();
  const [changeNickname, setChangeNickname] = useState('');
  const [nicknameCheck, setNicknameCheck] = useState(false);
  const [checkMsg, setCheckMsg] = useState(null);
  const [success, setSuccess] = useState(false);
  const [inputErr, setInputErr] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userProfiles'));

  useEffect(() => {
    setChangeNickname(userInfo?.nickname);
  }, []);

  const onChangeNickname = (e) => {
    e.preventDefault();
    setChangeNickname(e.target.value);
  };
  // NICKNAME CHECK
  const handleNicknameCheck = async () => {
    const textCheck = /^[a-zA-Z0-9\u3131-\uD79D]+$/;
    if (textCheck.test(changeNickname)) {
      const nickname = { nickname: changeNickname };
      try {
        setInputErr('');
        const response = await userDuplicationPOST(nickname);
        setCheckMsg(response.data.message);
        setNicknameCheck(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setInputErr('닉네임에 특수문자는 사용이 불가능해요.');
      setNicknameCheck(false);
    }
  };
  useEffect(() => {
    if (checkMsg === responseCheck.nicknameCheck[0].message) {
      setNicknameCheck(true);
    } else {
      setNicknameCheck(false);
    }
  }, [checkMsg]);

  // SAVE CHANGE PROFILE
  const handleSaveNickname = async () => {
    if (!nicknameCheck) {
      document.getElementById('nickname').focus();
    } else {
      const formData = new FormData();
      const json = JSON.stringify({
        nickname: changeNickname,
      });
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('patchData', blob);
      const id = localStorage.getItem('memberId');
      try {
        const result = await myProfilePATCH(id, formData);
        if (result.status === 200) {
          setSuccess(true);
          localStorage.setItem(
            'userProfiles',
            JSON.stringify({
              img: userInfo.img,
              nickname: changeNickname,
            })
          );
        } else {
          console.log('에러 :', result.status);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleOnClickPathNickname = () => {
    navigate('/mypage/main');
  };

  const nicknameLength = changeNickname.length;
  const point_txt = '다른 사람들에게 보여줄 닉네임을 설정해주세요.\n닉네임은 언제든지 바꿀 수 있습니다.';
  return (
    <>
      {success && (
        <ModalFrame
          confirm={() => handleOnClickPathNickname()}
          custom_txt={'닉네임이 변경되었습니다.'}
          height={'25%'}
        />
      )}
      <div className="nickname_setting_section">
        <div className="close_btn"></div>
        <div className="point_txt">{point_txt}</div>

        <div className="txt_box">
          <div className="sub_tit">닉네임</div>
        </div>
        <div className="nickname_box input_box relative flex flex_dir_c">
          <label htmlFor="nickname" className="absolute"></label>
          <input
            type="text"
            id="nickname"
            className={changeNickname === userInfo.nickname ? 'prevNickname' : ''}
            value={changeNickname}
            onChange={onChangeNickname}
            maxLength={10}
            placeholder="최대 10글자까지 입력"
          />
          <div className="btn_box input_button absolute">
            <button
              className={`button ${nicknameLength <= 0 ? 'disable' : ''}`}
              onClick={nicknameLength <= 0 ? null : handleNicknameCheck}
            >
              중복체크
            </button>
          </div>
        </div>
        <div className="info_box flex flex_jc_sb">
          <ul className="nickname_info">
            <li>한글, 영어, 숫자만 사용할 수 있어요.</li>
            <li>최대 10자까지 입력 가능해요.</li>
          </ul>
          {inputErr === '' ? (
            <span
              className={`event_txt flex flex_jc_e ${
                checkMsg === responseCheck.nicknameCheck[0].message ? 'true' : 'false'
              }`}
            >
              {checkMsg}
            </span>
          ) : (
            <span className="event_txt flex flex_jc_e false">{inputErr}</span>
          )}
        </div>

        <div className="btn_box">
          <button
            className={`button ${nicknameLength <= 0 && 'disable'}`}
            onClick={nicknameLength <= 0 ? null : handleSaveNickname}
          >
            수정완료
          </button>
        </div>
      </div>
    </>
  );
};

export default NicknameSetting;
