export async function saveLoginData(id, data) {
  const region = [
    {
      regionName: data.firstRegionOfResidence,
      code: data.firstRegionOfResidenceCode,
    },
    {
      regionName: data.secondRegionOfResidence,
      code: data.secondRegionOfResidenceCode,
    },
  ];
  localStorage.setItem('region', JSON.stringify(region));
  localStorage.setItem('memberId', id);
  const userData = {
    img: data?.storedProfileImagePath === undefined ? null : data?.storedProfileImagePath,
    nickname: data?.nickname,
    phone: data?.phoneNumber,
  };
  localStorage.setItem(
    'userProfileImg',
    data?.storedProfileImagePath === undefined ? null : data?.storedProfileImagePath
  );
  localStorage.setItem('userProfiles', JSON.stringify(userData));
}

export async function saveLocation(data) {
  const region = [
    {
      regionName: data.firstRegionOfResidence,
      code: data.firstRegionOfResidenceCode,
    },
    {
      regionName: data.secondRegionOfResidence,
      code: data.secondRegionOfResidenceCode,
    },
  ];
  localStorage.setItem('region', JSON.stringify(region));
}
