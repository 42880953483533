// MODULE
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useInView } from 'react-intersection-observer';
// RECOIL STATE
import { accessTokenCodeState } from '../../state/userState';
import { searchResultPageState } from '../../state/commonState';
// HOOK
import { articleSearchGET } from '../../api/article';
// COMPONENT
import Skeleton from '../Skeleton';
import ResultView from './ResultView';
import Keyword from './Keyword';

const MAX_KEYWORDS = 10;

const SearchLoading = () => {
  return (
    <ul className="flex flex_jc_sb flex_wrap_wrap mar_top_30">
      <Skeleton type={'double_type'} loop={7} />
    </ul>
  );
};

const Search = ({ close }) => {
  const [ref, inView] = useInView();
  const [keyword, setKeyword] = useState('');
  const [keywordArchive, setKeywordArchive] = useState([]);
  const [isResultView, setIsResultView] = useState(0);
  const [page, setPage] = useRecoilState(searchResultPageState);
  const [searchResult, setSearchResult] = useState([]);
  const [resultKeyword, setResultKeyword] = useState('');
  const [errCode, setErrCode] = useRecoilState(accessTokenCodeState);
  useEffect(() => {
    localStorage.getItem('keywordArchive') === null || localStorage.getItem('keywordArchive').length === 0
      ? localStorage.setItem('keywordArchive', JSON.stringify([]))
      : setKeywordArchive(JSON.parse(localStorage.getItem('keywordArchive')));
  }, []);

  // TEXT FUNCTION
  const onChangeKeyword = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };
  const handleClearInput = (e) => {
    setKeyword('');
  };
  // LOCALSTORAGE SAVE KEYWORD
  const saveKeywordToLocalStorage = (keyword) => {
    let keywordArchive = JSON.parse(localStorage.getItem('keywordArchive')) || [];
    let getDate = new Date();
    const month = getDate.getMonth() + 1;
    const date = getDate.getDate();
    const keywordEntry = {
      keyword: keyword,
      timestamp: month + '.' + date,
    };
    keywordArchive.unshift(keywordEntry);
    if (keywordArchive.length > MAX_KEYWORDS) {
      keywordArchive.pop();
    }
    localStorage.setItem('keywordArchive', JSON.stringify(keywordArchive));
    setKeywordArchive(keywordArchive);
  };

  // DELETE LOCALSTORAGE INDEX
  const handleDeleteKeyword = (index) => {
    const getStorageData = JSON.parse(localStorage.getItem('keywordArchive'));
    getStorageData.splice(index, 1);
    localStorage.setItem('keywordArchive', JSON.stringify(getStorageData));
    setKeywordArchive(JSON.parse(localStorage.getItem('keywordArchive')));
  };
  // CLEAR LOCALSTORAGE
  const handleClearLocalStorage = () => {
    localStorage.setItem('keywordArchive', JSON.stringify([]));
    setKeywordArchive(JSON.parse(localStorage.getItem('keywordArchive')));
  };
  // SEARCH ARTICLE
  const handleSearchArticle = async () => {
    if (keyword.length < 1) {
      alert('검색어를 입력해주세요.');
    } else {
      saveKeywordToLocalStorage(keyword);
      setResultKeyword(keyword);
      const regionCode = localStorage.getItem('userAreaCode');
      setIsResultView(2);

      try {
        const response = await articleSearchGET(regionCode, keyword, page);
        console.log('검색결과', response);
        setPage(1);
        setSearchResult(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          setErrCode(error.response.status);
        } else {
          console.log(error);
        }
      } finally {
        setIsResultView(1);
      }
    }
  };
  // ARCHIVE KEYWORD INSERT
  const handleOnInsertKeyword = async (getKeyword) => {
    setKeyword(getKeyword);
    setResultKeyword(getKeyword);
    saveKeywordToLocalStorage(getKeyword);
    const regionCode = localStorage.getItem('userAreaCode');
    setIsResultView(2);
    try {
      const response = await articleSearchGET(regionCode, getKeyword, page);
      console.log('검색결과', response);
      setPage(1);
      setSearchResult(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        setErrCode(error.response.status);
      } else {
        console.error(error);
      }
    } finally {
      setIsResultView(1);
    }
  };

  useEffect(() => {
    const handleScrollPageView = async () => {
      const regionCode = localStorage.getItem('userAreaCode');
      try {
        const nextPage = page + 1;
        setPage(nextPage);
        const result = await articleSearchGET(regionCode, keyword, nextPage);
        setSearchResult((prevData) => [...prevData, ...result.data]);
      } catch (err) {
        console.error(err);
      }
    };
    if (inView) {
      handleScrollPageView();
    }
  }, [inView]);
  return (
    <div className="search_section fixed">
      <div className="page_header flex flex_jc_sb flex_ai_c">
        <div className="close_btn relative" onClick={close}>
          <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" width={10} height={19} />
        </div>
        <div className="input_box relative">
          <label htmlFor="keyword" className="absolute"></label>
          <input
            type="text"
            id="keyword"
            onChange={onChangeKeyword}
            value={keyword}
            placeholder="검색어를 입력해주세요."
          />
          <div className="btn_box input_button absolute">
            <div className={`button ${keyword.length >= 2 ? '' : 'disable'}`} onClick={() => handleClearInput()}>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="search_btn" onClick={() => handleSearchArticle()}>
          <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Search.svg`} alt="" width={18} height={17} />
        </div>
      </div>
      {isResultView === 0 ? (
        <div className="keyword_archive">
          <div className="archive_header flex flex_jc_sb flex_ai_c">
            <div className="title">최근 검색어</div>
            <div className="clear_archive" onClick={handleClearLocalStorage}>
              전체 지우기
            </div>
          </div>
          <div className="archive_list">
            <ul>
              {keywordArchive === undefined || keywordArchive.length <= 0 ? (
                <li className="empty_list">최근 검색어가 없습니다.</li>
              ) : (
                keywordArchive.map((item, index) => (
                  <li key={index} className="flex flex_jc_sb flex_ai_c">
                    <div className="keyword" onClick={() => handleOnInsertKeyword(item.keyword)}>
                      {item.keyword}
                    </div>
                    <div className="time_table flex flex_jc_sb flex_ai_c">
                      <div className="reg_dt">{item.timestamp}</div>
                      <div className="delete_keyword button relative" onClick={() => handleDeleteKeyword(index)}>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      ) : isResultView === 1 ? (
        <ResultView keyword={resultKeyword} searchResult={searchResult} />
      ) : (
        <SearchLoading />
      )}
    </div>
  );
};

export default Search;
