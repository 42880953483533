import { Terms } from './Data';

const TermsOfUse = ({ close }) => {
  console.log(Terms);
  return (
    <div className="terms_section cover_section fixed">
      <div className="modal_header flex flex_jc_sb flex_ai_c">
        <div className="modal_title">이용약관</div>
        <div className="close_btn relative" onClick={() => close()}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="content">{Terms[0].Terms20231027}</div>
    </div>
  );
};

export default TermsOfUse;
