// MODULE
import { useState } from 'react';
// COMPONENT
import Search from '../Search/Search';

const MainHeader = ({ regionOption, setSelectedRegion, selectedRegion, all, allLocation, setPage }) => {
  const [isOption, setIsOption] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);

  const setDefaultValue = () => {
    setIsOption(false);
    setPage(1);
    window.scrollTo(0, 0);
  };
  const onClickOption = () => {
    setIsOption(!isOption);
  };
  const onClickLocation = (option) => {
    setSelectedRegion(option);
    allLocation(undefined);
    setDefaultValue();
  };
  const onClickAllLocation = () => {
    allLocation(true);
    setDefaultValue();
  };
  const onClickSearch = () => {
    setSearchPopup(true);
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'hidden';
  };
  const handleSearchPopupClose = () => {
    setSearchPopup(false);
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'unset';
  };
  const myLocationType = typeof regionOption === 'object';
  return (
    <>
      <header>
        <div className="flex flex_jc_sb">
          <div className="location_select">
            <div className="flex_frame">
              {/* {loginCheck ? ( */}
              <button className="current_location flex flex_jc_s flex_ai_c" onClick={() => onClickOption()}>
                <span>{all === undefined ? selectedRegion?.regionName : '전체'}</span>
                {/* {regionOptionCheck?.length >= 1 && ( */}
                <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Bottom_arrow.svg`} alt="" />
                {/* )} */}
              </button>
              {/* ) : (
                <span>전체</span>
              )} */}
            </div>
            {
              // regionOptionCheck?.length >= 1 &&
              isOption && (
                <div className="option_box">
                  <div className="my_location_list">
                    {/* {regionOption?.map((option, index) => { */}
                    {myLocationType
                      ? regionOption?.map((option, index) => {
                          return (
                            <button
                              className={selectedRegion.code === option.code && all === undefined ? 'checked' : ''}
                              onClick={() => onClickLocation(option)}
                              key={index}
                            >
                              {option.regionName}
                            </button>
                          );
                        })
                      : [selectedRegion]?.map((option, index) => {
                          return (
                            <button
                              className={selectedRegion.code === option.code && all === undefined ? 'checked' : ''}
                              onClick={() => onClickLocation(option)}
                              key={index}
                            >
                              {option.regionName}
                            </button>
                          );
                        })}
                    <button className={`${all !== undefined && 'checked'}`} onClick={() => onClickAllLocation()}>
                      전체
                    </button>
                  </div>
                </div>
              )
            }
            <div className="logo flex_frame flex_jc_c">
              <img src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`} alt="" />
            </div>

            <div className="flex_frame flex_jc_e">
              <button onClick={() => onClickSearch()}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Search.svg`} alt="" />
              </button>
            </div>
          </div>
        </div>
      </header>
      {searchPopup ? <Search close={handleSearchPopupClose} /> : null}
    </>
  );
};

export default MainHeader;
