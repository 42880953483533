// MODULE
import React, { useEffect, useState, useRef } from 'react';
// IMAGE

const KakaoMapImage = ({ placeName, getLat, getLon }) => {
  const containerImg = useRef(null);
  const [map, setMap] = useState(null);
  const [name] = useState(placeName);
  useEffect(() => {
    if (!map) {
      const customOverlay = new window.kakao.maps.CustomOverlay({
        map: map,
        content:
          `<div class="place_name">` +
          `<a  href="http://map.kakao.com/link/map/${placeName},${getLat},${getLon}" target="_blank">` +
          placeName +
          `</a>` +
          `</div>`,
        position: new window.kakao.maps.LatLng(getLat, getLon),
        xAnchor: 0.5,
        yAnchor: 1.8,
      });

      const MarkerSrc = process.env.PUBLIC_URL + '/assets/img/icon/Marker.svg';
      const MarkerSize = new window.kakao.maps.Size(16, 21);
      const MarkerInfo = new window.kakao.maps.MarkerImage(MarkerSrc, MarkerSize);
      const marker = new window.kakao.maps.Marker({
        map: map,
        position: new window.kakao.maps.LatLng(getLat, getLon),
        image: MarkerInfo,
      });
      const mapContainer = window.document.getElementById('map');
      const mapOption = {
        center: new window.kakao.maps.LatLng(getLat, getLon),
        level: 3,
        draggable: false,
        scrollwheel: false,
        disableDoubleClick: false,
        disableDoubleClickZoom: false,
      };
      const mapInstance = new window.kakao.maps.Map(mapContainer, mapOption);
      marker.setMap(mapInstance);
      customOverlay.setMap(mapInstance);
      setMap(mapInstance);
      mapInstance.setMinLevel(3);
      mapInstance.setMaxLevel(3);
    }
  }, [name]);

  return (
    <>
      <div id="map" ref={containerImg} style={{ width: '100%', height: 175, margin: '0 auto', borderRadius: 10 }}></div>
    </>
  );
};

export default React.memo(KakaoMapImage);
