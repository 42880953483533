import axios from '../utils/instance';

const uri = '/articles';

// PLACE ARTICLE LIST GET
export const clusterListGET = (placeName, page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}`, {
        headers: { 'Content-Type': 'application/json' },
        params: { placeName: placeName, page: page },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
