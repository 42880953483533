// MODULE
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// HOOK
import { findLoginIdPOST } from '../../api/member';
// COMPONENT
import Input from '../Common/Input';

const ResultView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0rem;
  margin-top: 35%;
  background: var(--tooltip-bg-color);
  border-radius: 0.5rem;
  border: 1px solid var(--gray-color);
  font-size: 1.3rem;
  & > span {
    font-size: 1.7rem;
    font-weight: 700;
  }
  ~ .btn_box {
    margin-top: 8rem;
  }
`;
const FindId = ({ Frame, status }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const lengthCheck = email.length > 6 ? true : false;
  const handleGetLoginId = async () => {
    try {
      const response = await findLoginIdPOST(email);
      setResult(response);
      status(true);
    } catch (error) {
      if (error.response.data.message === '회원이 존재하지 않습니다.') {
        alert(error.response.data.message);
      } else {
        console.log('error\n', error);
      }
    }
  };
  return (
    <Frame>
      {result.status !== 200 ? (
        <>
          <Input
            value={email}
            id={'email'}
            name={'email'}
            placeholder={'이메일'}
            label={'이메일'}
            onChange={(e) => onChangeEmail(e)}
          />
          <div className="btn_box">
            <div className={`button ${!lengthCheck && 'disable'}`} onClick={() => lengthCheck && handleGetLoginId()}>
              아아디 찾기
            </div>
          </div>
        </>
      ) : (
        <div>
          <ResultView>
            회원님의 아이디는 <span>'{result.data.loginId}'</span>입니다.
          </ResultView>
          <div className="btn_box">
            <div className="button" onClick={() => navigate(-1)}>
              로그인 하러가기
            </div>
          </div>
        </div>
      )}
    </Frame>
  );
};

export default FindId;
