// MODULE
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// HOOK
import { noticeDetailGET } from '../../api/notice';
import { convertHTMLCode } from '../../utils/textEffect';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';

const NoticeDetail = () => {
  const { state } = useLocation();
  const [detailData, setDetailData] = useState(null);

  const getDetailData = async () => {
    try {
      const result = await noticeDetailGET(state.id);
      setDetailData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetailData();
  }, []);

  return (
    <div className="notice notice_detail">
      <div className="notice_header flex flex_jc_sb flex_ai_c">
        <HistoryBack />
        <div className="page_title  flex flex_jc_c">공지사항</div>
      </div>
      <div className="detail_header">
        <div className="title">{detailData !== null ? detailData.title : ''}</div>
        <div className="create_dt">{detailData !== null ? detailData.createdAt : ''}</div>
      </div>
      <div className="contents">{detailData !== null ? convertHTMLCode(detailData.content) : ''}</div>
    </div>
  );
};

export default NoticeDetail;
