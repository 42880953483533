// MODULE
import { useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
// RECOIL STATE
import { messageMenuState } from '../../state/messageState';
// UTIL
import { rememberPrevPage } from '../../utils/common';
// HOOK
import { articleDELETE } from '../../api/article';
import { userBlockedPATCH } from '../../api/member';
import { buyRequestStatePATCH, leaveMessagePATCH } from '../../api/buyRequest';
import { refreshTokenGET } from '../../api/auth';
// COMPONENT
import ModalFrame from '../Modal/ModalFrame';

const ToastBottomMenu = ({
  close,
  menuState,
  currentMenu,
  articleId,
  data,
  itemInfo,
  requestId,
  pages,
  isCompleted,
  id,
  memberId,
  memberNickname,
  loginCheck,
}) => {
  const getOutletData = useOutletContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [buyRequestEnd, setBuyRequestEnd] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(0);
  const [blockedModal, setBlockedModal] = useState(0);
  const closeMenu = useResetRecoilState(messageMenuState);
  // LOGIN CHECK
  const handleEventLoginCheck = (getEvent) => {
    refreshTokenGET()
      .then((response) => {
        getEvent();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // OPEN DELETE CONFIRM MODAL
  const handleOpenDeleteConfirmModal = () => {
    setDeleteConfirm(true);
  };
  // CLOSE DELETE CONFIRM MODAL
  const handleCloseDeleteConfirmModal = () => {
    setDeleteConfirm(false);
  };
  // DELETE THIS ARTICLE
  const handleDeleteArticle = async () => {
    try {
      const response = await articleDELETE(articleId);
      if (response.status === 204) {
        navigate('/home');
      }
    } catch (error) {
      console.log(error);
    }
  };
  // REVIEW PAGE MOVE
  const handleReviewPageMove = () => {
    if (isCompleted === true) {
      navigate('/message/review', {
        state: {
          partnerNickname: itemInfo.partnerNickname,
          productName: itemInfo.productName,
          requestId: requestId,
        },
      });
    }
    closeMenu();
  };
  // TRADE STATE CHANGE
  const handleBuyRequestStateChange = async () => {
    const data = { isCompleted: true };
    try {
      const response = await buyRequestStatePATCH(id, data);
    } catch (error) {
      console.log(error);
    }
    closeMenu();
  };
  // LEAVE MESSAGE ROOM
  const handleLeaveMessageRoom = async () => {
    const data = { isLeft: true };
    try {
      const response = await leaveMessagePATCH(id, data);
      if (response.status === 200) {
        closeMenu();
        window.location.replace('/message/list');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const leaveMessageRoom = () => {
    if (isCompleted === true) {
      setLeaveModal(1);
    } else {
      setLeaveModal(2);
    }
  };
  // USER REPORT
  const handleUserReport = () => {
    navigate('/user-report', {
      state: {
        articleId: articleId !== undefined ? articleId : state.articleId,
        data: data !== undefined ? data : state.data !== undefined ? state.data : itemInfo,
      },
    });
  };
  // USER BLOCKED
  const handleUserBlocked = async () => {
    setBlockedModal(1);
  };
  const handleConformUserBlocked = async () => {
    const formData = new FormData();
    const json = JSON.stringify({
      blockedMemberId: Number(memberId),
    });
    const blob = new Blob([json], { type: 'application/json' });
    formData.append('patchData', blob);
    const id = localStorage.getItem('memberId');
    try {
      const response = await userBlockedPATCH(id, formData);
      if (response.status === 200) {
        setBlockedModal(2);
        closeMenu();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFinishUserBlocked = () => {
    setBlockedModal(0);
    navigate(-1);
  };
  console.log(isCompleted);
  return (
    <>
      {loginModal && (
        <ModalFrame
          confirm={() => (rememberPrevPage(window.location.href), navigate('/login'))}
          custom_txt={'로그인이 필요한 서비스입니다. \n로그인 하시겠습니까?'}
          buttons={() => setLoginModal(false)}
          type={'type_3'}
        />
      )}
      {deleteConfirm && (
        <ModalFrame
          confirm={handleDeleteArticle}
          custom_txt={'정말 해당 게시글을 삭제하시겠어요?'}
          buttons={handleCloseDeleteConfirmModal}
          type={'type_3'}
        />
      )}
      {buyRequestEnd ? (
        <ModalFrame
          custom_txt={`${memberNickname} 님과 거래는 하셨나요? 구매완료로 상태를 변경하시면 후기를 받을 수 있어요.`}
          type={'type_3'}
          confirm={() => setBuyRequestEnd(false)}
          buttons={() => setBuyRequestEnd(false)}
        />
      ) : leaveModal === 1 ? (
        <ModalFrame
          custom_txt={'해당 채팅방을 나가시겠어요?'}
          type={'type_3'}
          confirm={() => handleLeaveMessageRoom()}
          buttons={() => setLeaveModal(0)}
        />
      ) : leaveModal === 2 ? (
        <ModalFrame
          custom_txt={`아직 ${memberNickname} 님과의 거래가 완료되지 않았아요. 그래도 채팅방을 나가시겠어요?`}
          type={'type_3'}
          confirm={() => handleLeaveMessageRoom()}
          buttons={() => setLeaveModal(0)}
        />
      ) : (
        ''
      )}
      {blockedModal === 1 ? (
        <ModalFrame
          custom_txt={`정말 ${memberNickname} 님을 차단하시겠어요?`}
          type={'type_3'}
          confirm={() => handleEventLoginCheck(handleConformUserBlocked)}
          confirm_txt={'차단'}
          buttons={() => setBlockedModal(0)}
        />
      ) : (
        blockedModal === 2 && (
          <ModalFrame
            custom_txt={`${memberNickname} 님을 차단했어요.`}
            type={'type_3'}
            confirm={() => handleFinishUserBlocked()}
          />
        )
      )}
      <div className={`toast_menu fixed ${menuState ? '' : 'hide'}`}>
        <ul className="flex flex_dir_c">
          {pages === 'detail' && (
            <>
              {currentMenu ? (
                <>
                  <li onClick={() => navigate(`/modify`, { state: { articleId: articleId, data: data } })}>수정하기</li>
                  <li onClick={() => handleEventLoginCheck(handleOpenDeleteConfirmModal)}>삭제하기</li>
                </>
              ) : (
                <>
                  <li onClick={() => handleUserBlocked()}>상대방 차단하기</li>
                  <li onClick={() => handleEventLoginCheck(handleUserReport)}>신고하기</li>
                </>
              )}
            </>
          )}
          {pages === 'message' && (
            <>
              {currentMenu ? (
                <li
                  className={!isCompleted && 'review_ready disable relative'}
                  onClick={() => isCompleted && handleEventLoginCheck(handleReviewPageMove)}
                >
                  후기 남기기
                </li>
              ) : (
                <li
                  className={isCompleted && 'completed_trade disable relative'}
                  onClick={() => !isCompleted && handleEventLoginCheck(handleBuyRequestStateChange)}
                >
                  거래 완료하기
                </li>
              )}
              <li onClick={() => handleEventLoginCheck(handleUserBlocked)}>상대방 차단하기</li>
              <li onClick={() => handleEventLoginCheck(handleUserReport)}>신고하기</li>
              <li onClick={() => handleEventLoginCheck(leaveMessageRoom)}>대화방 나가기</li>
            </>
          )}
          {pages === 'userinfo' && (
            <>
              <li onClick={() => handleEventLoginCheck(handleUserBlocked)}>상대방 차단하기</li>
              <li onClick={() => handleEventLoginCheck(handleUserReport)}>신고하기</li>
            </>
          )}
          <li onClick={close}>닫기</li>
        </ul>
      </div>
    </>
  );
};

export default ToastBottomMenu;
