const OnlyOne = ({ loop }) => {
  const rendering = () => {
    const result = [];
    for (let i = 0; i <= loop; i++) {
      result.push(
        <li className="list_item_skeleton flex flex_jc_sb" key={i}>
          <div className="img_box"></div>
          <div className="info_view flex flex_dir_c ">
            <div className="small_text"></div>
            <div className="middle_text"></div>
            <div className="big_text"></div>
            <div className="middle_text flex_as_e" style={{ width: '30%' }}></div>
          </div>
        </li>
      );
    }
    return result;
  };
  return rendering();
};

const DoubleItem = ({ loop }) => {
  const rendering = () => {
    const result = [];
    for (let i = 0; i <= loop; i++) {
      result.push(
        <li className="list_item_skeleton double" key={i}>
          <div className="img_box flex"></div>
          <div className="info_view flex flex_dir_c ">
            <div className="middle_text"></div>
            <div className="small_text"></div>
            <div className="big_text"></div>
            <div className="middle_text" style={{ width: '30%' }}></div>
          </div>
        </li>
      );
    }
    return result;
  };
  return rendering();
};

const ChatListItem = ({ loop }) => {
  const rendering = () => {
    const result = [];
    for (let i = 0; i <= loop; i++) {
      result.push(
        <li className="list_item_skeleton chat_item flex flex_jc_sb" key={i}>
          <div className="profile img_box"></div>
          <div className="message_body flex flex_dir_c">
            <div className="user_info flex flex_jc_s flex_ai_c">
              <div className="nickname"></div>
              <div className="item_location"></div>
            </div>
            <div className="message_data"></div>
          </div>
          <div className="product_img"></div>
        </li>
      );
    }
    return result;
  };
  return rendering();
};

const PointLog = ({ loop }) => {
  const rendering = () => {
    const result = [];
    for (let i = 0; i <= loop; i++) {
      result.push(
        <li className="list_item_skeleton point_log flex flex_ai_c" key={i}>
          <div className="date"></div>
          <div className="contents"></div>
        </li>
      );
    }
    return result;
  };
  return rendering();
};

const DetailItem = ({ itemWid, itemHei, marTop, loop }) => {
  const realLoop = loop === undefined ? 0 : loop;
  const rendering = () => {
    const result = [];
    for (let i = 0; i <= realLoop; i++) {
      result.push(
        <div
          className={`list_item_skeleton detail_item`}
          style={{ width: itemWid, height: itemHei, marginTop: marTop }}
          key={i}
        ></div>
      );
    }
    return result;
  };
  return rendering();
};

const Skeleton = ({ itemWid, itemHei, marTop, title, contents, type, loop }) => {
  return (
    <>
      {type === 'one_type' ? (
        <OnlyOne loop={loop} />
      ) : type === 'double_type' ? (
        <DoubleItem loop={loop} />
      ) : type === 'chat_list' ? (
        <ChatListItem loop={loop} />
      ) : type === 'point_log' ? (
        <PointLog loop={loop} />
      ) : (
        <DetailItem itemWid={itemWid} itemHei={itemHei} marTop={marTop} loop={loop} />
      )}
    </>
  );
};

export default Skeleton;
