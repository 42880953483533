// MODULE
import { useEffect, useState } from 'react';
// HOOK
import { likeArticleGET } from '../../../api/member';

import ArticleList from '../../../pages/ArticleList';
const LikeList = () => {
  const [likeItem, setLikeItem] = useState(null);
  const [page, setPage] = useState(1);

  const empty_txt = '찜한 상품이 없어요.';
  const articleGet = async () => {
    const response = await likeArticleGET(page);
    const result = response.data;
    if (page > 1) {
      setLikeItem([...likeItem, ...result]);
    } else {
      setLikeItem(result);
    }
  };

  useEffect(() => {
    articleGet();
  }, [page]);

  return <ArticleList data={likeItem} setPage={setPage} page={page} value={true} empty_txt={empty_txt} />;
};

export default LikeList;
