// MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
// HOOKS
import { certNumberCheckGET, certSMSSendGET } from '../../api/auth';
// import { testNumberCheckGET } from '../../api/auth';
import { setCookie } from '../../utils/cookie';
import Timer from '../../utils/Timer';
import { saveLoginData } from '../../utils/saveData';
import { myProfileInfoGET } from '../../api/member';
// RECOIL STATE
import { registerData } from '../../state/registerState';
import { setCertificationState } from '../../state/userState';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import ModalFrame from '../../components/Modal/ModalFrame';

// Add the byapps functions
const byapps_is_api_enable = function () {
  return !!navigator.userAgent.match('Byapps');
};

const byapps_api_call = function (a, s) {
  if (byapps_is_api_enable()) {
    if (window.byappsJSI && window.byappsJSI.callAPI) {
      window.byappsJSI.callAPI(a, s);
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.byappsJSI) {
      let e = a;
      s && (e += '?' + s);
      window.webkit.messageHandlers.byappsJSI.postMessage(e);
    }
  }
};

const PhoneCertification = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [stepTwo, setStepTwo] = useState(false);
  const [certCode, setCertCode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [, setGetRegisData] = useRecoilState(registerData);
  const [, setIsCert] = useState(false);
  const [errModal, setErrModal] = useState(0);
  const [timeReset, setTimeReset] = useState(false);

  const isTimeEnd = useRecoilValue(setCertificationState);

  const onChangeTelNum = (e) => {
    e.preventDefault();

    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length > 10) {
      value = value.slice(0, 3) + '-' + value.slice(3, 7) + '-' + value.slice(7);
    }
    setPhone(value);
  };

  const onChangeCertNum = (e) => {
    e.preventDefault();
    setCertCode(e.target.value.replace(/[^0-9]/g, ''));
  };
  const accepts = phone.length;

  const [certId, setCertId] = useState(Number);
  const handleCertSMSSend = async () => {
    try {
      const result = await certSMSSendGET(phone);
      setCertId(result.data.id);
      setStepTwo(true);
      setTimeReset(true);
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setErrMsg('4');
      }
      console.error(error);
      console.log(error.code);
    } finally {
      setTimeout(() => {
        setTimeReset(false);
      }, 0);
    }
  };

  const handleCloseModal = () => {
    setErrMsg('');
  };

  async function saveUserData(response) {
    try {
      if (response.data.message === '회원가입이 필요한 유저 입니다.') {
        setGetRegisData({ phone: phone.replace(/-/g, '') });
        setIsCert(true);
        navigate('/register-terms');
      } else if (response.data.message === '인증되었습니다. 가입 이력이 존재하는 회원이므로 토큰을 발급합니다.') {
        const result = response.data.resultData;
        const needValue = '2,3,4,5,6,7,8,11,16';
        const loginResult = await myProfileInfoGET(result.id, needValue);
        const resultData = loginResult.data;
        setCookie('firstRegionOfResidence', resultData.firstRegionOfResidence, { path: '/' });
        setCookie('secondRegionOfResidence', resultData.secondRegionOfResidence, { path: '/' });
        saveLoginData(result.id, resultData);
        navigate('/home');
      }
    } catch (error) {
      console.log('error', error);
      if (error.response.status === 401) {
        setErrModal(99);
      }
    }
  }

  const handleRegisterNextStep = async () => {
    if (certCode.length < 6) {
      setErrMsg('인증번호 6자리를 입력해주세요.');
    } else {
      // if (Number(certCode) === 123459) {
      //   try {
      //     const testAccount = await testNumberCheckGET(certCode);
      //     saveUserData(testAccount);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // } else
      if (isTimeEnd) {
        setErrModal(2);
      } else {
        try {
          const response = await certNumberCheckGET(certId, certCode);
          saveUserData(response);
        } catch (error) {
          if (error.response.data.message === 'RandomNumber 가 일치하지 않습니다.') {
            setErrModal(1);
            console.log('error', error);
          } else if (error.response.data.message === 'RandomNumber 가 존재하지 않습니다.') {
            console.log('error', error);
            setErrModal(99);
          } else {
            setErrModal(99);
            console.log(error);
          }
        }
      }
    }
  };
  const byappsNavEndEvent = () => {
    byapps_api_call('hideBottomMenu');
  };

  // TEXT
  const point_txt = '휴대폰번호는 안전하게 보관되며,\n공개되지 않아요.';
  return (
    <div className="cert_section">
      <HistoryBack />
      <div className="page_title flex flex_jc_s">휴대폰 번호로 시작하기</div>
      <span className="point_txt flex flex_jc_s">{point_txt}</span>
      <div className="tel_box input_box relative flex flex_dir_c">
        <label htmlFor="tel" className="absolute"></label>
        <input
          type="text"
          id="tel"
          value={phone}
          onChange={onChangeTelNum}
          maxLength={11}
          placeholder="휴대폰 번호 입력"
          onFocus={byappsNavEndEvent}
          onBlur={byappsNavEndEvent}
        />
        <div className="btn_box input_button absolute">
          <button
            className={`button ${accepts >= 10 ? '' : 'disable'}`}
            onClick={accepts >= 10 ? () => handleCertSMSSend() : null}
          >
            인증 문자 받기
          </button>
        </div>
      </div>
      {stepTwo && (
        <div className="cert_code">
          <div className="cert_box input_box relative flex flex_dir_c">
            <label htmlFor="cert" className="absolute"></label>
            <input
              type="text"
              id="cert"
              value={certCode}
              onChange={onChangeCertNum}
              placeholder="인증번호 6자리 입력"
              maxLength={6}
              onFocus={byappsNavEndEvent}
              onBlur={byappsNavEndEvent}
            />
            <Timer time={300} timeReset={timeReset} />
          </div>
          <span className="point_txt flex flex_jc_e">타인에게 공유하지 마세요!</span>
        </div>
      )}
      {errModal === 1 ? (
        <ModalFrame custom_txt={'인증번호가 일치하지 않습니다.'} confirm={() => setErrModal(0)} type={'type_3'} />
      ) : (
        errModal === 2 && (
          <ModalFrame
            custom_txt={'유효시간이 만료되었습니다.\n인증문자를 다시 발급받아주세요.'}
            confirm={() => setErrModal(0)}
            type={'type_3'}
          />
        )
      )}
      {errMsg !== '' ? <ModalFrame txt={4} confirm={handleCloseModal} type={'type_1'} /> : null}
      <div className="btn_box">
        <button
          className={`button ${stepTwo && certCode.length >= 6 ? '' : 'disable'}`}
          onClick={stepTwo ? () => handleRegisterNextStep() : null}
        >
          시작하기
        </button>
      </div>
    </div>
  );
};

export default PhoneCertification;
