import axios from '../utils/instance';

const uri = '/reports';

export const userReportPOST = (formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return data;
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
