// MODULE
import { useNavigate } from 'react-router-dom';
// UTIL
import { rememberPrevPage } from '../../../utils/common';
// COMPONENT
import ProfileImage from '../../ProfileImage/ProfileImage';
import Skeleton from '../../Skeleton';
import ModalFrame from '../../Modal';
// IMAGES
import { ReactComponent as LikeCounter } from '../../../assets/img/icon/Like_icon.svg';
import { ReactComponent as MessageCounter } from '../../../assets/img/icon/Message_icon.svg';
import Default_Img from '../../../assets/img/default_profile.png';
import { useState } from 'react';

const WriterInfo = ({ userId, nickname, profile, like, message, myArticle, data, loginCheck }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const handleUserInfo = () => {
    // !loginCheck
    //   ? setModal(true)
    //   :
    navigate(`/current-user`, {
      state: { id: userId, isMyArticle: myArticle, data: data, articleId: data.id, loginCheck: loginCheck },
    });
  };
  return (
    <>
      {modal && (
        <ModalFrame
          confirm={() => (rememberPrevPage(window.location.href), navigate('/login'))}
          custom_txt={'작성자의 상세정보를 보려면 로그인이 필요합니다.\n 로그인 하시겠습니까?'}
          buttons={() => setModal(false)}
          type={'type_3'}
        />
      )}
      <div className="writer_info flex flex_jc_sb flex_ai_c">
        <div className="user_info flex flex_ai_c" onClick={() => handleUserInfo()}>
          <div className="img_box profile">
            {profile === undefined ? (
              <Skeleton itemWid={45} itemHei={45} />
            ) : (
              <ProfileImage
                src={profile === null ? Default_Img : `${process.env.REACT_APP_IMAGE_URL}${profile}`}
                alt={nickname}
                size={45}
              />
            )}
          </div>
          <div className="nickname">{nickname === undefined ? <Skeleton itemWid={70} itemHei={16} /> : nickname}</div>
        </div>
        <div className="counter_item flex">
          <div className="purchase_request flex flex_ai_c">
            <MessageCounter />
            <span>{message === undefined ? <Skeleton itemWid={10} itemHei={13} /> : message}</span>
          </div>
          <div className="like flex flex_ai_c">
            <LikeCounter />
            <span>{like === undefined ? <Skeleton itemWid={10} itemHei={13} /> : like}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WriterInfo;
