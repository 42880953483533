const ArticleCheckBox = ({ purchasable, nationWide, changePurchasable, changeNationWide }) => {
  return (
    <div className="check_box_section flex flex_jc_sa">
      <div className="wide_location flex flex_ai_c">
        <input
          type="checkbox"
          id="wide_location"
          value={purchasable}
          checked={purchasable}
          onChange={changePurchasable}
        />
        <label htmlFor="wide_location" className="relative">
          채팅가능
        </label>
      </div>
      <div className="request flex flex_ai_c">
        <input type="checkbox" id="request" value={nationWide} checked={nationWide} onChange={changeNationWide} />
        <label htmlFor="request" className="relative">
          전국노출
        </label>
      </div>
    </div>
  );
};

export default ArticleCheckBox;
