// IMAGE

const Splash = () => {
  const id = localStorage.getItem('memberId');
  setTimeout(() => {
    // if (id) {
    //   window.location.href = '/home';
    // } else {
    //   window.location.href = '/login';
    // }
    window.location.href = '/home';
  }, 500);

  return (
    <div
      className="fixed"
      style={{
        zIndex: 9999,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgb(9, 136, 244)',
        background: 'linear-gradient(0deg, rgba(9, 136, 244, 1) 0%, rgba(33, 225, 244, 1) 100%)',
      }}
    >
      <div className="img_box flex flex_jc_c" style={{ width: '91%', margin: '30% auto 0 auto' }}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo_w.png`} alt="" style={{ width: '50%' }} />
      </div>
    </div>
  );
};

export default Splash;
