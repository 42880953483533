// MODULE
import { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
// HOOK
import { userReportPOST } from '../../api/report';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import ModalFrame from '../../components/Modal';

const reportTypes = [
  {
    id: 1,
    label: '비매너 사용자에요.',
  },
  {
    id: 2,
    label: '부적절한 언행을 사용해요.',
  },
  {
    id: 3,
    label: '거짓 정보를 공유해요.',
  },
  {
    id: 4,
    label: '사기를 쳤어요.',
  },
  {
    id: 5,
    label: '욕설을 퍼부었어요.',
  },
  {
    id: 6,
    label: '기타',
  },
];

const UserReport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const imgRef = useRef();

  const [uploadImage, setUploadImage] = useState([]);
  const [inputErr, setInputErr] = useState('');
  const [reportProcess, setReportProcess] = useState(0);

  // CONTENT
  const [content, setContent] = useState('');
  const onChangeContent = (e) => {
    e.preventDefault();
    // setContent(e.target.value.replace(/<br\s*\/?>/gm, '\n'));
    const sanitizedValue = e.target.value.replace(/<br\s*\/?>/gm, '\n');
    setContent(sanitizedValue);
  };
  // IMAGE RESIZE
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });
  const [resizeImg, setResizeImg] = useState([]);
  const handleAddImages = async (event) => {
    const imageLists = event.target.files;
    const resizedImages = [];

    const imageUrlLists = [...resizeImg];

    for (let i = 0; i < imageLists.length; i++) {
      const file = imageLists[i];
      const image = await resizeFile(file);
      resizedImages.push(image);
    }

    let newImageList = [...uploadImage, ...resizedImages.slice(0, 3 - uploadImage.length)];
    let newImageThumbList = newImageList.map((image) => (image instanceof File ? URL.createObjectURL(image) : image));

    if (resizedImages.length > 5) {
      alert('이미지는 최대 3장 까지만 등록이 가능합니다.');
      setResizeImg(newImageList.slice(0, 3));
      setUploadImage(newImageThumbList);
    } else {
      setResizeImg(newImageList);
      setUploadImage(newImageThumbList);
    }
  };
  // DELETE IMAGE
  const handleDeleteImage = (id) => {
    setUploadImage(uploadImage.filter((_, index) => index !== id));
    setResizeImg(resizeImg.filter((_, index) => index !== id));
  };
  // USER REPORT CONFORM
  const handleUserReportConform = () => {
    setReportProcess(1);
  };

  const [selectedReportTypes, setSelectedReportTypes] = useState([]);

  // CHECKBOX CHANGE
  const handleCheckboxChange = (value) => {
    const isChecked = selectedReportTypes.includes(value);
    if (isChecked || selectedReportTypes.length < 3) {
      setSelectedReportTypes((prevSelected) => {
        if (isChecked) {
          return prevSelected.filter((type) => type !== value);
        } else {
          return [...prevSelected, value];
        }
      });
    }
  };
  const handleUserReportFinish = async () => {
    const formData = new FormData();
    for (let i = 0; i < resizeImg.length; i++) {
      formData.append('images', resizeImg[i]);
    }
    const json = JSON.stringify({
      content: content,
      reportTypes: selectedReportTypes,
      reportedMemberId: state?.data.writerId,
      articleId: state?.articleId,
    });
    const blob = new Blob([json], { type: 'application/json' });
    formData.append('postData', blob);
    try {
      const response = await userReportPOST(formData);
      if (response.status === 201) {
        setReportProcess(2);
      }
    } catch (error) {
      if (error.response.status === 409) {
        setReportProcess(3);
      }
      console.log(error);
    }
  };
  const valueCheck = content.length > 0 && resizeImg.length > 0 && selectedReportTypes.length > 0;
  return (
    <>
      {reportProcess === 1 ? (
        <ModalFrame
          type={'type_3'}
          custom_txt={'user_report'}
          confirm={() => setReportProcess(0)}
          confirm_txt={'취소'}
          buttons={() => handleUserReportFinish()}
          buttons_txt={'신고하기'}
        />
      ) : reportProcess === 2 ? (
        <ModalFrame type={'type_4'} custom_txt={'상대방을 신고하였습니다.'} confirm={() => navigate(-1)} />
      ) : reportProcess === 3 ? (
        <ModalFrame type={'type_4'} custom_txt={'이미 신고내역이 존재합니다.'} confirm={() => navigate(-1)} />
      ) : (
        ''
      )}
      <div className="user_report_section">
        <div className="report_header fixed flex flex_jc_sb flex_ai_c">
          <HistoryBack />
          <div>신고하기</div>
          <div></div>
        </div>
        <div className="report_body">
          <div className="title">
            <p>
              {state.data.writerNickname !== undefined ? state.data.writerNickname : state.data.partnerNickname}님을
              신고하시겠어요?
            </p>
            <p>신고이유를 선택해주세요.</p>
          </div>
          <div className="select_reason">
            {reportTypes.map((type) => (
              <div key={type.id} className="flex flex_ai_c">
                <input
                  type="checkbox"
                  id={`type_${type.id}`}
                  value={type.id}
                  checked={selectedReportTypes.includes(type.id)}
                  onChange={() => handleCheckboxChange(type.id)}
                  disabled={!selectedReportTypes.includes(type.id) && selectedReportTypes.length >= 3}
                />
                <label htmlFor={`type_${type.id}`} className="relative">
                  {type.label}
                </label>
              </div>
            ))}
          </div>
          <div className="text_box relative">
            <textarea name="" id="" rows={10} value={content} onChange={onChangeContent} maxLength={300}></textarea>
            <label htmlFor="">0/300</label>
          </div>
          <div className="form_box">
            <p>이미지를 등록해 주세요.</p>
            <div className="picture_section flex flex_ai_fe relative">
              <span className="event_txt absolute_top">{inputErr === 4 ? '*상품사진을 추가해주세요.' : ''}</span>

              <div className="picture_box input_box relative">
                <label htmlFor="image_file" className="absolute flex flex_jc_c flex_ai_c">
                  +<span className="img_counter absolute">{uploadImage.length}/3</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  ref={imgRef}
                  onChange={handleAddImages}
                  // onChange={onChange}
                  id="image_file"
                  disabled={uploadImage.length === 5 ? true : false}
                  multiple="multiple"
                />
              </div>

              <div className="thumb_img flex flex_ai_fe">
                {uploadImage.map((image, id) => (
                  <span key={id} className="relative">
                    <div className="img_box relative flex flex_ai_c">
                      <img src={image} alt={`${image}-${id}`} width={70} />
                    </div>
                    <div className="del_btn flex flex_jc_c flex_ai_c" onClick={() => handleDeleteImage(id)}>
                      +
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="btn_box">
            <div
              className={`button ${valueCheck ? '' : 'disable'}`}
              onClick={valueCheck ? () => handleUserReportConform() : null}
            >
              신고하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserReport;
