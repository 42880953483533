// MODULE
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// HOOKS
import { myProfileInfoGET } from '../../../api/member';
// COMPONENT
import HistoryBack from '../../../components/Common/HistoryBack';

const AccountCancellation = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const userName = JSON.parse(localStorage.getItem('userProfiles')).nickname;

  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = async () => {
    const needValue = '3,9';
    const id = localStorage.getItem('memberId');
    try {
      const response = await myProfileInfoGET(id, needValue);
      if (response.status === 200) {
        setUserData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="account_cancel_section">
      <div className="account_cancel_header flex flex_jc_sb">
        <div className="menu_title center">회원탈퇴</div>
      </div>
      <div className="account_cancel_body">
        <div className="sub_title">
          <span>{userName}</span>님!
          <br />
          정말 쇼업을 탈퇴하시겠어요?
        </div>
        <div className="cancel_info">
          <ul>
            <li>지금 탈퇴하시면 등록한 아이템, 관심, 채팅 등 모든 활동 정보가 삭제돼요!</li>
            <li>
              지금 탈퇴하시면 보유하신 포인트가 모드 사라져요!
              <br />
              추후 재가입해도 포인트는 복구되지 않아요!
            </li>
            <li>탈퇴 후 24시간 동안 재가입을 할 수 없어요!</li>
          </ul>
        </div>
        <div className="btn_box absolute flex flex_jc_sb">
          <div className="button" onClick={() => navigate(-1)}>
            더 이용하기
          </div>
          <div
            className="button"
            onClick={() => navigate('/account-cancel-finish', { state: { point: userData?.point } })}
          >
            탈퇴하기
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCancellation;
