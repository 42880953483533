// MODULE
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useRecoilState } from 'recoil';
// RECOIL STATE
import writeState from '../../../state/writeState';
// HOOK
import { ko } from 'date-fns/esm/locale';
// IMAGE

const Calendar = () => {
  const [startDay, setStartDay] = useState(new Date());
  const postStartDay = startDay.toISOString().slice(0, 10);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const defaultEndDate = addDays(new Date(), 7);
  const [endDay, setEndDay] = useState(defaultEndDate);
  const postEndDay = endDay.toISOString().slice(0, 10);

  const [, setRecoilDate] = useRecoilState(writeState.datePickerState);
  useEffect(() => {
    setRecoilDate({ startDate: postStartDay, endDate: postEndDay });
  }, [startDay, endDay]);

  useEffect(() => {
    if (startDay > endDay) {
      alert('종료일은 시작일보다 이전일 수 없습니다.');
      setEndDay(defaultEndDate);
      setStartDay(new Date());
    }
  }, [startDay, endDay]);

  return (
    <div className="date_box flex flex_jc_s">
      <div className="start_day_box input_box flex flex_wrap_wrap">
        <label htmlFor="start_day">시작일</label>
        <DatePicker
          inputmode="none"
          selected={startDay}
          onChange={(date) => setStartDay(date)}
          onFocus={(e) => (e.target.readOnly = true)}
          placeholderText="시작일을 선택해주세요."
          dateFormat="yyyy-MM-dd"
          withPortal
          locale={ko}
          calendarStartDay={1}
          disabledKeyboardNavigation
          calendarClassName="custom_calendar"
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            return (
              <div className="date_header flex flex_jc_sb flex_ai_c">
                <div>
                  {year}년&nbsp;{month}월
                </div>
                <div className="btn_box flex">
                  <button
                    className="prev_btn"
                    type="button"
                    onClick={() => decreaseMonth()}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                  </button>

                  <button className="next_btn" type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                  </button>
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="normal_txt flex_jc_c flex_as_e">-</div>
      <div className="end_day_box input_box flex flex_wrap_wrap">
        <label htmlFor="end_day">종료일</label>
        <DatePicker
          inputmode="none"
          selected={endDay}
          onChange={(date) => setEndDay(date)}
          onFocus={(e) => (e.target.readOnly = true)}
          placeholderText="종료일을 선택해주세요."
          dateFormat="yyyy-MM-dd"
          withPortal
          locale={ko}
          calendarStartDay={1}
          disabledKeyboardNavigation
          calendarClassName="custom_calendar"
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            return (
              <div className="date_header flex flex_jc_sb flex_ai_c">
                <div>
                  {year}년&nbsp;{month}월
                </div>
                <div className="btn_box flex">
                  <button
                    className="prev_btn"
                    type="button"
                    onClick={() => decreaseMonth()}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                  </button>

                  <button className="next_btn" type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                  </button>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
