// MODULE
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// UTIL
import { rememberPrevPage } from '../../../utils/common';
// HOOKS
import { buyRequestPOST } from '../../../api/buyRequest';
// COMPONENT
import ModalFrame from '../../../components/Modal';
// IMAGES
import { ReactComponent as LikeBtn } from '../../../assets/img/icon/Like_icon.svg';
import { ReactComponent as ShareBtn } from '../../../assets/img/icon/Share_icon.svg';

const RequestMessage = ({ isPurchasable, buyRequest, articleId, modalStatus, closeModal }) => {
  const navigate = useNavigate();
  return isPurchasable ? (
    <>
      {modalStatus === 1 ? (
        <ModalFrame custom_txt={'이미 채팅이 진행중 입니다.'} type={'type_1'} confirm={closeModal} />
      ) : modalStatus === 2 ? (
        <ModalFrame
          confirm={() => (rememberPrevPage(window.location.href), navigate('/login'))}
          custom_txt={'로그인을 해야 글을 작성할 수 있어요. \n로그인 하러 가시겠어요?'}
          buttons={closeModal}
          type={'type_3 color_b'}
        />
      ) : (
        ''
      )}
      <span onClick={() => buyRequest(articleId)}>채팅요청</span>
    </>
  ) : (
    <span className="disable">채팅불가</span>
  );
};
const DetailNavigation = ({ isPurchasable, articleId, isLiked, isMyArticle, like, unLike, shareOpen, loginCheck }) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(0);
  const detailHeaderSetting = document.querySelector('.detail_header');
  const handleCloseModal = () => {
    setModal(0);
    detailHeaderSetting.style.zIndex = 2;
  };

  const sendMessage = async () => {
    try {
      const result = await buyRequestPOST(articleId);
      if (result.status === 201) {
        navigate(`/message/detail`, { state: { id: result.data.id } });
      }
    } catch (error) {
      if (error.response.data.message === '이미 채팅이 진행중 입니다.') {
        console.log('error', error);
        detailHeaderSetting.style.zIndex = 1;
        setModal(1);
      }
    }
  };
  const isLoginCheckFunction = (getEvnet) => {
    if (loginCheck) {
      getEvnet();
    } else {
      detailHeaderSetting.style.zIndex = 1;
      setModal(2);
    }
  };
  useEffect(() => {}, [isLiked]);

  return (
    <div className="detail_nav fixed flex">
      {isMyArticle ? (
        <div className="like my_article">
          <LikeBtn />
        </div>
      ) : (
        <div
          className={`like ${isLiked ? 'active' : ''}`}
          onClick={() => isLoginCheckFunction(!isLiked ? like : unLike)}
        >
          <LikeBtn />
        </div>
      )}
      <div className="right_btn_box flex flex_jc_sb flex_ai_c">
        <div className="share" onClick={() => shareOpen()}>
          <ShareBtn />
        </div>
        {isMyArticle ? (
          <div className={`request cursor_p false`}>
            {isPurchasable ? <span className="disable">채팅요청</span> : <span className="disable">채팅불가</span>}
          </div>
        ) : (
          <div className={`request cursor_p ${isPurchasable}`}>
            <RequestMessage
              isPurchasable={isPurchasable}
              buyRequest={() => isLoginCheckFunction(sendMessage)}
              modalStatus={modal}
              closeModal={handleCloseModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailNavigation;
