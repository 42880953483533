import { atom } from 'recoil';

export const MessageStats = atom({
  key: 'messageStatus',
  default: [{ nickname: '' }],
});

export const messageMenuState = atom({
  key: 'messageMenuStatus',
  default: false,
});
