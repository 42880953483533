// MODULE
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// HOOKS
import { memberPOST, myProfilePATCH, myProfileInfoGET } from '../../../api/member';
import { getMyPageName } from '../../../utils/locationCheck';
import { saveLoginData } from '../../../utils/saveData';
// RECOIL STATE
import { registerData } from '../../../state/registerState';
import { myLocationState } from '../../../state/userState';
// COMPONENT
import Map from '../../MapSelect';
import HistoryBack from '../../Common/HistoryBack';
import ModalFrame from '../../Modal/ModalFrame';
import LocationItem from '../../LocationItem/LocationItem';

const MyLocationSetting = () => {
  const regionOption = JSON.parse(localStorage.getItem('region'));
  const [mapModal, setMapModal] = useState(false);
  const [isNormal, setIsNormal] = useState(true);
  const [success, setSuccess] = useState(false);
  const [order, setOrder] = useState(null);

  const { state } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [myLocation, setMyLocation] = useRecoilState(myLocationState);
  const handleOpenLocationMap = (index) => {
    setOrder(index);
    setMapModal(true);
  };
  const handleCloseLocationMap = () => {
    setOrder(null);
    setMapModal(false);
  };

  useEffect(() => {
    if (regionOption) {
      setMyLocation(regionOption);
    }
  }, []);

  const handleDeleteLocation = (index) => {
    setMyLocation((prevMyLocation) => {
      if (index === 0) {
        return [
          {
            ...prevMyLocation[0],
            regionName: '',
            code: '',
          },
          { ...prevMyLocation[1] },
        ];
      } else {
        return [
          { ...prevMyLocation[0] },
          {
            ...prevMyLocation[1],
            regionName: '',
            code: '',
          },
        ];
      }
    });
  };

  const userProfileData = localStorage.getItem('userProfiles');
  const userProfiles = JSON.parse(userProfileData);
  // MYLOCATION PATCH
  const handleMyLocationPatch = async () => {
    const formData = new FormData();

    const json = JSON.stringify({
      firstRegionOfResidence: myLocation[0]?.regionName,
      firstRegionOfResidenceCode: myLocation[0]?.code,
      secondRegionOfResidence: myLocation[1]?.regionName,
      secondRegionOfResidenceCode: myLocation[1]?.code,
    });

    const blob = new Blob([json], { type: 'application/json' });
    formData.append('patchData', blob);
    const id = localStorage.getItem('memberId');
    try {
      const result = await myProfilePATCH(id, formData);
      if (result.status === 200) {
        localStorage.setItem('region', JSON.stringify(myLocation));
        setSuccess(true);
      } else {
        console.log('에러 : ', result.status);
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  // REGISTER SHOWUP
  const [getRegisterData, setGetRegisterData] = useRecoilState(registerData);

  const handleJoinShowup = async () => {
    const formData = new FormData();
    if (getRegisterData.thumb !== undefined) {
      formData.append('profileImage', getRegisterData.thumb);
    }

    const json = JSON.stringify({
      nickname: getRegisterData.nickname,
      phoneNumber: getRegisterData.phone,
      firstRegionOfResidence: myLocation[0]?.regionName,
      firstRegionOfResidenceCode: myLocation[0]?.code,
      secondRegionOfResidence: myLocation[1].regionName,
      secondRegionOfResidenceCode: myLocation[1]?.code,
    });
    const blob = new Blob([json], { type: 'application/json' });
    formData.append('postData', blob);

    try {
      const response = await memberPOST(formData);
      if (response.status === 201) {
        const needValue = '2,3,4,5,6,7,8,11,16';
        const loginResult = await myProfileInfoGET(response.data.id, needValue);
        const resultData = loginResult.data;
        saveLoginData(response.data.id, resultData);

        // localStorage.setItem('region', JSON.stringify(myLocation));
        // localStorage.setItem('memberId', response.data.id);
        // const needValue = '2,3,4,5,6,7,8,11,16';
        // const loginResult = await myProfileInfoGET(response.data.id, needValue);
        // const resultData = loginResult.data;

        // const userData = {
        //   img: resultData?.storedProfileImagePath === undefined ? null : resultData?.storedProfileImagePath,
        //   nickname: resultData?.nickname,
        //   phone: resultData?.phoneNumber,
        // };
        // localStorage.setItem(
        //   'userProfileImg',
        //   resultData?.storedProfileImagePath === undefined ? null : resultData?.storedProfileImagePath
        // );
        // localStorage.setItem('userProfiles', JSON.stringify(userData));
        // navigate('/home');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const urlCheck = pathname === '/regist-location';
    const dataCheck = getRegisterData.phone === null;
    if (urlCheck && dataCheck) {
      setIsNormal(false);
    } else {
      return;
    }
  }, [getRegisterData]);
  // REGISTER PROCESS
  const handleCloseModal = () => {
    window.location.href = '/home';
  };
  // PAGE PATHNAME CHECK
  const Type = getMyPageName(pathname) === 2;

  return (
    <>
      {!isNormal ? (
        <ModalFrame type={'type_1'} txt={1} confirm={handleCloseModal} />
      ) : success ? (
        <ModalFrame type={'type_1'} confirm={() => navigate('/mypage/main')} custom_txt={'내 지역이 변경되었습니다.'} />
      ) : (
        ''
      )}
      {mapModal ? <Map type={'location_setting'} close={handleCloseLocationMap} order={order} /> : ''}
      <div className={`my_location_setting inner_con ${state?.prevPage === undefined ? '' : 'regist'}`}>
        {state?.prevPage === undefined ? '' : <HistoryBack />}
        {Type ? '' : <div className="page_title flex flex_jc_s">내 지역 설정하기</div>}
        <div className="location_list">
          <div className="sub_tit">설정된 지역</div>
          <ul className="flex flex_jc_sb">
            <LocationItem
              pathname={pathname}
              openMap={handleOpenLocationMap}
              deleteItem={handleDeleteLocation}
              data={myLocation}
            />
          </ul>
        </div>
        <div className="location_alert">
          <ul>
            <li>내 지역은 나중에 선택할 수도 있습니다.</li>
            <li>내 지역을 설정하지 않아도 다른사람들의 글은 읽을 수 있어요. 대신 내가 직접 글은 작성할 수 없어요.</li>
            <li>지역은 최대 2군데를 지정할 수 있어요.</li>
          </ul>
        </div>
        <div className="btn_box">
          <div
            className="modify_btn button"
            onClick={state?.prevPage === undefined ? () => handleMyLocationPatch() : () => handleJoinShowup()}
          >
            {state?.prevPage === undefined ? '저장하기' : '쇼업시작'}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLocationSetting;
