// MODULE
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
// HOOKS
import { nicknameCheckGET } from '../../../api/member';
// RECOIL STATE
import { registerData } from '../../../state/registerState';
// COMPONENT
import HistoryBack from '../../../components/Common/HistoryBack';
import ModalFrame from '../../../components/Modal';
// JSON
import responseCheck from '../../../json/responseText.json';

const Nickname = () => {
  const [getRegisterData, setGetRegisterData] = useRecoilState(registerData);
  const [inputErr, setInputErr] = useState('');
  const [nicknameCheck, setNicknameCheck] = useState(false);
  const [checkMsg, setCheckMsg] = useState(null);
  const [alertModal, setAlertModal] = useState(false);
  const navigate = useNavigate();
  // PAGE INSERT OPTION
  useEffect(() => {
    if (getRegisterData.phone === '') {
      setAlertModal(true);
    }
  }, []);
  const onChangeNickname = (e) => {
    e.preventDefault();
    setGetRegisterData((prevData) => ({
      ...prevData,
      nickname: e.target.value,
    }));
  };
  const handleNicknameCheck = async () => {
    const textCheck = /^[a-zA-Z0-9\u3131-\uD79D]+$/;
    setInputErr('');
    if (textCheck.test(getRegisterData.nickname)) {
      try {
        setInputErr('');
        const response = await nicknameCheckGET(getRegisterData.nickname);
        setCheckMsg(response.data.message);
      } catch (error) {
        console.log(error);
      }
    } else {
      setInputErr('닉네임에 특수문자는 사용이 불가능해요.');
      setNicknameCheck(false);
    }
  };
  useEffect(() => {
    if (checkMsg === responseCheck.nicknameCheck[0].message) {
      setNicknameCheck(true);
    } else {
      setNicknameCheck(false);
    }
  }, [checkMsg]);
  const handleRegistNextStep = () => {
    if (getRegisterData.phone === '') {
      alert('잘못된 접근입니다.');
      window.location = 'https://showup.co.kr';
    } else {
      if (getRegisterData.nickname.length === 0) {
        document.getElementById('nickname').focus();
        setInputErr('');
      } else if (!nicknameCheck) {
        document.getElementById('nickname').focus();
        setInputErr('');
      } else {
        navigate('/regist-thumbnail', { state: { prevPage: 'regist' } });
      }
    }
  };

  const nicknameLength = Number(getRegisterData.nickname?.length);
  const point_txt = '다른 사람들에게 보여줄 닉네임을 설정해주세요.\n닉네임은 언제든지 바꿀 수 있습니다.';
  return (
    <>
      {alertModal ? <ModalFrame type={'type_1'} txt={1} confirm={() => (window.location.href = '/')} /> : ''}
      <div className="nickname_setting_section">
        <HistoryBack />
        <div className="page_title">프로필설정</div>
        <div className="point_txt">{point_txt}</div>

        <div className="txt_box">
          <div className="sub_tit">닉네임</div>
        </div>
        <div className="nickname_box input_box relative flex flex_dir_c">
          <label htmlFor="nickname" className="absolute"></label>
          <input
            type="text"
            id="nickname"
            value={getRegisterData.nickname}
            onChange={onChangeNickname}
            maxLength={10}
            placeholder="최대 10글자까지 입력"
          />
          <div className="btn_box input_button absolute">
            <div
              className={`button ${nicknameLength === 0 ? 'disable' : ''}`}
              onClick={nicknameLength < 0 ? null : () => handleNicknameCheck()}
            >
              중복체크
            </div>
          </div>
        </div>
        <div className="info_box flex flex_jc_sb">
          <ul className="nickname_info">
            <li>한글, 영어, 숫자만 사용할 수 있어요.</li>
            <li>최대 10자까지 입력 가능해요.</li>
          </ul>
          {inputErr === '' ? (
            <span
              className={`event_txt flex flex_jc_e ${
                checkMsg === responseCheck.nicknameCheck[0].message ? 'true' : 'false'
              }`}
            >
              {checkMsg}
            </span>
          ) : (
            <span className="event_txt flex flex_jc_e false">{inputErr}</span>
          )}
        </div>

        <div className="btn_box">
          <div
            className={`button ${nicknameLength === 0 ? 'disable' : ''}`}
            onClick={nicknameLength === 0 ? null : handleRegistNextStep}
          >
            확인
          </div>
        </div>
      </div>
    </>
  );
};

export default Nickname;
