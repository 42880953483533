// MODULE
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
// RECOIL STATE
import { searchResultPageState } from '../../../state/commonState';
// HOOK
import { setPrice } from '../../../utils/textEffect';
import { getRemainingPeriod, getElapsedTime } from '../../../utils/timeEffect';
// IMAGE
import { ReactComponent as MessageCounter } from '../../../assets/img/icon/Message_icon.svg';
import { ReactComponent as LikeCounter } from '../../../assets/img/icon/Like_icon.svg';
import { ReactComponent as NoResult } from '../../../assets/img/no_result.svg';
import { useEffect } from 'react';

const ResultView = ({ keyword, searchResult }) => {
  const navigate = useNavigate();
  const [ref, inView] = useInView();
  const [page, setPage] = useRecoilState(searchResultPageState);
  const test = (e) => {
    navigate(`/detail?info=${e}`);
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'unset';
  };

  useEffect(() => {
    if (inView) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  }, [inView]);
  console.log(page);
  console.log(searchResult);
  return (
    <div className="search_result">
      {searchResult.length === 0 ? (
        <div className="no_result_frame flex flex_dir_c flex_jc_c flex_ai_c">
          <NoResult />
          <div
            className="txt_section flex flex_dir_c flex_jc_c flex_ai_c
          "
          >
            <div className="keyword">'{keyword}'</div>
            <div>에 대한 검색 결과가 없습니다.</div>
            <div className="sub_txt_box flex flex_dir_c flex_jc_c flex_ai_c">
              <p>단어의 철자가 정확한지 확인해 주세요!</p>
              <p>마감일이 지난 게시물은 조회되지 않아요.</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="result_header flex flex_jc_s flex_ai_c">
            <div className="keyword_title flex flex_ai_c">
              <div>'{keyword}'</div>&nbsp;에 대한 검색 결과&nbsp;<div>{searchResult.length}개</div>
            </div>
          </div>
          <div className={`result_items ${isMobile ? '' : 'pc'}`}>
            <ul className="flex flex_jc_sb flex_wrap_wrap">
              {searchResult.map((item, index) => (
                <li key={index} onClick={() => test(item.id)}>
                  <div className="img_box flex flex_ai_c">
                    {item.storedProductImagePaths !== undefined ? (
                      item.storedProductImagePaths.length === 0 ? (
                        <img src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`} alt="" />
                      ) : (
                        <img src={`${process.env.REACT_APP_IMAGE_URL}${item.storedProductImagePaths[0]}`} alt="" />
                      )
                    ) : null}
                  </div>
                  <div className="title">{item.productName}</div>
                  <div className="counter_item absolute flex">
                    {item.buyRequestCount === 0 ? (
                      ''
                    ) : (
                      <div className="purchase_request flex">
                        <MessageCounter />
                        <span>{item.buyRequestCount}</span>
                      </div>
                    )}
                    {item.likeCount === 0 ? (
                      ''
                    ) : (
                      <div className="like flex">
                        <LikeCounter />
                        <span>{item.likeCount}</span>
                      </div>
                    )}
                  </div>
                  <div className="item_info relative">
                    <div className="header_info flex flex_jc_s flex_ai_c flex_wrap_wrap">
                      <div className="reg_dt"></div>
                      <div className="location flex">
                        <div className="area">{item.regionName3depth}</div>&nbsp;·&nbsp;
                        <div className="market">{item.placeName}</div>
                      </div>
                      &nbsp;·&nbsp;
                      {getElapsedTime(item.createdAt)}
                    </div>
                    <div className="body_info">
                      <div className="title">{item.title}</div>
                      <div className="price">{item.price === null ? '가격정보 없음' : setPrice(item.price) + '원'}</div>
                    </div>
                    <div className="end_dt absolute">{getRemainingPeriod(item.endDay)}</div>
                  </div>
                </li>
              ))}
              {searchResult.length > 19 ? <li ref={ref}></li> : ''}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ResultView;
