// MODULE
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';
// IMPORT
import 'swiper/css';
import 'swiper/css/pagination';
// COMPONENT
import OriginalImage from '../OriginalImage/OriginalImage';

const ThumbSlide = ({ imgs, isDayOver }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [originImg, setOriginImg] = useState(false);
  const [selectedImgSrc, setSelectedImgSrc] = useState('');

  const handleOpenOriginImg = (imgSrc) => {
    setOriginImg(true);
    setSelectedImgSrc(imgSrc);
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'hidden';
  };
  const handleCloseOriginImg = () => {
    setOriginImg(false);
    setSelectedImgSrc('');
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'unset';
  };
  const SlideOption = {
    effect: 'slide',
    navigation: false,
    autoplay: false,
    centeredSlides: false,
    speed: 1000,
    loop: false,
    pagination: true,
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    spaceBetween: 0,
    edgeSwipeThreshold: 40,
  };
  return (
    <>
      {originImg ? <OriginalImage imgSrc={selectedImgSrc} close={handleCloseOriginImg} /> : ''}
      {imgs !== undefined ? (
        imgs.length > 1 ? (
          <Swiper
            {...SlideOption}
            navigation={true}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
            className="img_slide"
          >
            {imgs.map((img, index) => (
              <SwiperSlide key={index}>
                <div className={`img_box ${isDayOver ? 'end_img' : ''}`} onClick={() => handleOpenOriginImg(img)}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}${decodeURIComponent(img)}`}
                    alt={decodeURIComponent(img)}
                    loading="lazy"
                    height={360}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={`img_box ${isDayOver ? 'end_img' : ''} solo_img`} onClick={() => handleOpenOriginImg(imgs)}>
            <img src={`${process.env.REACT_APP_IMAGE_URL}${imgs}`} alt="" loading="lazy" />
          </div>
        )
      ) : null}
    </>
  );
};

export default ThumbSlide;
