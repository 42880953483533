// MODULE
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// RECOIL STATE
import { urlCopyInfoState } from '../../state/userState';

const Meta = () => {
  const location = useLocation();
  const pageInfo = useRecoilValue(urlCopyInfoState);
  return (
    <Helmet>
      <meta name="author" content="" />
      <meta name="description" content="쇼핑스킬 레벨업! 쇼업" />
      <meta name="keywords" content="쇼업, showup, Showup, SHOWUP, ShowUp, show up " />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="" />
      <meta property="og:image" content={`${process.env.REACT_APP_IMAGE_URL}${pageInfo.image}`} />
      <meta property="og:url" content="" />
      <meta property="og:description" content={pageInfo.description} />
      <meta property="og:image:width" content="436" />
      <meta property="og:image:height" content="134" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="" />
      <meta name="twitter:description" content="쇼핑스킬 레벨업! 쇼업" />
      <meta name="twitter:image" content="" />

      {/* KAKAO SHARE */}
      <title>ShowUP</title>
    </Helmet>
  );
};

export default Meta;
