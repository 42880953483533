// MODULE
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import TermsOfUse from '../../components/TermsOfUse';

const RegisterTerms = () => {
  const navigate = useNavigate();
  const [Terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  // TERMS OF USE MODAL
  const handleOpenTermsOfUse = () => {
    setTerms(true);
  };
  const handleCloseTermsOfUse = () => {
    setTerms(false);
  };
  const handleChangeTermsOfUse = () => {
    setTermsCheck(!termsCheck);
  };
  // PRIVACY POLICY
  const handleOpenPrivacy = () => {
    setPrivacy(true);
  };
  const handleClosePrivacy = () => {
    setPrivacy(false);
  };
  const handleChangePrivacy = () => {
    setPrivacyCheck(!privacyCheck);
  };
  const comp_txt = '본인인증이 완료되었어요!\n프로필을 설정하러 가볼까요?';

  const isChecked = !termsCheck || !privacyCheck;
  return (
    <>
      {Terms ? <TermsOfUse close={handleCloseTermsOfUse} /> : ''}
      {privacy ? <PrivacyPolicy close={handleClosePrivacy} /> : ''}
      <div className="comp_splash">
        <HistoryBack />
        <div className="logo img_box">
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`}
            alt=""
            width={'70%'}
            style={{ marginTop: '10%' }}
          />
        </div>
        <div className="sub_txt flex flex_jc_c">{comp_txt}</div>
        <div className="terms_check">
          <div className="flex flex_jc_sb">
            <div className="flex flex_ai_c">
              <input type="checkbox" id="terms_use" checked={termsCheck} onChange={handleChangeTermsOfUse} />
              <label htmlFor="terms_use">[필수] 이용약관 동의하기</label>
            </div>
            <div className="view_terms" onClick={() => handleOpenTermsOfUse()}>
              보기
            </div>
          </div>
          <div className="flex flex_jc_sb">
            <div className="flex flex_ai_c">
              <input type="checkbox" id="privacy_policy" checked={privacyCheck} onChange={handleChangePrivacy} />
              <label htmlFor="privacy_policy">[필수] 개인정보처리방침 동의하기</label>
            </div>
            <div className="view_terms" onClick={() => handleOpenPrivacy()}>
              보기
            </div>
          </div>
        </div>
        <div className="btn_box">
          <div
            className={`button ${isChecked ? 'disable' : ''}`}
            onClick={() => (isChecked ? '' : navigate('/regist-nickname'))}
          >
            프로필 설정하기
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterTerms;
