// MODULE
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// HOOK
import { dreegoMemberIdGET, dreegoMemberJoinPOST } from '../../api/externalAPI';
// IMAGE
import HistoryBack from '../../components/Common/HistoryBack';
import TermsModal from './TermsModal/TermsModal';
import ModalFrame from '../../components/Modal';
const DreegoRegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // USESTATE
  const [termsModal, setTermsModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [termsType, setTermsType] = useState('');
  const [resId, setResId] = useState('');
  // PASSWORD
  const [resPw, setResPw] = useState('');
  const [resPwck, setResPwck] = useState('');
  // NAME
  const [resName, setResName] = useState('');
  // EMAIL
  const [resEmail, setResEmail] = useState('');
  const [resEmailDomain, setResEmailDomain] = useState('');
  const [emailReception, setEmailReception] = useState(false);
  // PHONE
  const [phoneNum1, setPhoneNum1] = useState('010');
  const [phoneNum2, setPhoneNum2] = useState('');
  const [phoneNum3, setPhoneNum3] = useState('');
  const [smsReception, setSmsReception] = useState(false);
  // tERms
  const [allTerms, setAllTerms] = useState(false);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [terms3, setTerms3] = useState(false);

  const [idCheck, setIdCheck] = useState(0);
  const [pwCheck, setPwCheck] = useState(0);
  const [pwckCheck, setPwckCheck] = useState(0);
  const [nameCheck, setNameCheck] = useState(0);
  const [emailCheck, setEmailCheck] = useState(0);
  const [phoneCheck, setPhoneCheck] = useState(0);
  // ONCHANGE
  const onChangeRegistId = (e) => {
    e.preventDefault();
    setResId(e.target.value);
  };
  const onChangeRegistPw = (e) => {
    e.preventDefault();
    setResPw(e.target.value);
  };
  const onChangeRegistPwck = (e) => {
    e.preventDefault();
    setResPwck(e.target.value);
  };
  const onChangeRegistName = (e) => {
    e.preventDefault();
    setResName(e.target.value);
  };
  const onChangeRegistEmailId = (e) => {
    e.preventDefault();
    setResEmail(e.target.value);
  };
  const onChangeRegistEmailDomain = (e) => {
    e.preventDefault();
    setResEmailDomain(e.target.value);
    checkUserEmailId();
  };
  const onChangeRegistPhoneNum1 = (e) => {
    e.preventDefault();
    setPhoneNum1(e.target.value);
  };
  const onChangeRegistPhoneNum2 = (e) => {
    e.preventDefault();
    let value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNum2(value);
  };
  const onChangeRegistPhoneNum3 = (e) => {
    e.preventDefault();
    let value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNum3(value);
  };
  // CHECK ID
  const checkDreegoId = async () => {
    try {
      const result = await dreegoMemberIdGET(resId);
      setIdCheck(result.data.status === null ? 3 : 2);
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserId = () => {
    if (resId.length < 5) {
      setIdCheck(1);
    } else {
      checkDreegoId();
    }
  };
  const checkPassword =
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\-+=<>?.,;:|\/\\[\]{}~`])[a-zA-Z0-9!@#$%^&*()\-+=<>?.,;:|\/\\[\]{}~`]{8,20}$/;
  const checkUserPw = () => {
    if (resPw.length === 0) {
      setPwCheck(1);
    } else if (resPw.length < 8) {
      setPwCheck(2);
    } else if (!checkPassword.test(resPw)) {
      setPwCheck(3);
    } else {
      setPwCheck(4);
    }
  };
  const checkUserPwck = () => {
    if (resPwck.length === 0) {
      setPwckCheck(1);
    } else if (resPw !== resPwck) {
      setPwckCheck(2);
    } else {
      setPwckCheck(3);
    }
  };
  const checkUserName = () => {
    if (resName.length === 0) {
      setNameCheck(1);
    } else if (resName.length < 2) {
      setNameCheck(2);
    } else {
      setNameCheck(3);
    }
  };
  const checkUserEmailId = () => {
    if (resEmail.length === 0) {
      setEmailCheck(1);
    } else if (resEmailDomain.length === 0) {
      setEmailCheck(1);
    } else {
      setEmailCheck(2);
    }
  };
  const checkUserPhone = () => {
    if (phoneNum2.length + phoneNum3.length < 7) {
      setPhoneCheck(1);
    } else {
      setPhoneCheck(0);
    }
  };
  const handleCheckAllTerms = () => {
    const newAllAccessChecked = !allTerms;
    setAllTerms(newAllAccessChecked);
    setTerms1(newAllAccessChecked);
    setTerms2(newAllAccessChecked);
    setTerms3(newAllAccessChecked);
  };
  const handleterms1Change = () => {
    setTerms1(!terms1);
  };

  const handleterms2Change = () => {
    setTerms2(!terms2);
  };

  const handleterms3Change = () => {
    setTerms3(!terms3);
  };
  const handleEmailReception = () => {
    setEmailReception(!emailReception);
  };
  const handleSmsReception = () => {
    setSmsReception(!smsReception);
  };
  const handleOpentermsModal = (e) => {
    setTermsModal(true);
    setTermsType(e);
  };
  useEffect(() => {}, []);
  if (phoneNum2.length === 4) {
    document.querySelector('#phoneNum3').focus();
  }
  // JOIN DREEGO
  const handleJoinDreego = async () => {
    const data = {
      memberId: resId,
      password: resPw,
      memberName: resName,
      mobileNo: phoneNum1 + phoneNum2 + phoneNum3,
      smsAgreed: smsReception,
      email: resEmail + '@' + resEmailDomain,
      directMailAgreed: emailReception,
      joinTermsAgreements: [terms1 ? 'USE' : '', terms2 ? 'PI_COLLECTION_AND_USE_REQUIRED' : ''],
    };
    if (!terms1 && !terms2 && !terms3) {
      alert('필수 항목에 대해 약관동의를 해주셔야 합니다.');
    } else {
      try {
        const result = await dreegoMemberJoinPOST(data);
        if (result.status === 200) {
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          console.log('error', error);
          setErrorModal(true);
          setErrorMsg(error.response.data.message);
        }
      }
    }
  };
  const url = state !== null ? state.url : null;
  return (
    <>
      {errorModal ? <ModalFrame type={'type_4'} confirm={() => setErrorModal(false)} custom_txt={errorMsg} /> : ''}
      {termsModal ? <TermsModal type={termsType} close={() => setTermsModal(false)} /> : ''}
      <div className="dreego_regist_section">
        <div className="header flex flex_ai_c">
          {url !== null ? <HistoryBack url={url} /> : <HistoryBack />}
          <div className="img_box">
            <img src={`${process.env.PUBLIC_URL}/assets/img/dreego_Logo.png`} alt="Dreego Logo" width={75} />
          </div>
        </div>
        <div className="form_box ">
          {/* ID */}
          <div className="id_box input_box relative flex flex_dir_c">
            <label htmlFor="userid">아아디</label>
            <input
              type="text"
              name="userid"
              id="userid"
              value={resId}
              onChange={onChangeRegistId}
              placeholder="아이디"
              onBlur={checkUserId}
            />
            <span className={`event_txt absolute ${idCheck === 3}`}>
              {idCheck === 1
                ? '아이디는 5자 이상 ~ 20자 이하여야 합니다.'
                : idCheck === 2
                ? '이미 사용중인 아이디입니다. 다른 아이디를 입력해주세요.'
                : idCheck === 3
                ? '사용 가능합니다.'
                : ''}
            </span>
          </div>
          {/* PASSWORD */}
          <div className="pw_box input_box relative flex flex_dir_c">
            <label htmlFor="pw">비밀번호</label>
            <input
              type="password"
              name="pw"
              id="pw"
              value={resPw}
              onChange={onChangeRegistPw}
              placeholder="비밀번호 (영문, 숫자, 특수문자 8-20자)"
              onBlur={checkUserPw}
            />
            <span className={`event_txt absolute ${pwCheck === 4}`}>
              {pwCheck === 1
                ? '비밀번호를 입력해주세요.'
                : pwCheck === 2
                ? '비밀번호 자릿수를 확인해주세요. (8자리~20자리)'
                : pwCheck === 3
                ? '비밀번호는 영문, 숫자, 특수문자를 2종류 이상 조합하여 10~20자로 입력해주세요.'
                : pwCheck === 4
                ? '사용 가능합니다.'
                : ''}
            </span>
          </div>
          {/* /PASSWORD CHECK */}
          <div className="pwck_box input_box relative flex flex_dir_c">
            <label htmlFor="pwck">비밀번호 재입력</label>
            <input
              type="password"
              name="pwck"
              id="pwck"
              value={resPwck}
              placeholder="비밀번호 재입력"
              onChange={onChangeRegistPwck}
              onBlur={checkUserPwck}
            />
            <span className={`event_txt absolute ${pwckCheck === 3}`}>
              {pwckCheck === 1
                ? '비밀번호를 입력해주세요.'
                : pwckCheck === 2
                ? '비밀번호와 비밀번호 확인 값이 일치하지 않습니다.'
                : pwckCheck === 3
                ? '비밀번호가 일치합니다.'
                : ''}
            </span>
          </div>
          {/* NAME */}
          <div className="name_box input_box relative flex flex_dir_c">
            <label htmlFor="id">이름</label>
            <input
              type="text"
              name="id"
              value={resName}
              placeholder="이름"
              onChange={onChangeRegistName}
              onBlur={checkUserName}
            />
            <span className={`event_txt absolute ${nameCheck === 3}`}>
              {nameCheck === 1 ? '이름을 입력해주세요.' : nameCheck === 2 ? '이름은 2글자 이상이어야 합니다.' : ''}
            </span>
          </div>
          {/* EMAIL */}
          <div className="email_box input_box relative flex flex_dir_c">
            <div className="label">이메일 주소</div>
            <div className="input_boxes flex flex_jc_sb flex_ai_c">
              <div className="email_id">
                <label htmlFor="id"></label>
                <input
                  type="text"
                  name="id"
                  value={resEmail}
                  onChange={onChangeRegistEmailId}
                  onBlur={checkUserEmailId}
                />
              </div>
              <div>@</div>
              <div className="domain">
                <label htmlFor="mail_domain"></label>
                <input
                  type="text"
                  name="mail_domain"
                  id="mail_domain"
                  value={resEmailDomain}
                  onChange={onChangeRegistEmailDomain}
                  onBlur={checkUserEmailId}
                />
              </div>
            </div>
            <div className="email_domain_select">
              <select name="email_domain" id="" onChange={(e) => onChangeRegistEmailDomain(e)} value={resEmailDomain}>
                <option value="">직접 입력</option>
                <option value="naver.com">naver.com</option>
                <option value="hanmail.net">hanmail.net</option>
                <option value="daum.net">daum.net</option>
                <option value="nate.com">nate.com</option>
                <option value="gmail.com">gmail.com</option>
              </select>
            </div>
            <div className="email_reception check_box flex flex_ai_c">
              <input
                type="checkbox"
                name="email_reception"
                id="email_reception"
                checked={emailReception}
                onChange={handleEmailReception}
              />
              <label htmlFor="email_reception">이메일 수신 동의</label>
            </div>
            <span className={`event_txt absolute ${emailCheck === 2}`}>
              {emailCheck === 1 ? '입력된 이메일은 잘못된 형식입니다.' : emailCheck === 2 ? '사용 가능합니다.' : ''}
            </span>
          </div>
          {/* PHONE */}
          <div className="phone_box input_box relative">
            <div className="label ">휴대폰 번호</div>
            <div className="input_boxes flex flex_jc_sb flex_ai_c">
              <div className="phoneNum1_select">
                <select name="phoneNum1" id="" onChange={(e) => onChangeRegistPhoneNum1(e)} value={phoneNum1}>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="016">016</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
              </div>
              <div>-</div>
              <div className="phoneNum2">
                <label htmlFor="phoneNum2"></label>
                <input
                  type="text"
                  name="phoneNum2"
                  value={phoneNum2}
                  onChange={onChangeRegistPhoneNum2}
                  maxLength={4}
                />
              </div>
              <div>-</div>
              <div className="phoneNum3">
                <label htmlFor="phoneNum3"></label>
                <input
                  type="text"
                  name="phoneNum3"
                  id="phoneNum3"
                  value={phoneNum3}
                  onChange={onChangeRegistPhoneNum3}
                  maxLength={4}
                  onBlur={checkUserPhone}
                />
              </div>
            </div>
            <div className="sms_reception check_box flex flex_ai_c">
              <input
                type="checkbox"
                name="sms_reception"
                id="sms_reception"
                checked={smsReception}
                onChange={handleSmsReception}
              />
              <label htmlFor="sms_reception">SMS 수신 동의</label>
            </div>
            <span className={`event_txt absolute ${phoneCheck === 0}`}>
              {phoneCheck === 1 ? '휴대폰 번호를 정확하게 입력해주세요.' : ''}
            </span>
          </div>
          {/* TERMS */}
          <div className="terms_box ">
            <div className="label ">약관동의</div>
            <div className="check_box">
              <div className="all_access_box relative">
                <input
                  type="checkbox"
                  name="all_access"
                  id="all_access"
                  checked={allTerms}
                  onChange={handleCheckAllTerms}
                />
                <label htmlFor="all_access" className="absolute flex flex_jc_c flex_ai_c">
                  아래 약관에 모두 동의합니다.
                </label>
              </div>
              <ul className="terms_check_list">
                <li className="relative flex flex_jc_sb flex_ai_c">
                  <div className="terms1 check_box flex flex_ai_c">
                    <input type="checkbox" name="terms1" id="terms1" checked={terms1} onChange={handleterms1Change} />
                    <label htmlFor="terms1">[필수] 이용약관</label>
                  </div>
                  <div className="view_terms" onClick={() => handleOpentermsModal('USE')}>
                    보기
                  </div>
                </li>
                <li className="relative flex flex_jc_sb flex_ai_c">
                  <div className="terms2 check_box flex flex_ai_c">
                    <input type="checkbox" name="terms2" id="terms2" checked={terms2} onChange={handleterms2Change} />
                    <label htmlFor="terms2">[필수] 개인정보 수집 / 이용동의</label>
                  </div>
                  <div className="view_terms" onClick={() => handleOpentermsModal('PI_COLLECTION_AND_USE_REQUIRED')}>
                    보기
                  </div>
                </li>
                <li className="relative flex flex_jc_sb flex_ai_c">
                  <div className="terms3 check_box flex flex_ai_c">
                    <input type="checkbox" name="terms3" id="terms3" checked={terms3} onChange={handleterms3Change} />
                    <label htmlFor="terms3">[필수] 만 14세 이상입니다</label>
                  </div>
                </li>
                <li className="txt relative">
                  주문, 결제, 고객 상담 등 원활한 정보 제공을 위해 이메일 주소 및 휴대폰 번호는 정확히 기입하셔야
                  합니다.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="btn_box">
          <div className="button" onClick={() => handleJoinDreego()}>
            회원가입
          </div>
        </div>
      </div>
    </>
  );
};

export default DreegoRegister;
