// MODULE
import { useEffect, useState } from 'react';
// HOOK
import { dreegoTermsUseGET } from '../../../api/externalAPI';

const TermsModal = ({ type, close }) => {
  const [termsTxt, setTermsTxt] = useState('');
  const getTerms = async () => {
    try {
      const result = await dreegoTermsUseGET(type);
      console.log(result);
      setTermsTxt(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTerms();
  }, []);

  return (
    <>
      <div className="modal_bg"></div>
      <div className="terms_modal fixed">
        <div className="modal_header flex flex_jc_sb flex_ai_c">
          <div className="title">{type === 'USE' ? '[필수] 이용약관' : '[필수] 개인정보 수집 / 이용동의'}</div>
          <div className="close_btn relative" onClick={close}>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          className="contents"
          dangerouslySetInnerHTML={{
            __html:
              termsTxt.use !== undefined ? termsTxt.use?.contents : termsTxt.pi_collection_and_use_required?.contents,
          }}
        />
      </div>
    </>
  );
};

export default TermsModal;
