// MODULE
import { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// UTIL
import { rememberPrevPage } from '../../../utils/common';
import { setCookie } from '../../../utils/cookie';
// HOOKS
import { myProfileInfoGET } from '../../../api/member';
// RECOIL STATE
import { messageAlarmState, accessTokenCodeState } from '../../../state/userState';
// COMPONENT
import ProfileImage from '../../ProfileImage/ProfileImage';
// JSON
import MenuText from '../../../json/menuText.json';
import MannerScore from '../../MannerScore';

const Menu = () => {
  const outletData = useOutletContext();

  const navigate = useNavigate();
  const [msgAlarm, setMsgAlarm] = useRecoilState(messageAlarmState);
  const [errCode, setErrCode] = useRecoilState(accessTokenCodeState);
  const [userInfo, setUserInfo] = useState(null);
  const [getScore, setGetScore] = useState(0);

  const getProfile = async () => {
    const needValue = '2,3,4,5,6,7,8,11,16';
    const id = localStorage.getItem('memberId');
    try {
      const response = await myProfileInfoGET(id, needValue);
      const resultData = response.data;
      setUserInfo(resultData);
      setGetScore(response?.data.mannersScore);

      if (resultData.hasNotification !== undefined) {
        setMsgAlarm(resultData.hasNotification);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        setErrCode(error.response.status);
      } else {
        console.error(error);
      }
    }
  };

  // useEffect(() => {
  //   outletData !== undefined && getProfile();
  // }, [errCode]);

  // useEffect(() => {}, [userInfo]);
  useLayoutEffect(() => {
    outletData !== undefined && getProfile();
  }, []);

  const maxScore = 500;
  useEffect(() => {
    const handleKeyPress = (event) => {
      // 조합 키 체크 (Ctrl + M)
      if (event.ctrlKey && event.key === 'm') {
        alert('짜란');
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return (
    <>
      <div className="default_section">
        <div className="profile flex_dir_c">
          {outletData?.loginCheck && (
            <>
              <div className="img_box">
                <ProfileImage
                  src={
                    userInfo?.storedProfileImagePath === undefined
                      ? 'default'
                      : `${process.env.REACT_APP_IMAGE_URL}${userInfo.storedProfileImagePath}`
                  }
                  alt={userInfo?.nickname}
                  size={100}
                />
              </div>
              <div className="txt_box flex flex_jc_c">{userInfo?.nickname} 님</div>
              <div>{userInfo !== null ? <MannerScore propsScore={Math.floor((getScore / maxScore) * 100)} /> : ''}</div>
            </>
          )}
        </div>
      </div>
      <div className="menu_list">
        <ul>
          {outletData?.loginCheck &&
            MenuText.menuList_1.map((item, index) => (
              <li key={index}>
                <Link to={item.url} state={{ userInfo: userInfo }} className="flex flex_jc_sb flex_ai_c">
                  <div className="txt">{item.name}</div>
                  <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                </Link>
              </li>
            ))}
        </ul>
        <ul>
          {MenuText.menuList_2.map((item, index) => (
            <li key={index}>
              <Link to={item.url} className="flex flex_jc_sb flex_ai_c">
                <div className="txt">{item.name}</div>
                <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
              </Link>
            </li>
          ))}
          {outletData === undefined && (
            <li>
              <button
                className="flex flex_jc_sb flex_ai_c"
                onClick={() => rememberPrevPage(window.location.href, navigate('/login'))}
              >
                <div className="txt">로그인 하러가기</div>
                <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
              </button>
            </li>
          )}
          {localStorage.getItem('memberId') === '10001703' && (
            <li>
              <Link to="/custom_test_page">테스트 화면 접속</Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Menu;
