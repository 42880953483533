// MODULE
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// HOOKS
import { dreegoUserInfoGET } from '../../api/externalAPI';
import { pointConversionPATCH, myProfileInfoGET } from '../../api/member';
import { setPrice } from '../../utils/textEffect';
// COMPONENT
import ModalFrame from '../Modal';

const PointConversion = () => {
  const userPoint = localStorage.getItem('point');

  const { state } = useLocation();
  const navigate = useNavigate();
  const [errModal, setErrModal] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [dreegoInfo, setDreegoInfo] = useState(null);
  const [conversionPoint, setConversionPoint] = useState('');
  const [errMsg, setErrMsg] = useState(0);
  const [isConversion, setIsConversion] = useState(false);
  const [pointInfo, setPointInfo] = useState(null);

  const today = new Date();
  const postStartDay = today.toISOString().slice(0, 10);

  const oneMonthDay = new Date(today);
  oneMonthDay.setDate(today.getDate() - 30);

  const [endDt, setEndDt] = useState(oneMonthDay);
  const postEndDay = endDt.toISOString().slice(0, 10);

  const showupPhone = JSON.parse(localStorage.getItem('userProfiles')).phone;
  // ONCHANGE
  const onChangeConversionPoint = (e) => {
    e.preventDefault();
    let value = e.target.value.replace(/[^0-9]/g, '');
    setConversionPoint(value);
  };
  const getPointInfo = async () => {
    const id = localStorage.getItem('memberId');
    const needValue = '3,4,9';
    try {
      const result = await myProfileInfoGET(id, needValue);
      setPointInfo(result.data);
      try {
        const response = await dreegoUserInfoGET(state.userInfo.accessToken);
        if (result.data.phoneNumber !== response.data.mobileNo) {
          setErrModal(2);
        } else {
          setDreegoInfo(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // GET DREEGO INFO
  const dreegoUserInfo = async () => {
    try {
      const response = await dreegoUserInfoGET(state.userInfo.accessToken);
      console.log(response);
      if (pointInfo.phoneNumber !== response.data.mobileNo) {
        setErrModal(2);
      } else {
        setDreegoInfo(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (state === null) {
      setErrModal(1);
    } else {
      getPointInfo();
    }
  }, []);
  const setPoint = Number(conversionPoint);
  const inputPointCheck = () => {
    if (setPoint > userPoint) {
      setErrMsg(1);
      setConversionPoint(userPoint);
      setIsConversion(true);
    } else if (setPoint < 499) {
      setErrMsg(2);
      setIsConversion(false);
    } else {
      setErrMsg(0);
      setIsConversion(true);
    }
  };
  // CONVERSION POINT
  const handlePointConversion = async () => {
    if (setPoint < 499 || errModal === 2) {
      setErrModal(1);
    } else {
      try {
        const result = await pointConversionPATCH(dreegoInfo.memberNo, dreegoInfo.memberId, Number(conversionPoint));
        if (result.status === 200) {
          setSuccessModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handlePointConversionModalClose = () => {
    getPointInfo();
    setSuccessModal(false);
    conversionPoint('');
  };
  return (
    <>
      {errModal === 1 ? (
        <ModalFrame txt={1} confirm={() => navigate(-1)} type={'type_1'} />
      ) : errModal === 2 ? (
        <ModalFrame
          custom_txt={'드리고에 일치하는 회원정보가 없습니다. 지금 바로 회원가입을 하시겠습니까?'}
          confirm={() => navigate('/dreego-regist', { state: { url: '/mypage/point' } })}
          buttons={() => navigate(-1)}
          type={'type_3'}
        />
      ) : (
        ''
      )}
      {successModal ? (
        <ModalFrame
          confirm={() => handlePointConversionModalClose()}
          custom_txt={'포인트 전환에 성공했습니다.'}
          type={'type_1'}
        />
      ) : (
        ''
      )}
      <div className="point_section">
        <div className="point_conversion_info">
          <p>
            <span>{dreegoInfo?.memberName}</span>님
          </p>
          <p>
            전환 가능한 <span>포인트는</span>
          </p>
          <p>
            <span className="point">
              {setPrice(pointInfo?.point)}
              포인트
            </span>{' '}
            입니다.
          </p>
        </div>

        <div className="insert_point input_box relative flex flex_dir_c">
          <label htmlFor="point">전환 요청 포인트</label>
          <input
            type="text"
            name="point"
            id="point"
            placeholder="최소 500포인트 부터 전환이 가능합니다."
            value={conversionPoint}
            onChange={onChangeConversionPoint}
            onBlur={inputPointCheck}
          />
          <span className={`event_txt absolute ${errMsg === 0}`}>
            {errMsg === 1
              ? `현재 전환가능한 최대 포인트는 ${setPrice(
                  // state.totalPoint
                  userPoint
                )} 입니다.`
              : errMsg === 2
              ? '포인트 전환은 500 포인트 부터 가능합니다.'
              : ''}
          </span>
        </div>

        <div className="info_box flex flex_jc_sb">
          <ul className="conversion_info">
            <li className="title">※주의사항</li>
            <li>-포인트 전환 신청 즉시 드리고 포인트로 전환됩니다.</li>
            <li>-전환된 포인트의 유효기간은 드리고의 포인트 정책에 따라갑니다.</li>
            <li>-드리고로 전환된 포인트는 다시 쇼업으로 재적립 할 수 없습니다.</li>
            <li>-포인트 전환은 최소 500포인트 부터 1포인트 단위로 전환이 가능합니다.</li>
          </ul>
        </div>
        <div className="btn_box">
          <div
            className={`button ${isConversion ? '' : 'disable'}`}
            onClick={() => (setPoint < 499 || errModal === 1 ? '' : handlePointConversion())}
          >
            전환하기
          </div>
        </div>
      </div>
    </>
  );
};

export default PointConversion;
