// MODULE

// HOOK
import { pointInfoGET } from '../../api/member';

const PointLogSearch = () => {
  return (
    <>
      <div></div>
    </>
  );
};

export default PointLogSearch;
