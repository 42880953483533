const ModalFrame = ({ txt, buttons, confirm, height, type, custom_txt, confirm_txt, buttons_txt }) => {
  const modalText = [
    {
      id: 1,
      txt: '잘못된 접근입니다.',
    },
    {
      id: 2,
      txt: '관리자에게 문의해주세요.',
    },
    {
      id: 3,
      txt: '로그인에 실패했습니다. ID 또는 Password를 확인해 주세요.',
    },
    {
      id: 4,
      txt: '서버접속에 실패했습니다. 관리자에게 문의해주세요.',
    },
  ];
  const resultTxt = modalText.filter((item) => item.id === txt);
  const innerWidth = window.innerWidth;

  const frameStyle = { height: height, top: `calc((100% - ${height})/2)`, left: `calc((${innerWidth}/2)-40%)` };
  return (
    <>
      <div className="modal_bg"></div>
      <div className={`modal_frame ${type}`} style={frameStyle}>
        <div className="modal_header">
          <div className="close_btn">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="modal_content">
          {custom_txt === 'account_cancel' ? (
            <>
              <div className="account_header">회원 탈퇴</div>
              <div className="account_body">
                <p>회원 탈퇴 시 모든 활동 정보와 보유 중인 포인트가 삭제돼요.</p>
              </div>
              <div className="account_footer">정말로 탈퇴하시겠어요?</div>
            </>
          ) : custom_txt === 'user_report' ? (
            <>
              <div className="report_header">상대방을 신고하시겠어요?</div>
              <div>허위 신고일 경우 운영자에 의해 제재 받을 수 있어요.</div>
            </>
          ) : (
            <div>{resultTxt[0] !== undefined ? resultTxt[0].txt : custom_txt}</div>
          )}
        </div>
        <div className={`btn_box ${buttons !== undefined ? 'flex flex_jc_sb' : ''}`}>
          {buttons !== undefined ? (
            <>
              <div className="button" onClick={confirm}>
                {confirm_txt !== undefined ? confirm_txt : '확인'}
              </div>
              <div className="button" onClick={buttons}>
                {buttons_txt !== undefined ? buttons_txt : '취소'}
              </div>
            </>
          ) : (
            <div className="button" onClick={confirm}>
              {confirm_txt !== undefined ? confirm_txt : '확인'}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalFrame;
