// MODULE
import { useEffect, useState } from 'react';
// HOOK
import { userBlockedPATCH, blockedUserInfoGET } from '../../api/member';
// COMPONENT
import Skeleton from '../Skeleton';
import ProfileImage from '../ProfileImage/ProfileImage';
import ModalFrame from '../Modal';
// IMAGE
import Default_Img from '../../assets/img/default_profile.png';

const BlockedListItem = ({ data, onChildEvent }) => {
  const [enableModal, setEnableModal] = useState(0);
  const [blockedUser, setBlockedUser] = useState(null);
  const [dataLength, setDataLength] = useState(0);

  const handleEnableUserBlocked = (index) => {
    setEnableModal(1);
    handleConformUserBlocked(index);
  };
  const handleConformUserBlocked = async (index) => {
    const formData = new FormData();
    const json = JSON.stringify({
      blockedMemberId: Number(index),
    });
    const blob = new Blob([json], { type: 'application/json' });
    formData.append('patchData', blob);
    const id = localStorage.getItem('memberId');
    try {
      const response = await userBlockedPATCH(id, formData);
      if (response.status === 200) {
        setDataLength(response.data.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // BLOCKED USER INF
  const getUserInfo = async () => {
    try {
      const response = await blockedUserInfoGET(data);
      setBlockedUser(response.data);
    } catch (error) {
      console.log('error\n', error);
    }
  };
  // ENABLE USER BLOCKED
  const handleEnableUser = () => {
    setEnableModal(0);
    onChildEvent(dataLength);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      {blockedUser !== null
        ? blockedUser.map((user) => (
            <li key={user.id} className="flex flex_jc_sb flex_ai_c">
              {enableModal === 1 ? (
                <ModalFrame
                  custom_txt={`${user.nickname} 님 차단이 해제되었어요.`}
                  type={'type_3'}
                  confirm={() => handleEnableUser()}
                />
              ) : (
                ''
              )}
              <div className="user_info flex flex_ai_c">
                <div className="img_box profile">
                  <ProfileImage
                    src={
                      user?.profile === null
                        ? Default_Img
                        : `${process.env.REACT_APP_IMAGE_URL}${user.storedProfileImagePath}`
                    }
                    alt={user.nickname}
                    size={45}
                  />
                </div>
                <div className="nickname">
                  {user.nickname === undefined ? <Skeleton itemWid={70} itemHei={16} /> : user.nickname}
                </div>
              </div>
              <div
                className="enable_user_btn flex flex_jc_c flex_ai_c"
                onClick={() => handleEnableUserBlocked(user.id)}
              >
                차단해제
              </div>
            </li>
          ))
        : ''}
    </>
  );
};

export default BlockedListItem;
