// MODULE
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// HOOKS
import { buyRequestUserGET } from '../../api/buyRequest';
import { sendMessagePOST } from '../../api/message';
import { getRemainingPeriod } from '../../utils/timeEffect';
import { setPrice } from '../../utils/textEffect';
// RECOIL STATE
import { messageUserState, messageAlarmState } from '../../state/userState';
import { messageMenuState } from '../../state/messageState';
// COMPONENT
import Skeleton from '../../components/Skeleton';
// IMAGE
import MessageView from '../../components/MessageView';
import ToastBottomMenu from '../../components/ToastBottomMenu/ToastBottomMenu';
const MessageDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [itemInfo, setItemInfo] = useState('');
  const [writeMessage, setWriteMessage] = useState('');
  const [, setUserNickname] = useRecoilState(messageUserState);
  const [, setMsgAlarm] = useRecoilState(messageAlarmState);
  const [messageMenu, setMessageMenu] = useRecoilState(messageMenuState);
  const [requesterId, setRequesterId] = useState(0);
  const [requestId, setRequestId] = useState(null);
  // ONCHANGE WRITE MESSAGE
  const onChangeWriteMessage = (e) => {
    if (writeMessage.length === 0) {
      document.querySelector('#write_message').focus();
    } else {
      e.preventDefault();
      setWriteMessage(e.target.value);
    }
    e.preventDefault();
    setWriteMessage(e.target.value);
  };
  const getMessageContent = async () => {
    try {
      const response = await buyRequestUserGET(state.id);
      setMessage(response.data.messages);
      setItemInfo(response.data);
      setUserNickname(response.data.partnerNickname);
      setRequesterId(response.data.requesterId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMessageContent();
    setMsgAlarm(null);
    setRequestId(state.id);
  }, []);

  const sendMessage = async () => {
    if (writeMessage.length === 0) {
      const inputArea = document.getElementById('write_message');
      inputArea.focus();
    } else {
      try {
        const response = await sendMessagePOST(writeMessage, state.id);
        getMessageContent();
        setMessage([...message, response.data]);
        setWriteMessage('');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseMenu = () => {
    setMessageMenu(false);
  };
  const myMemberId = Number(localStorage.getItem('memberId'));
  useEffect(() => {}, [itemInfo.isCompleted]);
  return (
    <>
      <div className="product_info flex flex_ai_c">
        <div className="img_box flex flex_ai_c" onClick={() => navigate(`/detail?info=${state.articleId}`)}>
          {itemInfo?.storedProductImagePath === undefined ? (
            <img src={process.env.PUBLIC_URL + '/assets/img/logo_c.png'} alt="" loading="lazy" />
          ) : (
            <img src={`${process.env.REACT_APP_IMAGE_URL}${itemInfo?.storedProductImagePath}`} alt="" loading="lazy" />
          )}
        </div>
        <div className="article_info ">
          <div className="date">
            {itemInfo.articleEndDay === undefined ? (
              <Skeleton itemWid={60} itemHei={14} />
            ) : getRemainingPeriod(itemInfo?.articleEndDay) === 300 ? (
              itemInfo.articleEndDay + '에 종료됨'
            ) : (
              getRemainingPeriod(itemInfo.articleEndDay)
            )}
          </div>
          <div className="title">
            {itemInfo.productName === undefined ? <Skeleton itemWid={30} itemHei={14} /> : itemInfo?.productName}
          </div>
          <div className="price">
            {itemInfo.price === undefined ? <Skeleton itemWid={60} itemHei={16} /> : setPrice(itemInfo?.price) + '원'}
          </div>
          <div className="trade_status flex">
            {itemInfo?.isCompleted === undefined ? (
              <Skeleton itemWid={53.5} itemHei={21} />
            ) : (
              <div className={`status `}>{itemInfo.isCompleted ? '거래완료' : '거래중'}</div>
            )}
            {itemInfo?.status !== '구매 요청' ? (
              ''
            ) : requesterId === myMemberId ? (
              <div className="trade_review">후기 남기기</div>
            ) : (
              <div className="trade_review">후기 확인하기</div>
            )}
          </div>
        </div>
      </div>
      <div className="view_section">
        <MessageView message={message} />
      </div>
      <div className="send_section fixed flex flex_jc_sb flex_ai_c">
        <div className="icon_box"></div>
        <div className="message_input_box input_box">
          <label htmlFor="write_message" className="absolute"></label>
          <textarea
            id="write_message"
            type="text"
            value={writeMessage}
            onChange={onChangeWriteMessage}
            placeholder="메세지 보내기"
          ></textarea>
          <div className="btn_box input_button absolute">
            <div className={`button ${writeMessage.length >= 2 ? '' : 'disable'}`} onClick={() => sendMessage()}>
              전송
            </div>
          </div>
        </div>
      </div>
      <ToastBottomMenu
        isCompleted={itemInfo.isCompleted}
        menuState={messageMenu}
        itemInfo={itemInfo}
        currentMenu={requesterId === myMemberId}
        close={handleCloseMenu}
        pages={'message'}
        requesterId={requesterId}
        requestId={requestId}
        id={state.id}
        articleId={itemInfo.articleId}
        memberId={itemInfo.partnerId}
        memberNickname={itemInfo.partnerNickname}
      />
    </>
  );
};

export default MessageDetail;
