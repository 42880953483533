import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// 경로/usr/share/nginx/html
const Test = () => {
  const navigate = useNavigate();
  useEffect(() => {
    kakaoChatbot();
  }, []);
  const kakaoChatbot = () => {
    const script = document.createElement('script');
    script.async = true;
    try {
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          kakao.init(`${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}`);
        }
      }

      window.Kakao.Channel.createChatButton({
        container: '#kakao-talk-channel-chat-button',
        channelPublicId: '3129935',
        title: 'consult',
        size: 'small',
        color: 'yellow',
        shape: 'pc',
        supportMultipleDensities: true,
      });
      document.body.appendChild(script);
      document.body.removeChild(script);
    } catch (err) {}
  };
  const byapps_is_api_enable = function () {
    return !!navigator.userAgent.match('Byapps');
  };

  const byapps_api_call = function (a, s) {
    if (byapps_is_api_enable()) {
      if (window.byappsJSI && window.byappsJSI.callAPI) {
        window.byappsJSI.callAPI(a, s);
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.byappsJSI) {
        let e = a;
        s && (e += '?' + s);
        window.webkit.messageHandlers.byappsJSI.postMessage(e);
      }
    }
  };
  const testButton = () => {
    alert(byapps_api_call('isBottomMenuOn', 'callback_byapps_bottom_menu'));
  };
  const byappsSetting = () => {
    byapps_api_call('showSettings');
  };

  const byappsOpen = () => {
    byapps_api_call('window', 'https://showup.co.kr/home');
  };

  return (
    <>
      <button onClick={() => navigate(-1)} style={{ padding: 20 }}>
        뒤로가기
      </button>
      <hr />
      <div>
        {/* <Link
          to="
http://pf.kakao.com/_xorxkxbG"
        >
          쇼업 플친 이동
        </Link> */}
      </div>
      <div id="kakao-talk-channel-chat-button"></div>
      <hr />
      <button onClick={() => testButton()} style={{ padding: 20 }}>
        바이앱스 하단탭메뉴 콜백 테스트
      </button>
      <hr />
      <button onClick={() => byappsSetting()} style={{ padding: 20 }}>
        바이앱스 설정창 : byappsSetting()
      </button>
      <hr />
      <button onClick={() => byappsOpen()} style={{ padding: 20 }}>
        바이앱스 열어보기 : byappsOpen()
      </button>
      <button>
        <Link to="https://showup.co.kr:8080/docs/showup-api-specification.html" target="_blank">
          REST DOCS 이동
        </Link>
      </button>
      <hr />
    </>
  );
};

export default Test;
