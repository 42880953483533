// MODULE
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
// UTIL
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack/HistoryBack';
import NoticeListItem from '../../components/NoticeListItem';
// HOOK
import { noticeListGET } from '../../api/notice';

const NoticeList = () => {
  const [listData, setListData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const getList = async () => {
    try {
      const result = await noticeListGET(page);
      setListData(result.data.noticeData);
      setTotalCount(result.data.totalCount);
    } catch (error) {
      console.log(error);
    }
  };
  const [, setItemOffset] = useState(0);
  const pageCount = Math.ceil(totalCount / 20);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * totalCount) % 20;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  useEffect(() => {
    getList();
  }, [page]);

  return (
    <div className="notice">
      <div className="notice_header flex flex_jc_sb flex_ai_c">
        <HistoryBack />
        <div className="page_title  flex flex_jc_c">공지사항</div>
      </div>
      <div className="flex flex_dir_c">
        <ul>
          <NoticeListItem data={listData} index={listData?.id} />
        </ul>
        <ReactPaginate
          breakLabel="..."
          nextLabel="다음"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="이전"
          renderOnZeroPageCount={null}
          containerClassName={'pagination flex flex_jc_c flex_jc_db flex_ai_c'}
        />
      </div>
    </div>
  );
};

export default NoticeList;
