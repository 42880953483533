// MODULE
import { useEffect, useState } from 'react';
import { Outlet, useLocation, ScrollRestoration } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
// HOOKS
import { getLocation, byappsMenuSet } from '../../utils/locationCheck';
// RECOIL STATE
import { setByAppsBottomNavigationState, isLoginState } from '../../state/commonState';
// COMPONENT
import Navigation from '../Navigation';
import Meta from '../Meta';
import { getCookie } from '../../utils/cookie';

// Add the byapps functions
const byapps_is_api_enable = function () {
  return !!navigator.userAgent.match('Byapps');
};

const byapps_api_call = function (a, s) {
  if (byapps_is_api_enable()) {
    if (window.byappsJSI && window.byappsJSI.callAPI) {
      window.byappsJSI.callAPI(a, s);
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.byappsJSI) {
      let e = a;
      s && (e += '?' + s);
      window.webkit.messageHandlers.byappsJSI.postMessage(e);
    }
  }
};

const Layout = () => {
  const location = useLocation();
  const mainPage = location.pathname.includes('home');
  const [isApp, setIsApp] = useState(false);

  const bottomCheck = useRecoilValue(setByAppsBottomNavigationState);
  const [loginCheck, iseLoginCheck] = useRecoilState(isLoginState);
  const [appCheck, setAppCheck] = useRecoilState(setByAppsBottomNavigationState);

  const isLoginPage = window.location.pathname === '/login';

  const kakaoChatbot = () => {
    const script = document.createElement('script');
    script.async = true;
    try {
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          kakao.init(`${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}`);
        }
      }

      window.Kakao.Channel.createChatButton({
        container: '#kakao-talk-channel-chat-button',
        channelPublicId: '3129935',
        title: 'consult',
        size: 'small',
        color: 'yellow',
        shape: 'pc',
        supportMultipleDensities: true,
      });
      document.body.appendChild(script);
      document.body.removeChild(script);
    } catch (err) {}
  };

  useEffect(() => {
    iseLoginCheck(getCookie('isLogin') !== undefined ? getCookie('isLogin') : false);
    // kakaoChatbot();
  }, []);
  useEffect(() => {
    if (navigator.userAgent.match('Byapps')) {
      setIsApp(true);
      const webNav = document.querySelector('.bottom-nav');
      if (webNav !== null) {
        webNav.style.display = 'none';
      }
    }
    setAppCheck(byappsMenuSet(window.location.pathname));
  }, [bottomCheck, window.location.pathname]);
  useEffect(() => {
    window.history.scrollRestoration = 'auto';
  }, [window.history.state.idx]);
  return (
    <>
      <Meta />
      <div className={`showup_frame ${isLoginPage ? 'gradient' : ''}`}>
        {/* <div id="kakao-talk-channel-chat-button"></div> */}
        <ScrollRestoration
          getKey={(location, matches) => {
            const paths = ['/home', '/message/list', '/mypage/share-list'];
            return paths.includes(location.pathname) ? location.pathname : location.key;
          }}
        />
        <div className="con">
          <Outlet context={{ loginCheck }} />
          {getLocation() === 1 ? <Navigation loginCheck={loginCheck} /> : null}
        </div>
      </div>
    </>
  );
};

export default Layout;
