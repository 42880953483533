import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';

import reportWebVitals from './reportWebVitals';

import './assets/style/_index.css';

import DataRouter from './router/routers';
// ADD

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <RecoilRoot>
      <HelmetProvider>
        <RouterProvider router={DataRouter} />
      </HelmetProvider>
    </RecoilRoot>
  </CookiesProvider>
);

reportWebVitals();
