// MODULE
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// HOOK
import { kakaoLoginGET } from '../../api/auth';
import { myProfileInfoGET } from '../../api/member';
// RECOIL STATE
import { registerData } from '../../state/registerState';
import CertComplete from '../CertComplete';
import { saveLoginData } from '../../utils/saveData';

const KakaoLoginOauth = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const [certState, setCertState] = useState(false);
  const [, setGetRegisData] = useRecoilState(registerData);

  const code = new URL(window.location.href).searchParams.get('code');
  const getLogin = async () => {
    try {
      const response = await kakaoLoginGET(code);
      setResponse(response.data);
      console.log('카카오 테스트', response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserInfo = async () => {
    const id = localStorage.getItem('memberId');
    const needValue = '1,5,6,7,8,14';
    try {
      const response = await myProfileInfoGET(id, needValue);
      if (response.status === 200) {
        saveLoginData(id, response.data);
        navigate('/home');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (code !== '') {
      getLogin();
    }
  }, []);
  useEffect(() => {
    if (response?.message !== undefined) {
      getUserInfo();
    } else if (response?.phoneNumber.length > 10) {
      setGetRegisData({ phone: response?.phoneNumber });
      setCertState(true);
    }
  }, [response]);
  return (
    <>
      {certState ? <CertComplete /> : ''}
      <div>
        <div>대기중</div>
      </div>
    </>
  );
};

export default KakaoLoginOauth;
