// MODULE
import { useState, useRef } from 'react';
// JSON
import FAQDATA from './FAQ.json';

const FAQList = () => {
  const [isOpen, setIsOpen] = useState(-1);
  const answerItem = useRef([]);

  const handleToggleFaqItem = (index) => {
    setIsOpen(index === isOpen ? -1 : index);
  };
  return (
    <div className="question">
      <ul>
        {FAQDATA.list.map((faqData, index) => (
          <li key={index} onClick={() => handleToggleFaqItem(index)} className="flex flex_dir_c">
            <div className="q_txt flex flex_jc_sb">
              <div className="flex">
                <span className="txt_icon">Q.&ensp;</span>
                {faqData.question}
              </div>
              <div className={`icon relative ${index === isOpen ? 'active' : ''}`}>
                <div className="absolute"></div>
                <div className="absolute"></div>
              </div>
            </div>
            <div
              className={`a_txt flex ${index === isOpen ? 'active' : ''}`}
              ref={(el) => (answerItem.current[index] = el)}
            >
              <span className="txt_icon">A.&ensp;</span>
              <div className="contents">{faqData.answer}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQList;
