// MODULE
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// HOOKS
import { myProfilePATCH } from '../../../api/member';
import { refreshTokenGET } from '../../../api/auth';
// RECOIL STATE
// COMPONENT
import ModalFrame from '../../Modal/ModalFrame';
// IMAGE
import Default_Img from '../../../assets/img/default_profile.png';
import ProfileImage from '../../ProfileImage/ProfileImage';

const ProfileSetting = () => {
  const navigate = useNavigate();
  const imgRef = useRef();
  const [success, setSuccess] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [userGetImage, setUserGetImage] = useState(null);
  const [imageBtnBox, setImageBtnBox] = useState(false);
  const [userNickname, setUserNickname] = useState('');

  const { state } = useLocation();
  const handleOpenImageMenu = () => {
    setImageBtnBox(true);
  };
  const handleCloseImageMenu = () => {
    setImageBtnBox(false);
  };
  // GET USER IMAGE
  const userImage = state.userInfo.storedProfileImagePath;
  // LOAD PAGE
  useEffect(() => {
    setUserGetImage(userImage);
    setUserNickname(JSON.parse(localStorage.getItem('userProfiles')).nickname);
  }, [state.userInfo.nickname]);
  // UPLOAD PROFILE IMAGE
  const onChangeProfileImage = async () => {
    try {
      const response = await refreshTokenGET();
      console.log(response);
      const file = imgRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadImage(reader.result);
        setUserGetImage(file);
      };
      if (reader !== null) {
        handleProfileThumbAddFile();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // CHANGE DEFAULT IMAGE
  const onChangeDefaultImage = async () => {
    try {
      const response = await refreshTokenGET();
      console.log(response);
      setUploadImage('default');
      setUserGetImage('default');
      handleProfileThumbDefaultImage();
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfileThumbAddFile = async () => {
    const id = localStorage.getItem('memberId');
    const formData = new FormData();
    const uploadImage = imgRef.current?.files[0];
    formData.append('profileImage', uploadImage);
    try {
      const result = await myProfilePATCH(id, formData);
      if (result.status === 200) {
        localStorage.removeItem('userProfileImg');
        setImageBtnBox(false);
        setSuccess(true);
      } else {
        console.log('에러 : ', result.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleProfileThumbDefaultImage = async () => {
    const id = localStorage.getItem('memberId');
    const formData = new FormData();
    try {
      const result = await myProfilePATCH(id, formData);
      if (result.status === 200) {
        setImageBtnBox(false);
        setSuccess(true);
      } else {
        console.log('에러 : ', result.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeImg = localStorage.getItem('userProfileImg');

  return (
    <div className="profile_setting">
      <div className={`modal_bg ${imageBtnBox || success ? 'active' : ''}`}></div>
      {success && (
        <ModalFrame confirm={() => navigate(-1)} custom_txt={'프로필 이미지가 변경되었습니다.'} height={'25%'} />
      )}
      <div className="img_section">
        <div>
          <div className="profile_img img_box">
            <ProfileImage
              src={
                changeImg === null || changeImg === 'null' || changeImg === 'undefined'
                  ? userGetImage === 'default' ||
                    userGetImage === null ||
                    userGetImage === 'null' ||
                    userGetImage === undefined
                    ? Default_Img
                    : uploadImage === ''
                    ? `${process.env.REACT_APP_IMAGE_URL}${userGetImage}`
                    : uploadImage
                  : `${process.env.REACT_APP_IMAGE_URL}${changeImg}`
              }
              alt="User Profile Image Thumb"
              size={100}
            />
          </div>
        </div>
        <div
          className="nickname_setting flex flex_jc_c flex_ai_c"
          onClick={() =>
            navigate('/mypage/nickname-setting', {
              state: { imgPath: document.querySelector('img').getAttribute('src') },
            })
          }
        >
          <div className="current_nickname">{state.userInfo.nickname}</div>
          <div>
            <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Edit_icon.svg`} alt="" />
          </div>
        </div>
        <div className="btn_box set_btn" onClick={handleOpenImageMenu}>
          <div className="button">이미지 변경</div>
        </div>
        {imageBtnBox && (
          <div className="custom_btn_modal btn_box flex flex_dir_c flex_jc_c flex_ai_c">
            <div className="close_btn absolute" onClick={handleCloseImageMenu}>
              <div></div>
              <div></div>
            </div>
            <div className="default_img button" onClick={onChangeDefaultImage}>
              기본이미지로 변경하기
            </div>
            <div className="upload_img button">
              <label htmlFor="image_file">이미지 등록하기</label>
              <input type="file" accept="image/*" ref={imgRef} onChange={onChangeProfileImage} id="image_file" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSetting;
