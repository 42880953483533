import axios from '../utils/instance';

const uri = '/messages';

// MESSAGE POST
export const sendMessagePOST = (content, buyRequestId) => {
  return new Promise((resolve, reject) => {
    const body = {
      content: content,
      buyRequestId: buyRequestId,
    };
    axios
      .post(`${uri}`, body, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
