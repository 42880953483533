import { atom } from 'recoil';
// USER GEOLOCATION STATE
export const userGeolocationState = atom({
  key: 'isAccessGeolocation',
  default: false,
});
// USER SELECT LOCATION
export const userLocationSelectState = atom({
  key: 'locationSelectState',
  default: null,
});

// LOCATION SETTING VALUE
export const loginLocationState = atom({
  key: 'loginLocationState',
  default: {
    firstRegionOfResidence: null,
    secondRegionOfResidence: null,
  },
});
//  MY LOCATION
export const myLocationState = atom({
  key: 'myLocationStatus',
  default: [
    {
      regionName: '',
      code: '',
    },
    {
      regionName: '',
      code: '',
    },
  ],
});
// CLUSTER PLACE
export const clusterPlaceState = atom({
  key: 'placeNameStatus',
  default: '',
});
export const clusterPlaceIndexState = atom({
  key: 'placeIndexStatus',
  default: 0,
});
// MESSAGE STATE
export const messageUserState = atom({
  key: 'messageUserName',
  default: '',
});
// MESSAGE ALARM
export const messageAlarmState = atom({
  key: 'messageAlarmStatus',
  default: null,
});
// USER ACCESS TOKEN EXPIRATION
export const accessTokenCodeState = atom({
  key: 'errorCode',
  default: null,
});
// USER COPY ULR STATE
export const urlCopyInfoState = atom({
  key: 'dataState',
  default: {
    title: '',
    description: '',
    image: '',
  },
});
// SET CURRENT LOCATION CODE
export const userGeolocationGetState = atom({
  key: 'geolocationState',
  default: null,
});
// SET CERTIFICATION STATE
export const setCertificationState = atom({
  key: 'timerEnd',
  default: false,
});
