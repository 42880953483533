import { atom } from 'recoil';

export const registerData = atom({
  key: 'registAtomState',
  default: {
    phone: '',
    nickname: '',
    userId: '',
    thumb: '',
  },
});
