// MODULE
import { useState, useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
// RECOIL STATE
import { registerData } from '../../../state/registerState';
// COMPONENT
import HistoryBack from '../../../components/Common/HistoryBack';
import ProfileImage from '../../../components/ProfileImage/ProfileImage';
import ModalFrame from '../../../components/Modal';
// IMAGE
import Default_Img from '../../../assets/img/default_profile.png';

const ThumbNail = () => {
  const navigate = useNavigate();
  const imgRef = useRef();
  const [uploadImage, setUploadImage] = useState(null);
  const [getRegisterData, setGetRegisterData] = useRecoilState(registerData);
  const [alertModal, setAlertModal] = useState(false);

  // PAGE INSERT OPTION
  useEffect(() => {
    if (getRegisterData.phone === '') {
      setAlertModal(true);
    }
  }, []);

  const onChangeProfileImage = () => {
    const file = imgRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUploadImage(reader.result);
    };
    setGetRegisterData((prevData) => ({
      ...prevData,
      thumb: file,
    }));
  };
  // NEXT PAGE
  const handleNextPage = () => {
    if (getRegisterData.phone === '') {
      alert('잘못된 접근입니다.');
      window.location = 'https://showup.co.kr';
    } else {
      navigate('/regist-location', { state: { prevPage: 'regist' } });
    }
  };

  const point_txt = '다른 사람들에게 보여줄 썸네일을 설정해주세요.\n썸네일을 언제든지 바꿀 수 있습니다.';
  return (
    <>
      {alertModal ? <ModalFrame type={'type_1'} txt={1} confirm={() => (window.location.href = '/')} /> : ''}
      <div className="thumb_setting_section">
        <HistoryBack />
        <div className="page_title">이미지설정</div>
        <div className="point_txt">{point_txt}</div>

        <div className="img_section">
          <div>
            <div className="profile_img img_box">
              <ProfileImage
                src={uploadImage === null ? Default_Img : uploadImage}
                alt="User Profile Image Thumb"
                size={100}
              />
            </div>
          </div>
          <div className="btn_box">
            <div className="upload_img button">
              <label htmlFor="image_file">이미지 등록하기</label>
              <input type="file" accept="image/*" ref={imgRef} id="image_file" onChange={onChangeProfileImage} />
            </div>
          </div>
        </div>

        <div className="btn_box">
          <div
            className={`button`}
            // onClick={() => navigate('/regist-location', { state: { prevPage: 'regist' } })}
            onClick={() => handleNextPage()}
          >
            확인
          </div>
        </div>
      </div>
    </>
  );
};

export default ThumbNail;
