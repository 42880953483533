// 2131d1f3dc368f3bb7b5ee94f98d8f73 <= REST API 키
// MODULE
import { Link } from 'react-router-dom';

const KakaoLogin = () => {
  const REST_API_KEY = process.env.REACT_APP_KAKAO_LOGIN_KEY;
  const REDIRECT_URI = `${window.location.origin}/auth/kakao/callback`;

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  return (
    <div className="kakao_sign_up button">
      <Link to={KAKAO_AUTH_URL}>카카오톡으로 시작</Link>
    </div>
  );
};

export default KakaoLogin;
