// MODULE
import { useRecoilValue } from 'recoil';
// RECOIL STATE
import { urlCopyInfoState } from '../../../state/userState';
// COMPONENT
const Kakao = () => {
  const productData = useRecoilValue(urlCopyInfoState);
  const kakaoButton = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
      }

      kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: productData.title,
          description: productData.description,
          imageUrl: productData.image,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        social: {
          likeCount: 286,
          commentCount: 45,
          sharedCount: 845,
        },
        buttons: [
          {
            title: `웹으로 보기`,
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
          {
            title: `앱으로 보기`,
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
        // SHARE SUCCESS CALLBACK
        serverCallbackArgs: {
          key: '성공이요',
        },
      });
    }
  };

  return (
    <button onClick={kakaoButton} title="카카오톡에 공유하기">
      <img src={`${process.env.PUBLIC_URL}/assets/img/icon/kakao.png`} alt="" />
    </button>
  );
};

export default Kakao;
