import { Cookies } from 'react-cookie';

const cookies = new Cookies();
// 클라이언트에서 쿠키 저장
export const setCookie = (name, value, options) => {
  return cookies.set(name, value, { ...options });
};
// 클라이언트에서 쿠키 불러오기
export const getCookie = (name) => {
  return cookies.get(name);
};
// 클라이언트에서 쿠키 삭제
export const removeCookie = (name) => {
  cookies.remove(name);
};
