// MODULE
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// PROPS TYPE
import { listItemState } from '../../state/listState';
// HOOKS
import { setPrice } from '../../utils/textEffect';
import { getElapsedTime } from '../../utils/timeEffect';
// IMAGE
import { ReactComponent as LikeCounter } from '../../assets/img/icon/Like_icon.svg';
import { ReactComponent as MessageCounter } from '../../assets/img/icon/Message_icon.svg';
// COMPONENT

const ArticleListItem = ({ info, pageId, index, totalData, order, tabs }) => {
  const focusRef = useRef(null);
  const navigate = useNavigate();

  const [, setScrollData] = useRecoilState(listItemState);
  const handlePageMove = () => {
    setScrollData(totalData);
    navigate(`/detail?info=${info.id}`, { state: { isEnd: info.end, index: index + 1 } });
    sessionStorage.setItem('y', window.pageYOffset);
    sessionStorage.setItem('pageNum', pageId);
    sessionStorage.setItem('index', index);
    if (order !== undefined && tabs !== undefined) {
      sessionStorage.setItem('order', order);
      sessionStorage.setItem('tabs', tabs);
    }
  };
  return (
    <div className="list_item" ref={focusRef}>
      <div className="flex flex_jc_sb" onClick={() => handlePageMove()}>
        <div className="img_box relative flex_as_c">
          {info.thumb === 'default' ? (
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`}
              alt={info.title}
              loading="lazy"
              width={'auto'}
              height={'auto'}
            />
          ) : (
            <img
              src={info.thumb}
              alt={info.title}
              loading="lazy"
              width={'auto'}
              height={'auto'}
              style={{ maxWidth: 100 }}
            />
          )}
        </div>
        <div className="item_info relative">
          <div className="header_info flex flex_jc_s flex_ai_c">
            <div className="reg_dt">
              {getElapsedTime(info.create)}
              &nbsp;·&nbsp;
            </div>
            <div className="location flex">
              <div className="market">{info.location}</div>&nbsp;·&nbsp;<div className="area">{info.placeName}</div>
            </div>
          </div>
          <div className="body_info">
            <div className="title">{info.title}</div>
            <div className="price">{info.price === null ? '가격정보 없음' : setPrice(info.price) + '원'}</div>
          </div>
          <div className="bottom_info absolute flex flex_jc_sb flex_ai_c">
            <div className="counter_item  flex">
              {info.request === 0 ? (
                ''
              ) : (
                <div className="purchase_request flex flex_ai_c">
                  <MessageCounter stroke={'#888888'} />
                  <span>{info.request}</span>
                </div>
              )}
              {info.like === 0 ? (
                ''
              ) : (
                <div className="like flex flex_ai_c">
                  <LikeCounter stroke={'#888888'} />
                  <span>{info.like}</span>
                </div>
              )}
            </div>
            <div className="end_dt">{info.end}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticleListItem;
