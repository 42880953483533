// COMPONENT
import KakaoMapMyLocation from '../../components/KakaoMap/KakaoMapMyLocation';

const MyLocation = () => {
  return (
    <div className="my_location_section">
      <KakaoMapMyLocation />
    </div>
  );
};
export default MyLocation;
