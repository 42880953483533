// MODULE
import { useNavigate } from 'react-router-dom';
// HOOKS
import { setPrice } from '../../utils/textEffect';
import { getElapsedTime, getRemainingPeriod } from '../../utils/timeEffect';
// COMPONENT
import Skeleton from '../Skeleton';
// IMAGE
import { ReactComponent as MessageCounter } from '../../assets/img/icon/Message_icon.svg';
import { ReactComponent as LikeCounter } from '../../assets/img/icon/Like_icon.svg';

const WriterItems = ({ items, nickname, totalCount, isMyArticle, loginCheck }) => {
  const navigate = useNavigate();
  const isNull = items?.length === 0;
  return (
    <div className="sell_items">
      <div className="user_items flex flex_jc_sb flex_ai_c">
        <div className="box_title">
          {isMyArticle === undefined ? (
            <Skeleton itemWid={170} itemHei={19} />
          ) : !isMyArticle ? (
            `${nickname} 님이 공유한 정보`
          ) : (
            '내가 공유한 정보'
          )}
        </div>
        {isMyArticle === undefined ? (
          ''
        ) : isNull ? null : (
          <div
            className="more_btn"
            onClick={() =>
              navigate('/userinfo-detail', { state: { nickname: nickname, totalCount: totalCount, items: items } })
            }
          >
            <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
          </div>
        )}
      </div>
      <ul className="sell_item_list flex flex_jc_sb flex flex_wrap_wrap">
        {items === undefined ? (
          <Skeleton type={'double_type'} loop={3} />
        ) : isNull ? (
          <li className="empty_list flex flex_jc_c">사용자가 등록한 다른 상품이 없습니다.</li>
        ) : (
          items?.slice(0, 4).map((item, index) => (
            <li key={index} onClick={() => navigate(`/detail?info=${item.id}`)}>
              <div className="img_box flex flex_ai_c">
                {item.storedProductImagePaths !== undefined ? (
                  item.storedProductImagePaths === null ? (
                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo_c.png`} />
                  ) : (
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${item.storedProductImagePaths}`} alt="" />
                  )
                ) : null}
              </div>
              <div className="title">{item.productName}</div>
              <div className="counter_item absolute flex">
                {item.buyRequestCount !== 0 ? (
                  ''
                ) : (
                  <div className="purchase_request flex">
                    <MessageCounter />
                    <span>{item.buyRequestCount}</span>
                  </div>
                )}
                {item.likeCount === 0 ? (
                  ''
                ) : (
                  <div className="like flex">
                    <LikeCounter />
                    <span>{item.likeCount}</span>
                  </div>
                )}
              </div>
              <div className="item_info relative">
                <div className="header_info flex flex_jc_s flex_ai_c flex_wrap_wrap">
                  <div className="reg_dt"></div>
                  <div className="location flex">
                    <div className="area">{item.regionName3depth}</div>&nbsp;·&nbsp;
                    <div className="market">{item.placeName}</div>
                  </div>
                  &nbsp;·&nbsp;
                  {getElapsedTime(item.createdAt)}
                </div>
                <div className="body_info">
                  <div className="title">{item.title}</div>
                  <div className="price">{item.price === null ? '가격정보 없음' : setPrice(item.price) + '원'}</div>
                </div>
                <div className="end_dt absolute">{getRemainingPeriod(item.endDay)}</div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default WriterItems;
