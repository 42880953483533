// MODULE
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// HOOK
import { myProfilePATCH } from '../../../api/member';
// UTIL
import { CheckPasswordText } from '../../../utils/textEffect';
// COMPONENT
import Input from '../../Common/Input';
import ModalFrame from '../../Modal';

const Frame = styled.div`
  width: 91%;
  padding-top: 5rem;
  margin: 0 auto;
  & .input_box {
    display: flex;
    flex-direction: column;
    & > label {
      display: flex;
      justify-content: start;
    }
    & > input {
      margin-top: 1rem;
    }
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
    .event_txt {
      bottom: -1.5rem;
      &.true {
        color: var(--true-color);
      }
      &.false {
        color: var(--false-color);
      }
    }
  }
  .btn_box {
    margin-top: 4rem;
  }
`;
const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [changeValueCheck, setChangeValueCheck] = useState({
    password: 0,
    passwordCheck: 0,
  });
  const [modal, setModal] = useState(false);

  const onChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const onChangePasswordCheck = (e) => {
    e.preventDefault();
    setPasswordCheck(e.target.value);
  };
  const onBlurPassword = () => {
    if (!CheckPasswordText(password)) {
      setChangeValueCheck({
        ...changeValueCheck,
        password: 1,
      });
    } else {
      setChangeValueCheck({
        ...changeValueCheck,
        password: 2,
      });
    }
  };
  const onBlurPasswordCheck = () => {
    const isValid = password === passwordCheck && passwordCheck.length >= 3;
    setChangeValueCheck({
      ...changeValueCheck,
      passwordCheck: isValid ? 3 : passwordCheck.length < 3 ? 2 : 1,
    });
  };

  const handleChangePassword = async () => {
    if (changeValueCheck.password !== 2 || changeValueCheck.passwordCheck !== 3) {
      document.getElementById('nickname').focus();
    } else {
      const formData = new FormData();
      const json = JSON.stringify({
        password: password,
      });
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('patchData', blob);
      const id = localStorage.getItem('memberId');
      try {
        const result = await myProfilePATCH(id, formData);
        if (result.status === 200) {
          setModal(true);
        } else {
          console.log('에러 :', result.status);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      {modal && (
        <ModalFrame
          type={'type_3'}
          custom_txt={'비밀번호가 변경되었습니다.'}
          confirm={() => navigate('/mypage/main')}
        />
      )}
      <Frame>
        <Input
          id={'password'}
          name={'password'}
          type={'password'}
          placeholder={'비밀번호'}
          label={'비밀번호'}
          onChange={(e) => onChangePassword(e)}
          onBlur={onBlurPassword}
          event_type={`absolute ${changeValueCheck.password === 1 ? 'false' : 'true'}`}
          event_msg={
            changeValueCheck.password === 0
              ? ''
              : changeValueCheck.password === 1
              ? '8~16자의 영문과 숫자, 특수문자를 조합하여 설정해주세요.'
              : changeValueCheck.password === 2 && '사용 가능합니다.'
          }
        />
        <Input
          id={'passwordCheck'}
          name={'passwordCheck'}
          type={'password'}
          placeholder={'비밀번호 확인'}
          label={'비밀번호 확인'}
          onChange={(e) => onChangePasswordCheck(e)}
          onBlur={onBlurPasswordCheck}
          event_type={`absolute ${
            changeValueCheck.passwordCheck === 1 || changeValueCheck.passwordCheck === 2 ? 'false' : 'true'
          }`}
          event_msg={
            changeValueCheck.passwordCheck === 0
              ? ''
              : changeValueCheck.passwordCheck === 1
              ? '입력한 비밀번호가 다릅니다.'
              : changeValueCheck.passwordCheck === 2
              ? '올바른 비밀번호를 입력해주세요.'
              : '비밀번호가 일치합니다.'
          }
        />
        <div className="btn_box">
          <div
            className={`button ${
              (changeValueCheck.password !== 2 && 'disable') || (changeValueCheck.passwordCheck !== 3 && 'disable')
            }`}
            onClick={handleChangePassword}
          >
            비밀번호 변경하기
          </div>
        </div>
      </Frame>
    </>
  );
};

export default ChangePassword;
