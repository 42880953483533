// MODULE
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import DatePicker from 'react-datepicker';
import Resizer from 'react-image-file-resizer';
// HOOKS
import { articlePATCH } from '../../api/article';
import { ko } from 'date-fns/esm/locale';
// RECOIL STATUS
import writeState from '../../state/writeState';
// COMPONENT
import Map from '../../components/MapSelect';
import Location from '../../components/LocationSelect';
import ProductName from '../../components/ArticleWrite/ProductName/ProductName';
import Category from '../../components/ArticleWrite/Category/Category';
// IMPORT
import 'react-datepicker/dist/react-datepicker.css';
// JSON
import CategoryData from '../../json/kakaomapCategory.json';
import Price from '../../components/ArticleWrite/Price/Price';
import Calendar from '../../components/ArticleWrite/Calendar';
import ArticleCheckBox from '../../components/ArticleWrite/ArticleCheckBox/ArticleCheckBox';
import Contents from '../../components/ArticleWrite/Contents/Contents';
// IMAGE

const ArticleModify = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [defaultItem, setDefaultItem] = useState('');
  const [inputErr, setInputErr] = useState('');
  const [uploadImage, setUploadImage] = useState([]);
  const [getDataImage, setGetDataImage] = useState([]);
  const [deleteDataImage, setDeleteDataImage] = useState([]);
  const [maxCount, setMaxCount] = useState(6);

  const [coord, setCoord] = useRecoilState(writeState.writeLocationInfoState);
  const [modalType, setModalType] = useRecoilState(writeState.locationSelectTypeState);

  const imgRef = useRef();

  const getDate = useRecoilValue(writeState.datePickerState);

  // IMAGE RESIZE
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });
  const [resizeImg, setResizeImg] = useState([]);
  const onChange = async (event) => {
    try {
      const resizedImages = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const image = await resizeFile(file);
        resizedImages.push(image);
      }
      setResizeImg(resizedImages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // SET PROPS IMAGEDATA
    setGetDataImage(state.data.storedProductImagePaths);
    // SET PROPS LOCATION
    setCoord(() => ({
      lat: state.data.latitude,
      lon: state.data.longitude,
      code: state.data.regionCode,
      place_name: state.data.placeName,
    }));
  }, []);

  const handleAddImages = async (event) => {
    const imageLists = event.target.files;
    const resizedImages = [];

    for (let i = 0; i < imageLists.length; i++) {
      const file = imageLists[i];
      const image = await resizeFile(file);
      resizedImages.push(image);
    }

    let newImageList = [...uploadImage, ...resizedImages.slice(0, 5 - uploadImage.length)];
    let newImageThumbList = newImageList.map((image) => (image instanceof File ? URL.createObjectURL(image) : image));

    if (getDataImage.length + newImageList.length > 5) {
      alert('이미지는 최대 5장 까지만 등록이 가능합니다.');
      setResizeImg(newImageList.slice(0, 5));
      setUploadImage(newImageThumbList);
    } else {
      setResizeImg(newImageList);
      setUploadImage(newImageThumbList);
    }
  };

  useEffect(() => {
    setGetDataImage((prevThumb) => [...prevThumb, ...uploadImage].slice(0, 5));
  }, [uploadImage]);

  // DELETE IMAGE
  const handleDeleteImage = (id, image) => {
    setGetDataImage(getDataImage.filter((_, index) => index !== id));
    setDeleteDataImage([image, ...deleteDataImage]);
  };
  useEffect(() => {
    setMaxCount(5 - getDataImage.length);
  }, [getDataImage]);
  // TITLE VALUE
  const [title, setTitle] = useState(state.data.productName);
  const onChangeTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };
  // CATEGORY
  const [category, setCategory] = useState(state.data.category);
  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };
  // PRICE
  const [price, setPrice] = useState(state.data.price);
  const onChangePrice = (e) => {
    e.preventDefault();
    setPrice(e.target.value.replace(/[^0-9]/g, ''));
  };
  // START DAY
  const [startDt, setStartDt] = useState(new Date(state.data.startDay));
  const postStartDay = startDt.toISOString().slice(0, 10);
  // END DAY
  const [endDt, setEndDt] = useState(new Date(state.data.endDay));
  const postEndDay = endDt.toISOString().slice(0, 10);
  // ISNATIONWIDE
  const [nationWide, setNationWide] = useState(state.data.isNationwide);
  const onChangeNationWide = () => {
    setNationWide((nationWide) => !nationWide);
  };

  // ISPURCHASABLE
  const [purchasable, setPurchasable] = useState(state.data.isPurchasable);
  const onChangePurchasable = () => {
    setPurchasable((purchasable) => !purchasable);
  };

  // CONTENT
  const [content, setContent] = useState(state.data.content);
  const onChangeContent = (e) => {
    e.preventDefault();
    setContent(e.target.value.replace(/<br\s*\/?>/gm, '\n'));
  };
  // MAP
  // LOCATION SELECT POPUP
  const [locationSelect, setLocationSelect] = useState(false);
  const handleOpenLocationSelectBox = () => {
    setLocationSelect(true);
  };
  const handleCloseLocationSelectBox = () => {
    setLocationSelect(false);
  };
  // SELECT MAP CLOSE
  const handleSelectMapPosition = () => {
    setModalType(false);
  };
  // MAP
  const [reCountImg, setReCountImg] = useState([]);
  useEffect(() => {
    setReCountImg(resizeImg.slice(0, maxCount));
  }, [resizeImg]);
  const isNullCheck = title.length === 0 || price.length === 0 || category === 'default';
  const handleArticleModify = async () => {
    if (title.length === 0) {
      document.getElementById('title').focus();
      setInputErr(1);
    } else if (price.length === 0) {
      document.getElementById('price').focus();
      setInputErr(2);
    } else if (category === 'default') {
      setInputErr(5);
    } else {
      const formData = new FormData();
      const productImages = document.querySelector('#image_file');

      if (productImages.files.length > 0) {
        const imageMaxLength = Math.min(5, getDataImage.length);
        for (let i = 0; i < imageMaxLength; i++) {
          formData.append('productImages', reCountImg[i]);
        }
      }
      console.log('총합', getDataImage.length + resizeImg.length);
      const json = JSON.stringify({
        productName: title,
        startDay: postStartDay,
        endDay: postEndDay,
        price: price,
        content: content,
        category: category,
        isNationwide: nationWide,
        isPurchasable: purchasable,
        imagePathsToDelete: deleteDataImage,

        placeName: coord.place_name,
        latitude: coord.lat,
        longitude: coord.lon,
        regionName3depth: coord.addr_depth_3,
        regionCode: coord.code,
      });
      const deleteImage = JSON.stringify({
        imagePathsToDelete: deleteDataImage,
      });
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('patchData', blob);

      try {
        const result = await articlePATCH(state.articleId, formData);
        navigate(`/detail?info=${state.articleId}`, { state: { isWrite: true } });
      } catch (error) {
        console.error('writePOST error:', error);
      }
    }
  };

  useEffect(() => {
    if (startDt > endDt) {
      alert('종료일은 시작일보다 이전일 수 없습니다.');
      setStartDt(new Date(state.data.startDay));
      setEndDt(new Date(state.data.endDay));
    }
  }, [startDt, endDt]);

  return (
    <div className="write_section">
      <div className="write_header flex">
        <div className="history_back relative" onClick={() => navigate(-1)}>
          <div></div>
          <div></div>
        </div>
        <div className="page_title">내 글 수정하기</div>
      </div>

      <form className="form_box" encType="multipart/form-data">
        {/* IMAGE SECTION */}
        <div className="picture_section flex flex_ai_fe relative">
          <span className="event_txt absolute_top">{inputErr === 4 ? '*상품사진을 추가해주세요.' : ''}</span>

          <div className="picture_box input_box relative">
            <label htmlFor="image_file" className="absolute flex flex_jc_c flex_ai_c">
              +<span className="img_counter absolute">{getDataImage.length}/5</span>
            </label>
            <input
              type="file"
              accept="image/*"
              ref={imgRef}
              onChange={handleAddImages}
              id="image_file"
              disabled={getDataImage.length === 5 ? true : false}
              multiple="multiple"
            />
          </div>

          <div className="thumb_img flex flex_ai_fe">
            {
              // uploadImage
              getDataImage.map((image, id) => {
                const isUploadFile = image.slice(0, 5).includes('blob:');
                return (
                  <span key={id} className="relative">
                    <div className="img_box relative flex flex_jc_c flex_ai_c">
                      <img
                        src={isUploadFile ? `${image}` : `${process.env.REACT_APP_IMAGE_URL}${image}`}
                        alt={`${image}-${id}`}
                        width={70}
                      />
                    </div>
                    <div className="del_btn flex flex_jc_c flex_ai_c" onClick={() => handleDeleteImage(id, image)}>
                      +
                    </div>
                  </span>
                );
              })
            }
          </div>
        </div>

        <ProductName title={title} change={onChangeTitle} error={inputErr} />

        <Category category={category} change={onChangeCategory} error={inputErr} data={CategoryData.CATEGORY_LIST} />

        <Price price={price} change={onChangePrice} error={inputErr} />

        {/* <Calendar getStartDay={state.data.startDay} getEndDay={state.data.endDay} /> */}
        <div className="date_box flex flex_jc_s">
          <div className="start_day_box input_box flex flex_wrap_wrap">
            <label htmlFor="start_day">시작일</label>
            <DatePicker
              selected={startDt}
              onChange={(date) => setStartDt(date)}
              placeholderText="시작일을 선택해주세요."
              dateFormat="yyyy-MM-dd"
              withPortal
              locale={ko}
              calendarStartDay={1}
              calendarClassName="custom_calendar"
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                return (
                  <div className="date_header flex flex_jc_sb flex_ai_c">
                    <div>
                      {year}년&nbsp;{month}월
                    </div>
                    <div className="btn_box flex">
                      <button
                        className="prev_btn"
                        type="button"
                        onClick={() => decreaseMonth()}
                        disabled={prevMonthButtonDisabled}
                      >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                      </button>

                      <button
                        className="next_btn"
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                      </button>
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div className="normal_txt flex_jc_c flex_as_e">-</div>
          <div className="end_day_box input_box flex flex_wrap_wrap">
            <label htmlFor="end_day">종료일</label>
            <DatePicker
              selected={endDt}
              onChange={(date) => setEndDt(date)}
              placeholderText="종료일을 선택해주세요."
              dateFormat="yyyy-MM-dd"
              withPortal
              locale={ko}
              calendarStartDay={1}
              calendarClassName="custom_calendar"
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                return (
                  <div className="date_header flex flex_jc_sb flex_ai_c">
                    <div>
                      {year}년&nbsp;{month}월
                    </div>
                    <div className="btn_box flex">
                      <button
                        className="prev_btn"
                        type="button"
                        onClick={() => decreaseMonth()}
                        disabled={prevMonthButtonDisabled}
                      >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                      </button>

                      <button
                        className="next_btn"
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`} alt="" />
                      </button>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <ArticleCheckBox
          purchasable={purchasable}
          nationWide={nationWide}
          changePurchasable={onChangePurchasable}
          changeNationWide={onChangeNationWide}
        />

        <Contents content={content} change={onChangeContent} />

        <div className="location_select_btn relative">
          <div className="section_title">장소 선택하기</div>
          <div onClick={handleOpenLocationSelectBox} className="custom_input">
            <div>
              {coord.place_name === null || coord.place_name === undefined ? <span>위치 추가</span> : coord.place_name}
            </div>
            <span className="event_txt absolute">{inputErr === 3 ? '*장소를 선택해주세요.' : ''}</span>
          </div>
          {locationSelect ? <Location close={handleCloseLocationSelectBox} /> : null}
          {modalType ? <Map close={handleSelectMapPosition} /> : null}
        </div>

        <div className="btn_box flex flex_jc_c">
          <div className={`button ${isNullCheck ? 'disable' : ''}`} onClick={handleArticleModify}>
            수정하기
          </div>
        </div>
      </form>
    </div>
  );
};

export default ArticleModify;
