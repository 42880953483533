// MODULE
import { useEffect, useState } from 'react';
import styled from 'styled-components';
// HOOK
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import FindId from '../../components/FindUserInfo/FindId';
import FindPw from '../../components/FindUserInfo/FindPw';

const TabMenuFrame = styled.div`
  position: relative;
  margin-top: 2rem;
  > div {
    flex-basis: 50%;
    > input {
      opacity: 0;
      visibility: hidden;
      ~ label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        font-size: 1.8rem;
        font-weight: 700;
      }
      &:checked ~ label {
      }
    }
  }
`;
const EffectBar = styled.div`
  position: absolute;
  width: 50%;
  height: 3px;
  bottom: 0;
  left: calc(${(props) => props.left}px - 20px);
  background: var(--point-color);
  transition: left 0.3s;
`;
const TabContent = styled.div`
  margin-top: 5rem;
`;
const InputFrame = styled.div`
  .input_box {
    display: flex;
    flex-direction: column;
    & > label {
      display: flex;
      justify-content: start;
    }
    & > input {
      margin-top: 1rem;
    }
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }
  .btn_box {
    margin-top: 4rem;
  }
`;
const FindUserInfo = () => {
  const [tabOpen, setTabOpen] = useState('find_id');
  const [barPosition, setBarPosition] = useState(0);
  const [, setRouteStatus] = useState(false);

  const handleTabMenu = (e) => {
    setTabOpen(e.target.control.value);
    setBarPosition(e.target.getClientRects()[0].x);
  };

  useEffect(() => {}, []);
  return (
    <div className="frame">
      <div className="frame_inner_header flex flex_jc_sb flex_ai_c">
        <HistoryBack />
        <div className="page_title flex flex_jc_c">아이디/비밀번호 찾기</div>
      </div>
      <div className="body">
        <TabMenuFrame className="menu_bars flex flex_ai_c">
          <div>
            <input type="radio" name="find_info" id="find_id" value="find_id" defaultChecked />
            <label htmlFor="find_id" onClick={(e) => handleTabMenu(e)}>
              아이디 찾기
            </label>
          </div>
          <div>
            <input type="radio" name="find_info" id="find_pw" value="find_pw" />
            <label htmlFor="find_pw" onClick={(e) => handleTabMenu(e)}>
              비밀번호 찾기
            </label>
          </div>
          <EffectBar left={barPosition} />
        </TabMenuFrame>
        <TabContent>
          {tabOpen === 'find_id' ? (
            <FindId Frame={InputFrame} status={setRouteStatus} tabMove={setTabOpen} />
          ) : (
            <FindPw Frame={InputFrame} status={setRouteStatus} />
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default FindUserInfo;
