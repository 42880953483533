// MODULE
import { Suspense } from 'react';
import { isMobile } from 'react-device-detect';
// HOOKS
import Layout from './components/Layout';
function App() {
  return (
    <Suspense>
      <Layout />
    </Suspense>
  );
}

export default App;
