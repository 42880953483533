// MODULE
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PointDefaultLog = ({ data }) => {
  const navigate = useNavigate();
  const groupedData = data.reduce((result, item) => {
    const key = item.createdAt;

    if (!result[key]) {
      result[key] = [];
    }

    result[key].push({
      content: item.content,
      point: item.point,
      articleId: item.articleId,
    });

    return result;
  }, {});
  const thisYear = new Date().getFullYear().toString();
  return (
    <>
      {Object.keys(groupedData).map((date) => (
        <li key={date} className="flex flex_ai_fs">
          <div></div>
          <div className="date">{date.replaceAll('-', '.').replace(thisYear + '.', '')}</div>
          <div className="contents">
            {groupedData[date].map((item, index) => {
              const isSign = Math.sign(item.point) === -1 ? item.point : '+' + item.point;
              return (
                <div key={index} className="flex flex_jc_sb flex_ai_c">
                  <div className="ex_txt" onClick={() => navigate(`/detail?info=${item.articleId}`, { state: {} })}>
                    {item.content}
                  </div>
                  <div className={`calc_point ${Math.sign(item.point) === -1 ? '' : 'plus'}`}>{isSign}</div>
                </div>
              );
            })}
          </div>
        </li>
      ))}
    </>
  );
};

export default PointDefaultLog;
