const Price = ({ price, change, error }) => {
  return (
    <div className={`price_box input_box flex flex_wrap_wrap ${price === '' ? '' : 'active'} relative`}>
      <label htmlFor="price">가격</label>
      <input type="text" id="price" onChange={change} value={price} placeholder="가격을 입력해주세요" maxLength={8} />
      <span className="event_txt absolute">{error === 2 ? '*상품금액을 입력해주세요.' : ''}</span>
    </div>
  );
};

export default Price;
