import axios from '../utils/instance';

const uri = '/reviews';

// REVIEW POST
export const tradeReviewPOST = (score, buyRequestId) => {
  return new Promise((resolve, reject) => {
    const body = {
      score: score,
      buyRequestId: buyRequestId,
    };
    axios
      .post(`${uri}`, body, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
