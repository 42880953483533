const ProductName = ({ title, change, error }) => {
  return (
    <div className="title_box input_box flex flex_wrap_wrap relative">
      <label htmlFor="title">상품명</label>
      <input
        type="text"
        id="title"
        onChange={change}
        value={title}
        placeholder="상품명을 입력해 주세요"
        maxLength={80}
      />
      <span className="event_txt absolute">{error === 1 ? '*상품명을 입력해주세요.' : ''}</span>
    </div>
  );
};

export default ProductName;
