// MODULE
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// HOOK
import { myArticleGET } from '../../../api/member';
// COMPONENT
import ArticleList from '../../../pages/ArticleList';

const ShareList = () => {
  const { state } = useLocation();
  const id = state.userInfo !== undefined ? localStorage.getItem('memberId') : state.id;
  const [order, setOrder] = useState('ALL');
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [totalCount, setTotalCount] = useState();
  const [maxPage, setMaxPage] = useState();

  const handleOnChangeTab = (order) => {
    setOrder(order);
  };
  const TabMenu = [
    {
      id: 'allArticleCount',
      name: '전체',
      order: 'ALL',
    },
    {
      id: 'onSaleArticleCount',
      name: '게시중',
      order: 'ON_SALE',
    },
    {
      id: 'expiredArticleCount',
      name: '기간만료',
      order: 'EXPIRED',
    },
  ];

  async function getList() {
    const result = await myArticleGET(id, order, page);
    const data = result.data.responses;

    if (page > 1) {
      setListData([...listData, ...data]);
    } else {
      setListData(data);
    }
    setTotalCount(result.data);
    setMaxPage([
      { type: 'allArticleCount', count: result.data.allArticleCount },
      { type: 'expiredArticleCount', count: result.data.expiredArticleCount },
      { type: 'onSaleArticleCount', count: result.data.onSaleArticleCount },
    ]);
  }
  useEffect(() => {
    getList();
    if (page === 1) {
      window.scrollTo(0, 0);
    }
    getList();
    // if (page > 1) {
    //   console.log('test page > 1');
    // } else if (page === 1) {
    //   console.log('test page === 1');
    //   window.scrollTo(0, 0);
    // }
  }, [order, page]);

  useEffect(() => {
    setPage(1);
  }, [order]);
  return (
    <div className="share_page">
      <div className="page_header flex">
        {listData && (
          <ul className="tab_list flex flex_jc_sb">
            {TabMenu.map((item, index) => {
              return (
                <li
                  key={item.id}
                  onClick={() => handleOnChangeTab(item.order)}
                  className={`${order === item.order ? 'active' : ''} relative flex flex_jc_c flex_ai_c`}
                >
                  <span>
                    {item.name}&nbsp;
                    {totalCount[item?.id]}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {listData && (
        <ArticleList
          data={listData}
          setPage={setPage}
          page={page}
          maxPage={maxPage}
          type={TabMenu.filter((type) => type.order === order)[0]?.id}
        />
      )}
    </div>
  );
};

export default ShareList;
