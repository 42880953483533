// MODULE
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// HOOK
import { myProfileInfoGET } from '../../api/member';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import ProfileImage from '../../components/ProfileImage/ProfileImage';
import ToastBottomMenu from '../../components/ToastBottomMenu/ToastBottomMenu';
import Skeleton from '../../components/Skeleton';
import ShareList from '../../components/Mypage/ShareList/ShareList';
// IMAGE
import Default_Img from '../../assets/img/default_profile.png';
import MannerScore from '../../components/MannerScore';

const CurrentUserData = () => {
  const { state } = useLocation();
  const [menuState, setMenuState] = useState(false);
  const [getScore, setGetScore] = useState(0);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);

  const maxScore = 500;

  // GET USER INFO
  const getUserProfile = async () => {
    const id = state.id;
    const needValue = '2,3,16,17';
    try {
      const response = await myProfileInfoGET(id, needValue);
      setCurrentUserInfo(response.data);
      setGetScore(response?.data.mannersScore);
    } catch (error) {
      console.log(error);
    }
  };
  // OPEN MENU
  const handleMenuOpen = () => {
    setMenuState(true);
  };
  const handleMenuClose = () => {
    setMenuState(false);
  };

  useEffect(() => {
    getUserProfile();
  }, [state]);
  return (
    <div className="current_info_section">
      <div className={`shadow_bg fixed ${menuState}`} onClick={() => setMenuState(false)}></div>
      <ToastBottomMenu
        close={handleMenuClose}
        menuState={menuState}
        pages={'userinfo'}
        memberId={state.id}
        memberNickname={state.data.writerNickname}
      />
      <div className="current_info_header flex flex_jc_sb flex_ai_c">
        <HistoryBack />
        {currentUserInfo !== null ? (
          <div>{state.isMyArticle ? '내 프로필' : `${currentUserInfo.nickname} 님의 프로필`}</div>
        ) : (
          <Skeleton itemWid={120} itemHei={19} />
        )}
        {state.isMyArticle ? (
          <div className="dotted_btn"></div>
        ) : (
          <div className="dotted_btn flex flex_dir_c flex_jc_sb flex_ai_c" onClick={() => handleMenuOpen()}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
      <div className="current_info_body">
        <div className="profile flex_dir_c flex_ai_c">
          <div className="img_box profile_img">
            {currentUserInfo !== null ? (
              <ProfileImage
                src={
                  currentUserInfo.storedProfileImagePath === undefined
                    ? Default_Img
                    : `${process.env.REACT_APP_IMAGE_URL}${currentUserInfo.storedProfileImagePath}`
                }
                alt={`${currentUserInfo.nickname} 님의 프로필 이미지`}
                size={100}
              />
            ) : (
              <Skeleton itemWid={100} itemHei={100} />
            )}
          </div>
          {currentUserInfo !== null ? (
            <div className="txt_box flex flex_jc_c">{currentUserInfo.nickname}</div>
          ) : (
            <Skeleton itemWid={50} itemHei={19} />
          )}
        </div>
        <div className="inner_section">
          {currentUserInfo !== null ? <MannerScore propsScore={Math.floor((getScore / maxScore) * 100)} /> : ''}
          <div className="review_count">
            {currentUserInfo !== null ? (
              <>
                받은 후기 <span>{currentUserInfo.reviewCount}</span> 건
              </>
            ) : (
              <Skeleton itemWid={100} itemHei={16} />
            )}
          </div>
          {state.id !== undefined ? <ShareList id={state.id} /> : ''}
        </div>
      </div>
    </div>
  );
};

export default CurrentUserData;
