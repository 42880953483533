// MODULE
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// HOOK
import { refreshTokenGET } from '../../api/auth';
import { deleteStorage } from '../../utils/common';

const Error = () => {
  const navigate = useNavigate();

  const [data, setData] = useState('');

  const ErrorPage = async () => {
    try {
      const response = await refreshTokenGET();
      console.log('Error', response);
    } catch (error) {
      console.log('Error', error);
      setData(error);
      deleteStorage();
    }
  };

  useEffect(() => {
    ErrorPage();
  }, []);
  return (
    <div className="error_page">
      <div className="page_title" style={{ textAlign: 'center' }}>
        알수없는 오류로 인해 메인 페이지로 돌아갑니다.
        <div>{data}</div>
      </div>
      <div className="btn_box">
        <div className="button" onClick={() => navigate('/home')}>
          돌아가기
        </div>
      </div>
    </div>
  );
};

export default Error;
