import { removeCookie } from './cookie';

// 로그아웃
export function deleteStorage() {
  localStorage.clear();
  removeCookie('firstRegionOfResidence');
  removeCookie('secondRegionOfResidence');
  removeCookie('memberId');
  removeCookie('isLogin');
}
// 날짜 설정
export function normalTime(time) {
  const getTime = new Date(time);
  const KorTime = getTime.toString();
  return console.log(KorTime);
}
export function normalDay(time) {
  const getTime = new Date(time);
  const Year = getTime.getFullYear();
  const Month = getTime.getMonth() + 1;
  const Day = getTime.getDate();
  return Year + '년' + Month + '월' + Day + '일';
}
// 총 페이지 계산
export function pageCount(total) {
  const maxItem = 20;
  const totalPage = Math.ceil(total / maxItem);

  return totalPage;
}
// 로그인 페이지 유지
export function rememberPrevPage(url) {
  const replaceUrl = url.replace(window.location.origin, '');
  localStorage.setItem('prevUrl', replaceUrl);
}
