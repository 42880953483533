// MODULE
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
// RECOIL STATE
import { messageMenuState } from '../../../state/messageState';

const HistoryBack = ({ url }) => {
  const navigate = useNavigate();
  const closeMenu = useResetRecoilState(messageMenuState);

  const returnPage = () => {
    navigate(url === undefined ? -1 : url, { state: { back: true } });
    closeMenu();
  };
  return (
    <div className="history_back flex" onClick={() => returnPage()}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/img/icon/Back_arrow.svg`}
        alt=""
        loading="lazy"
        width={10}
        height={19}
      />
    </div>
  );
};

export default HistoryBack;
