// MODULE
import { useNavigate } from 'react-router-dom';
// COMPONENT
import HistoryBack from '../../Common/HistoryBack/HistoryBack';
// IMAGE
import { ReactComponent as HomeIcon } from '../../../assets/img/icon/Home.svg';

const DetailHeader = ({ notImages, fromWrite, openMenu, isMyArticle, homeBtn }) => {
  const navigate = useNavigate();
  return (
    <div className={`detail_header flex flex_jc_sb flex_ai_c ${notImages ? 'type_normal' : ''}`}>
      <div className="left_menu flex flex_ai_c">
        <HistoryBack url={fromWrite ? '/home' : undefined} />
        {homeBtn ? (
          <div className="home_move" onClick={() => navigate('/home')}>
            <HomeIcon className="flex flex_ai_c" />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="right_menu flex">
        <div className="dotted_btn flex flex_dir_c flex_jc_sb flex_ai_c" onClick={openMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default DetailHeader;
