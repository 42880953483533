const Category = ({ category, change, error, data }) => {
  return (
    <div className="category_box select_box flex flex_wrap_wrap relative">
      <span className="custom_label">카테고리</span>
      <select name="" id="category" value={category} onChange={change}>
        <option value="">카테고리 선택하기</option>
        {data.map((cate) => (
          <option value={cate.name} key={cate.code}>
            {cate.name}
          </option>
        ))}
      </select>
      <span className="event_txt absolute">{error === 5 ? '*카테고리를 선택하세요.' : ''}</span>
    </div>
  );
};

export default Category;
