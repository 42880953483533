// MODULE
// HOOK
import { deleteStorage } from '../../../utils/common';
// COMPONENT
const AccountCancelEnd = () => {
  const handleAccountCancelEnd = () => {
    deleteStorage();
    window.location.href = '/';
  };
  return (
    <div className="account_cancel_end">
      <div className="header flex flex_jc_c flex_ai_c">회원 탈퇴</div>
      <div className="contents">
        <h1>그동안 즐거웠어요!</h1>
        <h1>다음에 꼭 다시 만나요</h1>
        <div className="img_box">
          <img src="" alt="" />
        </div>
      </div>
      <div className="btn_box absolute">
        <div className="button" onClick={() => handleAccountCancelEnd()}>
          홈으로 돌아가기
        </div>
      </div>
    </div>
  );
};

export default AccountCancelEnd;
