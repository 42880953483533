// MODULE
import { useLocation, useNavigate } from 'react-router-dom';
// COMPONENT
import HistoryBack from '../../components/Common/HistoryBack';
import ArticleList from '../ArticleList';
// IMAGE
import { ReactComponent as HomeIcon } from '../../assets/img/icon/Home.svg';

const WriterItemsTab = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const items = state.items;

  return (
    <div className="user_info_section">
      <div className={`detail_header flex flex_jc_sb flex_ai_c flex_wrap_wrap`}>
        <div className="left_menu flex flex_ai_c">
          <HistoryBack />
          <div className="home_move" onClick={() => navigate('/home')}>
            <HomeIcon className="flex flex_ai_c" />
          </div>
        </div>
        <div className="right_menu flex flex_jc_e flex_ai_c"></div>
        <div className="page_title">
          {state.nickname}님이 공유한 정보({state.totalCount})
        </div>
      </div>
      <ArticleList data={items} page={false} />
    </div>
  );
};

export default WriterItemsTab;
