// MODULE
import { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// COMPONENT
import Search from '../Search/Search';
// IMAGE

const Header = ({ placeName, placeNames, open }) => {
  // const location = useLocation();
  // const headerViewType = location.pathname.includes('mylocation') || location.pathname.includes('cluster-list');

  const [searchPopup, setSearchPopup] = useState(false);
  // const handleSearchPopupOpen = () => {
  //   setSearchPopup(true);
  //   const rootBody = document.querySelector('body');
  //   rootBody.style.overflow = 'hidden';
  // };
  const handleSearchPopupClose = () => {
    setSearchPopup(false);
    const rootBody = document.querySelector('body');
    rootBody.style.overflow = 'unset';
  };
  return (
    <>
      <header>
        <div className="flex flex_jc_sb">
          <div className="place_names flex flex_ai_c">
            <div>
              <img src={`${process.env.PUBLIC_URL}/assets/img/icon/Marker.svg`} alt="" />
            </div>
            {placeName === undefined ? (
              placeNames === 0 ? (
                <div className="txt empty">선택된 장소가 없습니다.</div>
              ) : (
                <div className="txt empty">{placeNames.length}개의 장소가 있습니다.</div>
              )
            ) : (
              <div className="txt" onClick={open}>
                {placeName}
              </div>
            )}
          </div>
        </div>
      </header>
      {searchPopup ? <Search close={handleSearchPopupClose} /> : null}
    </>
  );
};

export default Header;
