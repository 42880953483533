import axios from '../utils/instance';

const uri = '/auth';

// CERT SMS GET
export const certSMSSendGET = (phone) => {
  return new Promise((resolve, reject) => {
    const textReplace = phone.replace(/-/g, '');
    axios
      .get(`${uri}/send-sms?phoneNumber=${textReplace}`, {
        headers: {
          Accept: '*/*',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// LOGOUT DELETE
export const memberLogoutGET = () => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${uri}/members/token`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// CERT NUMBER CHECK
export const certNumberCheckGET = (certId, certCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/verify-sms?randomNumberId=${certId}&randomNumber=${certCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// KAKAO LOGIN GET
export const kakaoLoginGET = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/kakao/login?code=${code}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ACCESS TOKEN REFRESH
export const refreshTokenGET = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${uri}/token/reissue`, {
        headers: {
          charset: 'UTF-8',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// CHANGE LOGIN FORM
//// LOGIN
export const loginIdPOST = (id, pw) => {
  const loginData = {
    password: pw,
    loginId: id,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`${uri}/id-password-login`, loginData, {
        headers: {
          charset: 'UTF-8',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// TEST ACCOUNT
// export const testNumberCheckGET = (certCode) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${uri}/verify-sms?randomNumber=${certCode}`, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       })
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
