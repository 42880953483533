// MODULE
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// HOOK
import { memberPOST, myProfileInfoGET, userDuplicationPOST } from '../../../api/member';
import { setCookie } from '../../../utils/cookie';
import { saveLoginData } from '../../../utils/saveData';
// RECOIL STATE
import { isLoginState } from '../../../state/commonState';
// UTIL
import { CheckPasswordText } from '../../../utils/textEffect';
// COMPONENT
import HistoryBack from '../../../components/Common/HistoryBack';
import Input from '../../../components/Common/Input';
import PrivacyPolicy from '../../../components/PrivacyPolicy';
import TermsOfUse from '../../../components/TermsOfUse';
import ModalFrame from '../../../components/Modal/ModalFrame';

const Register = () => {
  const navigate = useNavigate();
  const [, setIsLogin] = useRecoilState(isLoginState);
  const [join, setJoin] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [Terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [emailDomain, setEmailDomain] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [registerData, setRegisterData] = useState({
    id: '',
    password: '',
    nickname: '',
  });
  const [registerCheck, setRegisterCheck] = useState({
    id: 0,
    password: 0,
    passwordCheck: 0,
    nickname: 0,
    email: 0,
  });

  const onChangeTarget = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };
  const onChangePasswordCheck = (e) => {
    e.preventDefault();
    setPasswordCheck(e.target.value);
  };
  const onChangeEmailAddress = (e) => {
    e.preventDefault();
    setEmailAddress(e.target.value);
  };
  const onChangeEmailDomain = (e) => {
    e.preventDefault();
    setEmailDomain(e.target.value);
  };

  const onBlurPassword = () => {
    if (!CheckPasswordText(registerData.password)) {
      setRegisterCheck({
        ...registerCheck,
        password: 1,
      });
    } else {
      setRegisterCheck({
        ...registerCheck,
        password: 2,
      });
    }
  };
  const onBlurPasswordCheck = () => {
    const isValid = registerData.password === passwordCheck && passwordCheck.length >= 3;
    setRegisterCheck({
      ...registerCheck,
      passwordCheck: isValid ? 3 : passwordCheck.length < 3 ? 2 : 1,
    });
  };
  const onBlurIdDuplication = async () => {
    if (registerData.id.length < 3) {
      setRegisterCheck({ ...registerCheck, id: 2 });
      return;
    }
    const id = registerData.id;
    try {
      const response = await userDuplicationPOST({ id });
      const isDuplicate = response.data.message === '중복 입니다.';
      setRegisterCheck({ ...registerCheck, id: isDuplicate ? 1 : 3 });
    } catch (error) {
      console.log('error\n', error);
    }
  };
  const onBlurNicknameDuplication = async () => {
    if (registerData.nickname.length < 3) {
      setRegisterCheck({ ...registerCheck, nickname: 2 });
      return;
    }
    const nickname = registerData.nickname;
    try {
      const response = await userDuplicationPOST({ nickname });
      const isDuplicate = response.data.message === '중복 입니다.';
      setRegisterCheck({ ...registerCheck, nickname: isDuplicate ? 1 : 3 });
    } catch (error) {
      console.log('error\n', error);
    }
  };
  const onBlurEmailDuplication = async () => {
    // 유효한 이메일 도메인 리스트
    // if (emailAddress.length < 3 || !isDomainValid) {
    if (emailAddress.length < 3) {
      return setRegisterCheck({ ...registerCheck, email: 2 });
    }
    const fullEmail = emailAddress + '@' + emailDomain;
    try {
      const isDuplicate = await userDuplicationPOST({ email: fullEmail }).then(
        (res) => res.data.message === '중복 입니다.'
      );
      setRegisterCheck({ ...registerCheck, email: isDuplicate ? 1 : 3 });
    } catch (error) {
      console.log('error\n', error);
    }
  };
  // TERMS OF USE MODAL
  const handleOpenTermsOfUse = () => {
    setTerms(true);
  };
  const handleCloseTermsOfUse = () => {
    setTerms(false);
  };
  const handleChangeTermsOfUse = () => {
    setTermsCheck(!termsCheck);
  };
  // PRIVACY POLICY
  const handleOpenPrivacy = () => {
    setPrivacy(true);
  };
  const handleClosePrivacy = () => {
    setPrivacy(false);
  };
  const handleChangePrivacy = () => {
    setPrivacyCheck(!privacyCheck);
  };

  const handleRegisterShowup = async () => {
    if (registerCheck.id !== 3) {
      document.querySelector('#id').focus();
    } else if (registerCheck.password !== 2) {
      document.querySelector('#password').focus();
    } else if (registerCheck.passwordCheck !== 3) {
      document.querySelector('#loginPwCk').focus();
    } else if (registerCheck.nickname !== 3) {
      document.querySelector('#nickname').focus();
    } else if (registerCheck.email !== 3) {
      document.querySelector('#email_address').focus();
    }
    // else if (!privacyCheck) {
    //   document.querySelector('#privacy_policy').focus();
    // } else if (!Terms) {
    //   document.querySelector('#terms_use').focus();
    // }
    else {
      const formData = new FormData();
      const json = JSON.stringify({
        loginId: registerData.id,
        password: registerData.password,
        nickname: registerData.nickname,
        email: emailAddress + '@' + emailDomain,
        firstRegionOfResidence: '',
        firstRegionOfResidenceCode: '',
        secondRegionOfResidence: '',
        secondRegionOfResidenceCode: '',
      });
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('postData', blob);
      try {
        const response = await memberPOST(formData);
        if (response.status === 201) {
          setJoin(true);
          const result = response.data;
          const needValue = '2,3,4,5,6,7,8,11,16';
          const loginResult = await myProfileInfoGET(result.id, needValue);
          const resultData = loginResult.data;
          setCookie('firstRegionOfResidence', resultData.firstRegionOfResidence, { path: '/' });
          setCookie('secondRegionOfResidence', resultData.secondRegionOfResidence, { path: '/' });
          const time = 3600;
          const expiration = new Date(Date.now() + time * 720000);
          setCookie('isLogin', true, { expires: expiration });
          setIsLogin(true);
          saveLoginData(result.id, resultData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {join && (
        <ModalFrame type={'type_1'} confirm={() => navigate('/home')} custom_txt={'회원가입이 완료되었습니다.'} />
      )}
      {Terms && <TermsOfUse close={handleCloseTermsOfUse} />}
      {privacy && <PrivacyPolicy close={handleClosePrivacy} />}
      <div className="register_section frame">
        <div className="frame_inner_header flex flex_jc_sb flex_ai_c">
          <HistoryBack />
          <div className="page_title flex flex_jc_c">회원가입</div>
        </div>
        <Input
          value={registerData.id}
          id={'id'}
          name={'id'}
          placeholder={'아이디'}
          label={'아이디'}
          onChange={onChangeTarget}
          onBlur={onBlurIdDuplication}
          event_type={`absolute ${registerCheck.id === 1 || registerCheck.id === 2 ? 'false' : 'true'}`}
          event_msg={
            registerCheck.id === 0
              ? ''
              : registerCheck.id === 1
              ? '중복된 아이디 입니다.'
              : registerCheck.id === 2
              ? '올바른 아이디를 입력하세요.'
              : '사용 가능합니다.'
          }
        />
        <Input
          value={registerData.password}
          id={'password'}
          name={'password'}
          type={'password'}
          placeholder={'비밀번호'}
          label={'비밀번호'}
          onChange={onChangeTarget}
          onBlur={onBlurPassword}
          event_type={`absolute ${registerCheck.password === 1 ? 'false' : 'true'}`}
          event_msg={
            registerCheck.password === 0
              ? ''
              : registerCheck.password === 1
              ? '8~16자의 영문과 숫자, 특수문자를 조합하여 설정해주세요.'
              : registerCheck.password === 2 && '사용 가능합니다.'
          }
        />
        <Input
          value={passwordCheck}
          id={'loginPwCk'}
          name={'loginPwCk'}
          type={'password'}
          placeholder={'비밀번호 확인'}
          label={'비밀번호 확인'}
          onChange={onChangePasswordCheck}
          onBlur={onBlurPasswordCheck}
          event_type={`absolute ${
            registerCheck.passwordCheck === 1 || registerCheck.passwordCheck === 2 ? 'false' : 'true'
          }`}
          event_msg={
            registerCheck.passwordCheck === 0
              ? ''
              : registerCheck.passwordCheck === 1
              ? '입력한 비밀번호가 다릅니다.'
              : registerCheck.passwordCheck === 2
              ? '올바른 비밀번호를 입력해주세요.'
              : '비밀번호가 일치합니다.'
          }
        />
        <Input
          value={registerData.nickname}
          id={'nickname'}
          name={'nickname'}
          placeholder={'닉네임'}
          label={'닉네임'}
          onChange={onChangeTarget}
          onBlur={onBlurNicknameDuplication}
          event_type={`absolute ${registerCheck.nickname === 1 || registerCheck.nickname === 2 ? 'false' : 'true'}`}
          event_msg={
            registerCheck.nickname === 0
              ? ''
              : registerCheck.nickname === 1
              ? '중복된 닉네임 입니다.'
              : registerCheck.nickname === 2
              ? '올바른 닉네임을 입력하세요.'
              : '사용 가능합니다.'
          }
        />
        <div className="email_box relative flex flex_wrap_wrap flex_ai_fe">
          <Input
            value={emailAddress}
            id={'email_address'}
            name={'email_address'}
            placeholder={'이메일'}
            label={'이메일'}
            onChange={onChangeEmailAddress}
            onBlur={onBlurEmailDuplication}
            event_type={`absolute ${registerCheck.email === 1 || registerCheck.email === 2 ? 'false' : 'true'}`}
            event_msg={
              registerCheck.email === 0
                ? ''
                : registerCheck.email === 1
                ? '중복된 이메일 입니다.'
                : registerCheck.email === 2
                ? '올바른 이메일을 입력하세요.'
                : '사용 가능합니다.'
            }
          />
          <div className="flex flex_jc_c flex_as_c">@</div>
          <Input
            value={emailDomain}
            id={'email_domain'}
            name={'email_domain'}
            placeholder={''}
            label={''}
            onChange={onChangeEmailDomain}
            onBlur={onBlurEmailDuplication}
          />
          <select name="" id="" value={emailDomain} onChange={onChangeEmailDomain} onBlur={onBlurEmailDuplication}>
            <option value="">직접 입력</option>
            <option value="gmail.com">gmail.com</option>
            <option value="hanmail.net">hanmail.net</option>
            <option value="kakao.com">kakao.com</option>
            <option value="nate.com">nate.com</option>
            <option value="naver.com">naver.com</option>
          </select>
        </div>
        <div className="terms_check" style={{ marginTop: 30 }}>
          <div className="flex flex_jc_sb" style={{ marginBottom: 10 }}>
            <div className="flex flex_ai_c">
              <input type="checkbox" id="terms_use" checked={termsCheck} onChange={handleChangeTermsOfUse} />
              <label htmlFor="terms_use">[필수] 이용약관 동의하기</label>
            </div>
            <div className="view_terms" onClick={() => handleOpenTermsOfUse()}>
              보기
            </div>
          </div>
          <div className="flex flex_jc_sb">
            <div className="flex flex_ai_c">
              <input type="checkbox" id="privacy_policy" checked={privacyCheck} onChange={handleChangePrivacy} />
              <label htmlFor="privacy_policy">[필수] 개인정보처리방침 동의하기</label>
            </div>
            <div className="view_terms" onClick={() => handleOpenPrivacy()}>
              보기
            </div>
          </div>
        </div>
        <div className="btn_box">
          <div className="button" onClick={handleRegisterShowup}>
            회원가입
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
